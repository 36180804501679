import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Editor } from 'primereact/editor';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';
import 'quill/dist/quill.snow.css'; // Quillのスタイル
import Quill from 'quill';


const EditContent = () =>{

    // QuillのParchmentモジュールを使用してカスタムブロックを定義
    const BlockEmbed = Quill.import('blots/block/embed');
    const toast = useRef(null);

    const [ publicLanguageSelect, setPublicLanguageSelect ] = useState();
    const [ defaultLanguage, setDefaultLanguage ] = useState();
    const [ activeIndex, setActiveIndex ] = useState(2);
    const [ hiddenEditTab, setHiddenEditTab] = useState(0);
    const { t } = useTranslation();
    const [ name, setName] = useState('');
    const [ selectTemplate, setSelectTemplate ] = useState(undefined);
    const [ breadcrumb, setBreadcrumb] = useState('');
    const [ thumbnail, setThumbnail] = useState('');
    const [ address, setAddress] = useState('');
    const [ password, setPassword ] = useState(0);
    const [ description, setDescription] = useState('');
    const [ css, setCss] = useState('');
    const [ js, setJs] = useState('');
    const [ json, setJson] = useState('');
    const [ titleJP, setTitleJP] = useState('');
    const [ titleEN, setTitleEN] = useState('');
    const [ titleCH, setTitleCH] = useState('');
    const [ content1JP, setContent1JP] = useState('');
    const [ content2JP, setContent2JP] = useState('');
    const [ content3JP, setContent3JP] = useState('');
    const [ content1EN, setContent1EN] = useState('');
    const [ content2EN, setContent2EN] = useState('');
    const [ content3EN, setContent3EN] = useState('');
    const [ content1CH, setContent1CH] = useState('');
    const [ content2CH, setContent2CH] = useState('');
    const [ content3CH, setContent3CH] = useState('');
    const [ showEC1JP, setShowEC1JP] = useState(false); 
    const [ showEC2JP, setShowEC2JP] = useState(false); 
    const [ showEC3JP, setShowEC3JP] = useState(false); 
    const [ showEC1EN, setShowEC1EN] = useState(false); 
    const [ showEC2EN, setShowEC2EN] = useState(false); 
    const [ showEC3EN, setShowEC3EN] = useState(false); 
    const [ showEC1CH, setShowEC1CH] = useState(false); 
    const [ showEC2CH, setShowEC2CH] = useState(false); 
    const [ showEC3CH, setShowEC3CH] = useState(false); 

    const [ usersData, setUsersData ] = useState([]);
    const [ selectUsersData, setSelectUsersData ] = useState(null);

    const [ editName, setEditName] = useState('');
    const [ editSelectTemplate, setEditSelectTemplate ] = useState(undefined);
    const [ editBreadcrumb, setEditBreadcrumb] = useState('');
    const [ editThumbnail, setEditThumbnail] = useState('');
    const [ editAddress, setEditAddress] = useState('');
    const [ editPassword, setEditPassword ] = useState(0);
    const [ editDescription, setEditDescription] = useState('');
    const [ editCss, setEditCss] = useState('');
    const [ editJs, setEditJs] = useState('');
    const [ editJson, setEditJson] = useState('');
    const [ editTitleJP, setEditTitleJP] = useState('');
    const [ editTitleEN, setEditTitleEN] = useState('');
    const [ editTitleCH, setEditTitleCH] = useState('');
    const [ editContent1JP, setEditContent1JP] = useState('');
    const [ editContent2JP, setEditContent2JP] = useState('');
    const [ editContent3JP, setEditContent3JP] = useState('');
    const [ editContent1EN, setEditContent1EN] = useState('');
    const [ editContent2EN, setEditContent2EN] = useState('');
    const [ editContent3EN, setEditContent3EN] = useState('');
    const [ editContent1CH, setEditContent1CH] = useState('');
    const [ editContent2CH, setEditContent2CH] = useState('');
    const [ editContent3CH, setEditContent3CH] = useState('');

    const [showEEC1JP, setShowEEC1JP] = useState(false); 
    const [showEEC2JP, setShowEEC2JP] = useState(false); 
    const [showEEC3JP, setShowEEC3JP] = useState(false); 
    const [showEEC1EN, setShowEEC1EN] = useState(false); 
    const [showEEC2EN, setShowEEC2EN] = useState(false); 
    const [showEEC3EN, setShowEEC3EN] = useState(false); 
    const [showEEC1CH, setShowEEC1CH] = useState(false); 
    const [showEEC2CH, setShowEEC2CH] = useState(false); 
    const [showEEC3CH, setShowEEC3CH] = useState(false); 

    let selectDel = '';


    const templateList = [
        { name: t('temp1'), code: '1' },
        { name: t('temp2'), code: '2' },
        { name: t('temp3'), code: '3' }
    ];

    const selectTemplateFunction = (val) =>{
        templateList.forEach((item) => {
            if(item.code === val.toString()){
                setEditSelectTemplate({name:item.name, code:item.code});
            }
        })
    }
    const sendRequest = async (url,data) => {
        const params = new URLSearchParams(data).toString();
        const response = await fetch( `${url}?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }else if(response.status === 200) {
            const responceData = await response.json();
            return responceData;
        }else{
            return "[]";
        }
    }

    const requestDB = async(data) => {
        try {
            const resData = await sendRequest(`${process.env.REACT_APP_API_CONTENT}`,data);
            setUsersData(resData.system);
            // return resData;
        } catch (error) {
            console.log(error)
            console.error("Failed to send the message: ", error);
        }
    }

    useEffect(() =>{
        const token = sessionStorage.getItem('token');
        const data = { token }
        const defLang = localStorage.getItem('defaultLanguage');
        if(defLang){
            const data = JSON.parse(defLang);
            setDefaultLanguage(data.value);
        }
        const pubLang = localStorage.getItem('publicLanguageSelect');
        if(pubLang){
            const data = JSON.parse(pubLang);
            setPublicLanguageSelect(data.value);
        }
        requestDB(data);
    },[]);

    const sendUserDB = async(data, action) => {
        const token = sessionStorage.getItem('token');
        const dataT = { token }
        const dataWithToken = { ...data, token };
        // const data = { email, password, name, nickname, level };
        // console.log(data);
        if(action === 'new'){
            console.log('new')
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'POST', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        // window.location.reload();
                        requestDB(dataT);
                        toast.current.show({severity:'success', summary: t('success'), detail:t('newDataCreationSuccess'), life: 3000});
                    // },1000)
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('newDataCreationFailure'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_CONTENT}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'edit'){
            // console.log("data:"+JSON.stringify(data))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'PUT', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        // window.location.reload();
                        requestDB(dataT);
                        toast.current.show({severity:'success', summary: t('success'), detail:t('updateSuccess'), life: 3000});
                    // },1000)
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('updateError'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_CONTENT}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'delete'){
            // console.log('delete')
            // console.log("data:"+JSON.stringify(data))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'DELETE', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        // window.location.reload();
                        requestDB(dataT);
                        toast.current.show({severity:'success', summary: t('success'), detail:t('deleteDataSuccess'), life: 3000});
                    // },1000)
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('deleteDataFailure'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_CONTENT}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }
    // 新規ユーザーデータチェック
    const formik = useFormik({
        initialValues: {
            name: '',
            template: '',
            breadcrumb: '',
            thumbnail:'',
            titleJP: '',
            titleEN: '',
            titleCH: '',
            content1JP: '',
            content2JP: '',
            content3JP: '',
            content1EN: '',
            content2EN: '',
            content3EN: '',
            content1CH: '',
            content2CH: '',
            content3CH: '',
            address:'',
            password:0,
            description:'',
            css:'',
            js:'',
            json:''
        },
        validate: (data) => {
            let errors = {};
            if (!data.name) {
                errors.name = t('nameIsRequired');
            }
            if (!data.address) {
                errors.address = t('addressIsRequired');
            }
            if(!data.template){
                errors.template = t('templateIsRequired');
            }
            return errors;
        },
        onSubmit: (data) => {
            sendUserDB(data, 'new');
            setActiveIndex(2);
            // formik.resetForm();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };


    // ユーザー編集データーチェック
    const formikEdit = useFormik({
        initialValues: {
            id: '',
            name: '',
            template: '',
            breadcrumb: '',
            thumbnail:'',
            titleJP: '',
            titleEN: '',
            titleCH: '',
            content1JP: '',
            content2JP: '',
            content3JP: '',
            content1EN: '',
            content2EN: '',
            content3EN: '',
            content1CH: '',
            content2CH: '',
            content3CH: '',
            address:'',
            password:0,
            description:'',
            css:'',
            js:'',
            json:''
        },
        validate: (data) => {
            let errors = {};
            if (!data.name) {
                errors.name = t('nameIsRequired');
            }
            if (!data.address) {
                errors.address = t('addressIsRequired');
            }
            if(!data.template){
                errors.template = t('templateIsRequired');
            }
            return errors;
        },
        onSubmit: (data) => {
            sendUserDB(data,'edit');
            setActiveIndex(2);
            // formik.resetForm();
        }
    });

    const editIsFormFieldInvalid = (name) => !!(formikEdit.touched[name] && formikEdit.errors[name]);

    const editGetFormErrorMessage = (name) => {
        return editIsFormFieldInvalid(name) ? <small className="p-error">{formikEdit.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const accept = () => {
        const id=selectDel;
        const data = {id:id}
        sendUserDB(data,'delete');
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        selectDel = '';
    }
    const confirmDelete = (value) => {
        selectDel = value.id;
        confirmDialog({
            message: t('areYouSureYouWantToDelete?'),
            header: t('confirm'),
            rejectLabel:t('no'),
            acceptLabel:t('yes'),
            defaultFocus:'reject',
            pt:{
                closeButton:{
                    className: 'focus:shadow-none'
                }
            },
            accept,
            reject
        })
    }
    const deleteTemplate = (value) => {
        return (
            <span>
                <Button 
                    label={t('delete')}
                    onClick={() => {
                        confirmDelete(value)
                    }}
                    pt={{
                        root:{
                            className:'text-sm bg-red-400 hover:bg-red-600 border-0 py-1 focus:shadow-none'
                        }
                    }}
                />
            </span>
        )
    }
    const hiddenTabFunction = () => {
        if(hiddenEditTab === 0){
            return 'hidden'
        }else{
            return 'block'
        }
    }
    const toggleEditor = (op) => {
        if(op==='content1JP') setShowEC1JP(!showEC1JP);
        if(op==='content2JP') setShowEC2JP(!showEC2JP);
        if(op==='content3JP') setShowEC3JP(!showEC3JP);
        if(op==='content1EN') setShowEC1EN(!showEC1EN);
        if(op==='content2EN') setShowEC2EN(!showEC2EN);
        if(op==='content3EN') setShowEC3EN(!showEC3EN);
        if(op==='content1CH') setShowEC1CH(!showEC1CH);
        if(op==='content2CH') setShowEC2CH(!showEC2CH);
        if(op==='content3CH') setShowEC3CH(!showEC3CH);
        if(op==='editContent1JP') setShowEEC1JP(!showEEC1JP);
        if(op==='editContent2JP') setShowEEC2JP(!showEEC2JP);
        if(op==='editContent3JP') setShowEEC3JP(!showEEC3JP);
        if(op==='editContent1EN') setShowEEC1EN(!showEEC1EN);
        if(op==='editContent2EN') setShowEEC2EN(!showEEC2EN);
        if(op==='editContent3EN') setShowEEC3EN(!showEEC3EN);
        if(op==='editContent1CH') setShowEEC1CH(!showEEC1CH);
        if(op==='editContent2CH') setShowEEC2CH(!showEEC2CH);
        if(op==='editContent3CH') setShowEEC3CH(!showEEC3CH);
    };

    class CustomSpan extends BlockEmbed {
        static create(value) {
            let node = super.create();
            node.setAttribute('id', value.id);
            node.innerHTML = value.content;
            return node;
        }
    
        static value(node) {
            return {
                id: node.getAttribute('id'),
                content: node.innerHTML
            };
        }
    }
    
    CustomSpan.blotName = 'customSpan';
    CustomSpan.tagName = 'span';


    // Quill.register(CustomSpan);


    // Quillのツールバーオプションの設定
    const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        ['link', 'image'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean']
    ];
    const urlTemplate = (row) =>{
        const baseUrl = `${window.location.protocol}//${window.location.host}/`;
        return(
            <div>
                <a href={(`${baseUrl}${row.address}`)} target="_blank" rel="noreferrer noopener">
                {row.address}
                </a>
            </div>
        )
    }
    const modules = {
        toolbar: {
            container: toolbarOptions,
            handlers: {
                insertSpan: function () {
                    const range =  this.quill.getSelection();
                    if (range) {
                        const value = { id: 'test', content: 'test' };
                        this.quill.insertEmbed(range.index, 'customSpan', value, Quill.sources.USER);
                    }
                }
            }
        }
    };

    const titleNewFields = () => {
        const contJP = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('title')}(JP)</label>
                    <InputText
                        inputid="titleJP"
                        name="titleJP"
                        value={titleJP}
                        placeholder={t('titleJP')}
                        onChange={(e) => {
                            formik.setFieldValue('titleJP', e.target.value);
                            setTitleJP(e.target.value);
                        }}
                        autoComplete="titleJP" />
                        {getFormErrorMessage('titleJP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('title')}(EN)</label>
                    <InputText
                        inputid="titleEN"
                        name="titleEN"
                        value={titleEN}
                        placeholder={t('titleEN')}
                        onChange={(e) => {
                            formik.setFieldValue('titleEN', e.target.value);
                            setTitleEN(e.target.value);
                        }}
                        autoComplete="titleEN" />
                        {getFormErrorMessage('titleEN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('title')}(CN)</label>
                    <InputText
                        inputid="titleCH"
                        name="titleCH"
                        value={titleCH}
                        placeholder={t('titleCH')}
                        onChange={(e) => {
                            formik.setFieldValue('titleCH', e.target.value);
                            setTitleCH(e.target.value);
                        }}
                        autoComplete="titleCH" />
                        {getFormErrorMessage('titleCH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const content1NewFields = () => {
        const contJP = () => {
            return(
                <>
                {/* content1JP */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('top')}(JP)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEC1JP ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('content1JP')} 
                        />
                    </div>
                    {showEC1JP ? (
                        <Editor
                            inputid="content1JP"
                            name="content1JP"
                            value={content1JP}
                            placeholder={t('content1JP')}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                            onTextChange={(e) => {
                                formik.setFieldValue('content1JP', e.htmlValue);
                                setContent1JP(e.htmlValue);
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content1JP"
                            name="content1JP"
                            value={content1JP !== null ? content1JP : ''}
                            placeholder={t('content1JP')}
                            autoResize
                            onChange={(e) => {
                                formik.setFieldValue('content1JP', e.target.value);
                                setContent1JP(e.target.value);
                            }}
                        />
                    )}
                    {getFormErrorMessage('content1JP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                {/* content1EN */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('top')}(EN)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEC1EN ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('content1EN')} 
                        />
                    </div>
                    {showEC1EN ? (
                        <Editor
                            inputid="content1EN"
                            name="content1EN"
                            value={content1EN}
                            placeholder={t('content1EN')}
                            onTextChange={(e) => {
                                formik.setFieldValue('content1EN', e.htmlValue);
                                setContent1EN(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content1EN"
                            name="content1EN"
                            value={content1EN !== null ? content1EN : ''}
                            placeholder={t('content1EN')}
                            autoResize
                            onChange={(e) => {
                                formik.setFieldValue('content1EN', e.target.value);
                                setContent1EN(e.target.value);
                            }}
                        />
                    )}
                    {getFormErrorMessage('content1EN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                {/* content1CH */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('top')}(CH)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEC1CH ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('content1CH')} 
                        />
                    </div>
                    {showEC1CH ? (
                        <Editor
                            inputid="content1CH"
                            name="content1CH"
                            value={content1CH}
                            placeholder={t('content1CH')}
                            onTextChange={(e) => {
                                formik.setFieldValue('content1CH', e.htmlValue);
                                setContent1CH(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content1CH"
                            name="content1CH"
                            value={content1CH !== null ? content1CH : ''}
                            placeholder={t('content1CH')}
                            autoResize
                            onChange={(e) => {
                                formik.setFieldValue('content1CH', e.target.value);
                                setContent1CH(e.target.value);
                            }}
                        />
                    )}
                    {getFormErrorMessage('content1CH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const content2NewFields = () => {
        const contJP = () => {
            return(
                <>
                {/* content2JP */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('bottom')}(JP)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEC2JP ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('content2JP')} 
                        />
                    </div>
                    {showEC2JP ? (
                        <Editor
                            inputid="content2JP"
                            name="content2JP"
                            value={content2JP}
                            placeholder={t('content2JP')}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                            onTextChange={(e) => {
                                formik.setFieldValue('content2JP', e.htmlValue);
                                setContent2JP(e.htmlValue);
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content2JP"
                            name="content2JP"
                            value={content2JP !== null ? content2JP : ''}
                            placeholder={t('content2JP')}
                            autoResize
                            onChange={(e) => {
                                formik.setFieldValue('content2JP', e.target.value);
                                setContent2JP(e.target.value);
                            }}
                        />
                    )}
                    {getFormErrorMessage('content2JP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                {/* content2EN */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('bottom')}(EN)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEC2EN ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('content2EN')} 
                        />
                    </div>
                    {showEC2EN ? (
                    <Editor
                        inputid="content2EN"
                        name="content2EN"
                        value={content2EN}
                        placeholder={t('content2EN')}
                        onTextChange={(e) => {
                            console.log('value:'+e.target.value)
                            formik.setFieldValue('content2EN', e.htmlValue);
                            setContent2EN(e.htmlValue);
                        }}
                        modules={modules}
                        theme="snow"
                        pt={{
                            toolbar:{
                                className:"hidden"
                            }
                        }}
                    />
                ) : (
                    <InputTextarea
                        inputid="content2EN"
                        name="content2EN"
                        value={content2EN !== null ? content2EN : ''}
                        placeholder={t('content2EN')}
                        autoResize
                        onChange={(e) => {
                            formik.setFieldValue('content2EN', e.target.value);
                            setContent2EN(e.target.value);
                        }}
                    />
                )}
                    {getFormErrorMessage('content2EN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                {/* content2CH */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('bottom')}(CH)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEC2CH ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('content2CH')} 
                        />
                    </div>
                    {showEC2CH ? (
                        <Editor
                            inputid="content2CH"
                            name="content2CH"
                            value={content2CH}
                            placeholder={t('content2CH')}
                            onTextChange={(e) => {
                                formik.setFieldValue('content2CH', e.htmlValue);
                                setContent2CH(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content2CH"
                            name="content2CH"
                            value={content2CH !== null ? content2CH : ''}
                            placeholder={t('content2CH')}
                            autoResize
                            onChange={(e) => {
                                formik.setFieldValue('content2CH', e.target.value);
                                setContent2CH(e.target.value);
                            }}
                        />
                    )}
                    {getFormErrorMessage('content2CH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const content3NewFields = () => {
        const contJP = () => {
            return(
                <>
                {/* content3JP */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('rightSideBar')}(JP)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEC3JP ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('content3JP')} 
                        />
                    </div>
                    {showEC3JP ? (
                        <Editor
                            inputid="content3JP"
                            name="content3JP"
                            value={content3JP}
                            placeholder={t('content3JP')}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                            onTextChange={(e) => {
                                formik.setFieldValue('content3JP', e.htmlValue);
                                setContent3JP(e.htmlValue);
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content3JP"
                            name="content3JP"
                            value={content3JP !== null ? content3JP : ''}
                            placeholder={t('content3JP')}
                            autoResize
                            onChange={(e) => {
                                formik.setFieldValue('content3JP', e.target.value);
                                setContent3JP(e.target.value);
                            }}
                        />
                    )}
                    {getFormErrorMessage('content3JP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                {/* content3EN */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('rightSideBar')}(EN)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEC3EN ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('content3EN')} 
                        />
                    </div>
                    {showEC3EN ? (
                        <Editor
                            inputid="content3EN"
                            name="content3EN"
                            value={content3EN}
                            placeholder={t('content3EN')}
                            onTextChange={(e) => {
                                formik.setFieldValue('content3EN', e.htmlValue);
                                setContent3EN(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content3EN"
                            name="content3EN"
                            value={content3EN !== null ? content3EN : ''}
                            placeholder={t('content3EN')}
                            autoResize
                            onChange={(e) => {
                                formik.setFieldValue('content3EN', e.target.value);
                                setContent3EN(e.target.value);
                            }}
                        />
                    )}
                    {getFormErrorMessage('content3EN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                {/* content3CH */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('rightSideBar')}(CH)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEC3CH ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('content3CH')} 
                        />
                    </div>
                    {showEC3CH ? (
                        <Editor
                            inputid="content3CH"
                            name="content3CH"
                            value={content3CH}
                            placeholder={t('content3CH')}
                            onTextChange={(e) => {
                                formik.setFieldValue('content3CH', e.htmlValue);
                                setContent3CH(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content3CH"
                            name="content3CH"
                            value={content3CH !== null ? content3CH : ''}
                            placeholder={t('content3CH')}
                            autoResize
                            onChange={(e) => {
                                formik.setFieldValue('content3CH', e.target.value);
                                setContent3CH(e.target.value);
                            }}
                        />
                    )}
                    {getFormErrorMessage('content3CH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const titleEditFields = () => {
        const contJP = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('title')}(JP)</label>
                    <InputText
                        inputid="titleJP"
                        name="titleJP"
                        value={editTitleJP !== null ? editTitleJP : ''}
                        placeholder={t('titleJP')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('titleJP', e.target.value);
                            setEditTitleJP(e.target.value);
                        }}
                        autoComplete="titleJP" />
                        {editGetFormErrorMessage('titleJP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('title')}(EN)</label>
                    <InputText
                        inputid="titleEN"
                        name="titleEN"
                        value={editTitleEN !== null ? editTitleEN : ''}
                        placeholder={t('titleEN')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('titleEN', e.target.value);
                            setEditTitleEN(e.target.value);
                        }}
                        autoComplete="titleEN" />
                        {editGetFormErrorMessage('titleEN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('title')}(CN)</label>
                    <InputText
                        inputid="titleCH"
                        name="titleCH"
                        value={editTitleCH !== null ? editTitleCH : ''}
                        placeholder={t('titleCH')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('titleCH', e.target.value);
                            setEditTitleCH(e.target.value);
                        }}
                        autoComplete="titleCH" />
                        {editGetFormErrorMessage('titleCH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const content1EditFields = () => {
        const contJP = () => {
            return(
                <>
                {/* editContent1JP */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('top')}(JP)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEEC1JP ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('editContent1JP')} 
                        />
                    </div>
                    {showEEC1JP ? (
                        <Editor
                            inputid="content1JP"
                            name="content1JP"
                            value={editContent1JP}
                            placeholder={t('content1JP')}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                            onTextChange={(e) => {
                                formikEdit.setFieldValue('content1JP', e.htmlValue);
                                setEditContent1JP(e.htmlValue);
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content1JP"
                            name="content1JP"
                            value={editContent1JP !== null ? editContent1JP : ''}
                            placeholder={t('content1JP')}
                            autoResize
                            onChange={(e) => {
                                formikEdit.setFieldValue('content1JP', e.target.value);
                                setEditContent1JP(e.target.value);
                            }}
                        />
                    )}
                    {editGetFormErrorMessage('content1JP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                {/* editContent1EN */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('top')}(EN)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEEC1EN ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('editContent1EN')} 
                        />
                    </div>
                    {showEEC1EN ? (
                        <Editor
                            inputid="content1EN"
                            name="content1EN"
                            value={editContent1EN}
                            placeholder={t('content1EN')}
                            onTextChange={(e) => {
                                formikEdit.setFieldValue('content1EN', e.htmlValue);
                                setEditContent1EN(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content1EN"
                            name="content1EN"
                            value={editContent1EN !== null ? editContent1EN : ''}
                            placeholder={t('content1EN')}
                            autoResize
                            onChange={(e) => {
                                formikEdit.setFieldValue('content1EN', e.target.value);
                                setEditContent1EN(e.target.value);
                            }}
                        />
                    )}
                    {editGetFormErrorMessage('content1EN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                {/* editContent1CH */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('top')}(CH)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEEC1CH ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('editContent1CH')} 
                        />
                    </div>
                    {showEEC1CH ? (
                        <Editor
                            inputid="content1CH"
                            name="content1CH"
                            value={editContent1CH}
                            placeholder={t('content1CH')}
                            onTextChange={(e) => {
                                formikEdit.setFieldValue('content1CH', e.htmlValue);
                                setEditContent1CH(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content1CH"
                            name="content1CH"
                            value={editContent1CH !== null ? editContent1CH : ''}
                            placeholder={t('content1CH')}
                            autoResize
                            onChange={(e) => {
                                formikEdit.setFieldValue('content1CH', e.target.value);
                                setEditContent1CH(e.target.value);
                            }}
                        />
                    )}
                    {editGetFormErrorMessage('content1CH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const passwordFunction = (row) =>{
        const val = (row.password === 1) ? 'YES' : 'NO';
        return(
            <div className='font-bold'>
                {val}
            </div>
        )
    }
    const content2EditFields = () => {
        const contJP = () => {
            return(
                <>
                {/* editContent2JP */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('bottom')}(JP)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEEC2JP ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('editContent2JP')} 
                        />
                    </div>
                    {showEEC2JP ? (
                        <Editor
                            inputid="content2JP"
                            name="content2JP"
                            value={editContent2JP}
                            placeholder={t('content2JP')}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                            onTextChange={(e) => {
                                formikEdit.setFieldValue('content2JP', e.htmlValue);
                                setEditContent2JP(e.htmlValue);
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content2JP"
                            name="content2JP"
                            value={editContent2JP !== null ? editContent2JP : ''}
                            placeholder={t('content2JP')}
                            autoResize
                            onChange={(e) => {
                                formikEdit.setFieldValue('content2JP', e.target.value);
                                setEditContent2JP(e.target.value);
                            }}
                        />
                    )}
                    {editGetFormErrorMessage('content2JP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                {/* editContent2EN */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('bottom')}(EN)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEEC2EN ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('editContent2EN')} 
                        />
                    </div>
                    {showEEC2EN ? (
                    <Editor
                        inputid="content2EN"
                        name="content2EN"
                        value={editContent2EN}
                        placeholder={t('content2EN')}
                        onTextChange={(e) => {
                            console.log('value:'+e.target.value)
                            formikEdit.setFieldValue('content2EN', e.htmlValue);
                            setEditContent2EN(e.htmlValue);
                        }}
                        modules={modules}
                        theme="snow"
                        pt={{
                            toolbar:{
                                className:"hidden"
                            }
                        }}
                    />
                ) : (
                    <InputTextarea
                        inputid="content2EN"
                        name="content2EN"
                        value={editContent2EN !== null ? editContent2EN : ''}
                        placeholder={t('content2EN')}
                        autoResize
                        onChange={(e) => {
                            formikEdit.setFieldValue('content2EN', e.target.value);
                            setEditContent2EN(e.target.value);
                        }}
                    />
                )}
                    {editGetFormErrorMessage('content2EN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                {/* editContent2CH */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('bottom')}(CH)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEEC2CH ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('editContent2CH')} 
                        />
                    </div>
                    {showEEC2CH ? (
                        <Editor
                            inputid="content2CH"
                            name="content2CH"
                            value={editContent2CH}
                            placeholder={t('content2CH')}
                            onTextChange={(e) => {
                                formikEdit.setFieldValue('content2CH', e.htmlValue);
                                setEditContent2CH(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content2CH"
                            name="content2CH"
                            value={editContent2CH !== null ? editContent2CH : ''}
                            placeholder={t('content2CH')}
                            autoResize
                            onChange={(e) => {
                                formikEdit.setFieldValue('content2CH', e.target.value);
                                setEditContent2CH(e.target.value);
                            }}
                        />
                    )}
                    {editGetFormErrorMessage('content2CH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const content3EditFields = () => {
        const contJP = () => {
            return(
                <>
                {/* editContent3JP */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('rightSideBar')}(JP)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEEC3JP ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('editContent3JP')} 
                        />
                    </div>
                    {showEEC3JP ? (
                        <Editor
                            inputid="content3JP"
                            name="content3JP"
                            value={editContent3JP}
                            placeholder={t('content3JP')}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                            onTextChange={(e) => {
                                formikEdit.setFieldValue('content3JP', e.htmlValue);
                                setEditContent3JP(e.htmlValue);
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content3JP"
                            name="content3JP"
                            value={editContent3JP !== null ? editContent3JP : ''}
                            placeholder={t('content3JP')}
                            autoResize
                            onChange={(e) => {
                                formikEdit.setFieldValue('content3JP', e.target.value);
                                setEditContent3JP(e.target.value);
                            }}
                        />
                    )}
                    {editGetFormErrorMessage('content3JP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                {/* editContent3EN */}
                <div className="prose p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('rightSideBar')}(EN)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEEC3EN ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('editContent3EN')} 
                        />
                    </div>
                    {showEEC3EN ? (
                        <Editor
                            inputid="content3EN"
                            name="content3EN"
                            value={editContent3EN}
                            placeholder={t('content3EN')}
                            onTextChange={(e) => {
                                formikEdit.setFieldValue('content3EN', e.htmlValue);
                                setEditContent3EN(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content3EN"
                            name="content3EN"
                            value={editContent3EN !== null ? editContent3EN : ''}
                            placeholder={t('content3EN')}
                            autoResize
                            onChange={(e) => {
                                formikEdit.setFieldValue('content3EN', e.target.value);
                                setEditContent3EN(e.target.value);
                            }}
                        />
                    )}
                    {editGetFormErrorMessage('content3EN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                {/* editContent3CH */}
                <div className="p-2 flex flex-col w-full">
                    <div className="flex flex-row justify-between w-full">
                        <label>{t('content')}{t('rightSideBar')}(CH)</label>
                        <Button
                            pt={{
                                root:{
                                    className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                }
                            }}
                            type='button' 
                            label={showEEC3CH ? t('htmlView') : t('wysiwygView')} 
                            onClick={()=>toggleEditor('editContent3CH')} 
                        />
                    </div>
                    {showEEC3CH ? (
                        <Editor
                            inputid="content3CH"
                            name="content3CH"
                            value={editContent3CH}
                            placeholder={t('content3CH')}
                            onTextChange={(e) => {
                                formikEdit.setFieldValue('content3CH', e.htmlValue);
                                setEditContent3CH(e.htmlValue);
                            }}
                            modules={modules}
                            theme="snow"
                            pt={{
                                toolbar:{
                                    className:"hidden"
                                }
                            }}
                        />
                    ) : (
                        <InputTextarea
                            inputid="content3CH"
                            name="content3CH"
                            value={editContent3CH !== null ? editContent3CH : ''}
                            placeholder={t('content3CH')}
                            autoResize
                            onChange={(e) => {
                                formikEdit.setFieldValue('content3CH', e.target.value);
                                setEditContent3CH(e.target.value);
                            }}
                        />
                    )}
                    {editGetFormErrorMessage('content3CH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }


    const passwordSelectItems = [
        { name: 'YES', value: 1 },
        { name: 'NO', value: 0 }
    ];
    return(
        <div className="pb-40">
            <Header />
            <Toast ref={toast} />
            <div className=" mt-20 p-0 sm:p-8">
            <Accordion activeIndex={activeIndex} 
                onTabChange={
                    (e) => {
                        setActiveIndex(e.index);
                        if(e.index != 1){
                            setHiddenEditTab(0);
                        }else{
                            setHiddenEditTab(1);
                        }
                    }
                }
            >
                {/* 新規ユーザー */}
                <AccordionTab header={t('newContent')}>
                    <div className="w-full ">
                        <form 
                            onSubmit={formik.handleSubmit} 
                            className="w-full !max-w-none"
                        >
                            <div className="flex flex-col items-center contents-center justify-start w-full">
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('name')}</label>
                                        <InputText
                                            id="name"
                                            inputid="name"
                                            name="name"
                                            value={name}
                                            placeholder={t('name')}
                                            onChange={(e) => {
                                                formik.setFieldValue('name', e.target.value);
                                                setName(e.target.value);
                                            }}
                                            autoComplete="name" />
                                            {getFormErrorMessage('name')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('address')}</label>
                                        <InputText
                                            inputid="address"
                                            name="address"
                                            value={address}
                                            placeholder={t('address')}
                                            onChange={(e) => {
                                                formik.setFieldValue('address', e.target.value);
                                                setAddress(e.target.value);
                                            }}
                                            autoComplete="address" />
                                            {getFormErrorMessage('address')}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('breadcrumb')}</label>
                                        <InputText
                                            inputid="breadcrumb"
                                            name="breadcrumb"
                                            value={breadcrumb}
                                            placeholder={t('breadcrumb')}
                                            onChange={(e) => {
                                                formik.setFieldValue('breadcrumb', e.target.value);
                                                setBreadcrumb(e.target.value);
                                            }}
                                            autoComplete="breadcrumb" />
                                            {getFormErrorMessage('breadcrumb')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('heroImage')}</label>
                                        <InputText
                                            inputid="thumbnail"
                                            name="thumbnail"
                                            value={thumbnail}
                                            placeholder={t('heroImage')}
                                            onChange={(e) => {
                                                formik.setFieldValue('thumbnail', e.target.value);
                                                setThumbnail(e.target.value);
                                            }}
                                            autoComplete="thumbnail" />
                                            {getFormErrorMessage('thumbnail')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('template')}</label>
                                        <Dropdown
                                            inputid="template"
                                            name="template"
                                            value={selectTemplate}
                                            options={templateList}
                                            optionLabel="name"
                                            placeholder={t('template')}
                                            onChange={(e) => {
                                                formik.setFieldValue('template', e.value.code);
                                                setSelectTemplate(e.value);
                                            }}/>
                                            {getFormErrorMessage('template')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full items-center ">
                                        <label>{t('companyLogin')}</label>
                                        <SelectButton 
                                            value={password} 
                                            onChange={
                                                (e) => {
                                                    setPassword(e.value)
                                                    formik.setFieldValue('password', e.value);
                                                }
                                            } 
                                            optionLabel="name" 
                                            options={passwordSelectItems}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('description')}</label>
                                        <InputText
                                            inputid="description"
                                            name="description"
                                            value={description}
                                            placeholder={t('description')}
                                            onChange={(e) => {
                                                formik.setFieldValue('description', e.target.value);
                                                setDescription(e.target.value);
                                            }}
                                            autoComplete="description" />
                                            {getFormErrorMessage('description')}
                                    </div>
                                    {titleNewFields()}
                                    {/* コンテンツ１ */}
                                    {content1NewFields()}
                                    {/* コンテンツ２ */}
                                    {content2NewFields()}
                                    {/* コンテンツ３ */}
                                    {content3NewFields()}
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('css')}</label>
                                        <InputTextarea
                                            inputid="css"
                                            name="css"
                                            value={css}
                                            placeholder={t('css')}
                                            autoResize
                                            onChange={(e) => {
                                                formik.setFieldValue('css', e.target.value);
                                                setCss(e.target.value);
                                            }}
                                        />
                                        {getFormErrorMessage('css')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('js')}</label>
                                        <InputTextarea
                                            inputid="js"
                                            name="js"
                                            value={js}
                                            placeholder={t('js')}
                                            autoResize
                                            onChange={(e) => {
                                                formik.setFieldValue('js', e.target.value);
                                                setJs(e.target.value);
                                            }}
                                        />
                                        {getFormErrorMessage('js')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('json')}</label>
                                        <InputTextarea
                                            inputid="json"
                                            name="json"
                                            value={json}
                                            placeholder={t('json')}
                                            autoResize
                                            onChange={(e) => {
                                                formik.setFieldValue('json', e.target.value);
                                                setJson(e.target.value);
                                            }}
                                        />
                                        {getFormErrorMessage('json')}
                                    </div>
                                </div>
                                {/* {localStorage.getItem('token')} */}
                                <div className="pb-5 flex flex-row">
                                    <Button 
                                        className="m-2 text-nowrap"
                                        label={t('change')} 
                                        type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </AccordionTab>
                {/* ユーザー編集 */}
                <AccordionTab header={t('editContent')} className={hiddenTabFunction()} >
                    <div className="w-full ">
                        <form 
                            onSubmit={formikEdit.handleSubmit} 
                            className="!w-full !max-w-none"
                        >
                            <div className="flex flex-col items-center contents-center justify-start w-full">
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('name')}</label>
                                        <InputText
                                            id="name"
                                            inputid="name"
                                            name="name"
                                            value={editName}
                                            placeholder={t('name')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('name', e.target.value);
                                                setEditName(e.target.value);
                                            }}
                                            autoComplete="name" />
                                            {editGetFormErrorMessage('name')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('address')}</label>
                                        <InputText
                                            inputid="address"
                                            name="address"
                                            value={editAddress}
                                            placeholder={t('address')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('address', e.target.value);
                                                setEditAddress(e.target.value);
                                            }}
                                            autoComplete="address" />
                                            {editGetFormErrorMessage('address')}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('breadcrumb')}</label>
                                        <InputText
                                            inputid="breadcrumb"
                                            name="breadcrumb"
                                            value={editBreadcrumb}
                                            placeholder={t('breadcrumb')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('breadcrumb', e.target.value);
                                                setEditBreadcrumb(e.target.value);
                                            }}
                                            autoComplete="breadcrumb" />
                                            {getFormErrorMessage('breadcrumb')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('heroImage')}</label>
                                        <InputText
                                            inputid="thumbnail"
                                            name="thumbnail"
                                            value={editThumbnail}
                                            placeholder={t('heroImage')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('thumbnail', e.target.value);
                                                setEditThumbnail(e.target.value);
                                            }}
                                            autoComplete="thumbnail" />
                                            {getFormErrorMessage('thumbnail')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('template')}</label>
                                        <Dropdown
                                            inputid="template"
                                            name="template"
                                            value={editSelectTemplate}
                                            options={templateList}
                                            optionLabel="name"
                                            placeholder={t('template')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('template', e.value.code);
                                                setEditSelectTemplate(e.value);
                                            }}/>
                                            {getFormErrorMessage('template')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full items-center ">
                                        <label>{t('companyLogin')}</label>
                                        <SelectButton 
                                            value={editPassword} 
                                            onChange={
                                                (e) => {
                                                    setEditPassword(e.value)
                                                    formikEdit.setFieldValue('password', e.value);
                                                }
                                            } 
                                            optionLabel="name" 
                                            options={passwordSelectItems}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('description')}</label>
                                        <InputText
                                            inputid="description"
                                            name="description"
                                            value={editDescription}
                                            placeholder={t('description')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('description', e.target.value);
                                                setEditDescription(e.target.value);
                                            }}
                                            autoComplete="description" />
                                            {editGetFormErrorMessage('description')}
                                    </div>
                                    {titleEditFields()}
                                    {/* コンテンツ１ */}
                                    {content1EditFields()}
                                    {/* コンテンツ２ */}
                                    {content2EditFields()}
                                    {/* コンテンツ３ */}
                                    {content3EditFields()}
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('css')}</label>
                                        <InputTextarea
                                            inputid="css"
                                            name="css"
                                            value={editCss !== null ? editCss : ''}
                                            placeholder={t('css')}
                                            autoResize
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('css', e.target.value);
                                                setEditCss(e.target.value);
                                            }}
                                        />
                                        {editGetFormErrorMessage('css')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('js')}</label>
                                        <InputTextarea
                                            inputid="js"
                                            name="js"
                                            value={editJs !== null ? editJs : ''}
                                            placeholder={t('js')}
                                            autoResize
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('js', e.target.value);
                                                setEditJs(e.target.value);
                                            }}
                                        />
                                        {editGetFormErrorMessage('js')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('json')}</label>
                                        <InputTextarea
                                            inputid="json"
                                            name="json"
                                            value={editJson !== null ? editJson : ''}
                                            placeholder={t('json')}
                                            autoResize
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('json', e.target.value);
                                                setEditJson(e.target.value);
                                            }}
                                        />
                                        {editGetFormErrorMessage('json')}
                                    </div>
                                </div>
                                {/* {localStorage.getItem('token')} */}
                                <div className="pb-5 flex flex-row">
                                    <Button 
                                        className="m-2 text-nowrap"
                                        label={t('change')} 
                                        type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </AccordionTab>
                <AccordionTab header={t('listContent')} >
                    <ConfirmDialog />
                    <DataTable 
                        value={usersData} 
                        selectionMode 
                        selection={selectUsersData} 
                        onRowClick={
                            (e) => {
                                // console.log('eData:'+JSON.stringify(e.data));
                                if(e.data){
                                    setSelectUsersData(e.data);
                                    formikEdit.setFieldValue('id', e.data.id);
                                    setEditName(e.data.name);
                                    formikEdit.setFieldValue('name', e.data.name);
                                    formikEdit.setFieldValue('template', e.data.template);
                                    selectTemplateFunction(e.data.template);
                                    if(e.data.breadcrumb === null){setEditBreadcrumb('') }else {setEditBreadcrumb(e.data.breadcrumb)};
                                    formikEdit.setFieldValue('breadcrumb', e.data.breadcrumb);
                                    if(e.data.thumbnail === null){setEditThumbnail('') }else {setEditThumbnail(e.data.thumbnail)};
                                    formikEdit.setFieldValue('thumbnail', e.data.thumbnail);
                                    setEditAddress(e.data.address);
                                    formikEdit.setFieldValue('address', e.data.address);
                                    setEditPassword(e.data.password);
                                    formikEdit.setFieldValue('password', e.data.password);
                                    if(e.data.description === null){setEditDescription('') }else {setEditDescription(e.data.description)};
                                    formikEdit.setFieldValue('description', e.data.description);
                                    setEditCss(e.data.css);
                                    formikEdit.setFieldValue('css', e.data.css);
                                    setEditJs(e.data.js);
                                    formikEdit.setFieldValue('js', e.data.js);
                                    setEditJson(e.data.json);
                                    formikEdit.setFieldValue('json', e.data.json);
                                    if(e.data.titleJP === null){setEditTitleJP('') }else {setEditTitleJP(e.data.titleJP)};
                                    formikEdit.setFieldValue('titleJP', e.data.titleJP);
                                    if(e.data.titleEN === null){setEditTitleEN('') }else {setEditTitleEN(e.data.titleEN)};
                                    formikEdit.setFieldValue('titleEN', e.data.titleEN);
                                    if(e.data.titleCH === null){setEditTitleCH('') }else {setEditTitleCH(e.data.titleCH)};
                                    formikEdit.setFieldValue('titleCH', e.data.titleCH);
                                    setEditContent1JP(e.data.content1JP);
                                    formikEdit.setFieldValue('content1JP', e.data.content1JP);
                                    setEditContent2JP(e.data.content2JP);
                                    formikEdit.setFieldValue('content2JP', e.data.content2JP);
                                    setEditContent3JP(e.data.content3JP);
                                    formikEdit.setFieldValue('content3JP', e.data.content3JP);
                                    setEditContent1EN(e.data.content1EN);
                                    formikEdit.setFieldValue('content1EN', e.data.content1EN);
                                    setEditContent2EN(e.data.content2EN);
                                    formikEdit.setFieldValue('content2EN', e.data.content2EN);
                                    setEditContent3EN(e.data.content3EN);
                                    formikEdit.setFieldValue('content3EN', e.data.content3EN);
                                    setEditContent1CH(e.data.content1CH);
                                    formikEdit.setFieldValue('content1CH', e.data.content1CH);
                                    setEditContent2CH(e.data.content2CH);
                                    formikEdit.setFieldValue('content2CH', e.data.content2CH);
                                    setEditContent3CH(e.data.content3CH);
                                    formikEdit.setFieldValue('content3CH', e.data.content3CH);
                                    setHiddenEditTab(1);
                                    setActiveIndex(1);
                                }
                            }
                        } 
                        dataKey="id" 
                        tableStyle={{ minWidth: '50rem' }}
                        className="border"
                        stripedRows
                    >
                        <Column selectionMode="single"
                            pt={{
                                bodyCell:{className:'hidden'},
                                headerCell:{className:'hidden'}
                            }}
                        />
                        <Column field="name" header={t('name')}
                            pt={{headerContent:{className:'!justify-center text-md text-center '},
                                bodyCell:{className:'!justify-center text-md text-center'},
                                headerCell:{className:''}
                            }}
                        />
                        <Column field={urlTemplate} header={t('address')}
                            pt={{headerContent:{className:'!justify-center text-md text-center '},
                                bodyCell:{className:'!justify-center text-md text-center'},
                                headerCell:{className:''}
                        }}
                    />
                        <Column field="description" header={t('description')}
                            pt={{headerContent:{className:'!justify-center text-md text-center '},
                                bodyCell:{className:'!justify-center text-md text-center'},
                                headerCell:{className:''}
                            }}
                        />
                        <Column field="password" header={t('companyLogin')}
                            body={passwordFunction}
                            pt={{headerContent:{className:'!justify-center text-xs text-center w-[90px]'},
                                bodyCell:{className:'!justify-center text-md text-center'},
                                headerCell:{className:''}
                            }}
                        />
                        <Column field="id" header={t('delete')} body={deleteTemplate}
                            pt={{headerContent:{className:'!justify-center text-md text-center w-[90px]'},
                                bodyCell:{className:'!justify-center text-md text-center'},
                                headerCell:{className:''}
                            }}
                        />
                    </DataTable>
                </AccordionTab>
            </Accordion>
            </div>
            <Footer />
        </div>
    )
}

export default EditContent