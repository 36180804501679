import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { FilterMatchMode } from 'primereact/api';
import { FilterService } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { IconField } from 'primereact/iconfield';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';

const ContactUsManagement = () =>{
    const { t } = useTranslation();
    const [ visible, setVisible ] = useState(false);
    const [ applicationVisible, setApplicationVisible ] = useState(true);
    const [ contentVisible, setContentVisible ] = useState(true);
    const [ contactUs, setContactUs ] = useState([]);

    const customNullFilter = (value, filter) => {
        return value === undefined || value === null ||  value === '';
      };
    const customNullFilter2 = (value, filter) => {
        return value === undefined || value === null ||  value === '';
    };
      // カスタムフィルターを登録
      FilterService.register('customNull', customNullFilter);
      FilterService.register('customNull2', customNullFilter2);
    const [filters, setFilters] = useState(null);
    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            company: { value: null, matchMode: FilterMatchMode.CONTAINS },
            lastName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            lastNameKana: { value: null, matchMode: FilterMatchMode.CONTAINS },
            firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            firstNameKana: { value: null, matchMode: FilterMatchMode.CONTAINS },
            subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
            application: { value: null, matchMode: 'customNull2' },
            content: { value: null, matchMode: 'customNull' },
            // content: { value: null, matchMode: FilterMatchMode.CONTAINS },
            // application: { value: null, matchMode: FilterMatchMode.CONTAINS },
            email: { value: null, matchMode: FilterMatchMode.CONTAINS },
            tel: { value: null, matchMode: FilterMatchMode.CONTAINS },
            address: { value: null, matchMode: FilterMatchMode.CONTAINS },
            createDate: { value: null, matchMode: FilterMatchMode.CONTAINS }
        });
        setGlobalFilterValue('');
    }
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    // const [ createDate, setCreateDate ] = useState(null);
    const [ company, setCompany ] = useState(null);
    const [ lastName, setLastName ] = useState(null);
    const [ lastNameKana, setLastNameKana ] = useState(null);
    const [ firstName, setFirstName ] = useState(null);
    const [ firstNameKana, setFirstNameKana ] = useState(null);
    const [ subject, setSubject ] = useState(null);
    const [ content, setContent ] = useState(null);
    const [ application, setApplication ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const [ tel, setTel ] = useState(null);
    const [ address, setAddress ] = useState(null);
    const [ file, setFile ] = useState(null);

    const sendRequest = async (url,data) => {
        const params = new URLSearchParams(data).toString();
        const response = await fetch( `${url}?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }else if(response.status === 200) {
            const responceData = await response.json();
            return responceData;
        }else{
            return "[]";
        }
    }
    useEffect (() =>{
        const token = sessionStorage.getItem('token');
        const data = { token }
        const requestDB = async() => {
            try {
                const resData = await sendRequest(`${process.env.REACT_APP_API_CONTACT}`,data);
                setContactUs(resData.system);
                // console.log(resData.system)
                // return resData;
            } catch (error) {
                console.log(error)
                console.error("Failed to send the message: ", error);
            }
        }
        requestDB();
        initFilters();
        // applicationSelectFunction();
    },[])

    const truncateText = (text, maxLength) => {
        if(text){
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            }
        }
        return text;
    };
    const formatDateForPublic = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = ('0' + (d.getMonth() + 1)).slice(-2);
        const day = ('0' + d.getDate()).slice(-2);
        const hour = ('0' + d.getHours()).slice(-2);
        const minute = ('0' + d.getMinutes()).slice(-2);
        return {
            date: `${year}/${month}/${day}`,
            time: `${hour}:${minute}`
        };
    }
    const subjectTemplate = (row) => {
        return(
            <div>
                {truncateText(row.subject,30)}
            </div>
        )

    }
    const contentTemplate = (row) => {
        return(
            <div>
                {truncateText(row.content,30)}
            </div>
        )
    }
    const applicationTemplate = (row) => {
        const applicationCont = (row.application) ? truncateText(row.application,30) : '';
        return(
            <div>
                {applicationCont}
            </div>
        )
    }
    const addressTemplate = (row) => {
        return(
            <div>
                {truncateText(row.address,15)}
            </div>
        )
    }
    const dateTemplate = (row) => {
        const date = formatDateForPublic(row.createDate);
        return(
            <div className='flex flex-col w-fit'>
                <div>
                    {date.date}
                </div>
                <div>
                    {date.time}
                </div>
            </div>
        )

    }
    const companyTemplate = (row) => {
        return(
            <div className='flex flex-row w-fit'>
                    <div>
                        {row.company}
                    </div>
            </div>
        )

    }
    const nameTemplate = (row) => {
        return(
            <div className='flex flex-row w-fit'>
                <div className='flex flex-col pr-2'>
                    <div className='text-xs'>
                        {row.lastNameKana}
                    </div>
                    <div>
                        {row.lastName}
                    </div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-xs'>
                        {row.firstNameKana}
                    </div>
                    <div>
                        {row.firstName}
                    </div>
                </div>
            </div>
        )

    }

    const onGlobalFilterChange = (e) => {
        // initFilters();
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    
    const applicationSelectFunction = () => {
        // initFilters();
        setApplicationVisible(true);
        setContentVisible(false);
        setFilters({
          ...filters,
          application: { value: null, matchMode: 'customNull2' },
          content: { value: true, matchMode: 'customNull' }
        });
    }
    const contactUsSelectFunction = () => {
        // initFilters();
        setApplicationVisible(false);
        setContentVisible(true);
        setFilters({
          ...filters,
          content: { value: null , matchMode: 'customNull' },
          application: { value: true, matchMode: 'customNull2' }
        });
    }
    const clearSelectFunction = () => {
        initFilters();
        setApplicationVisible(true);
        setContentVisible(true);
        // content: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-between">
                <div className='flex flex-row'>
                    <Button
                        label={t('application')}
                        icon='pi pi-filter'
                        className='p-button-primary'
                        severity='info'
                        pt={{
                            root:{
                                className:'mr-4'
                            }
                        }}
                        onClick={() => {
                            applicationSelectFunction()
                        }}
                    />
                    <Button
                        label={t('contactUs')}
                        icon='pi pi-filter'
                        className='p-button-primary'
                        severity='success'
                        pt={{
                            root:{
                                className:'mr-4'
                            }
                        }}
                        onClick={() => {
                            contactUsSelectFunction()
                        }}
                    />
                    <Button
                        label={t('clear')}
                        icon='pi pi-filter'
                        className='p-button-primary'
                        severity='secondary'
                        onClick={() => {
                            clearSelectFunction()
                        }}
                    />
                </div>
                <div>
                    <IconField iconPosition="left"
                        className='statics flex flex-row'
                    >
                        <InputIcon 
                            className='pi pi-search
                            absolute pt-4 pl-4'
                        />
                        <InputText
                            value={globalFilterValue} 
                            onChange={onGlobalFilterChange} 
                            placeholder={t('keywordSearch')}
                            className='!pl-[40px]'
                            />
                    </IconField>
                </div>
            </div>
        );
    };

    const header = renderHeader();


    const replaceNewlinesWithBreaks = (text) => {
        if (!text) return null;
        return text.split('\n').map((item, index) => (
          <React.Fragment key={index}>
            {item}
            <br />
          </React.Fragment>
        ));
      }
    const headerFunction = () => {
        return(
            <div className='w-full'>
                <div className='flex flex-col mx-auto w-fit'>
                    <div className='flex flex-col sm:flex-row'>
                        <div className='flex flex-col mt-2 ml-2 mr-2'>
                        {(company)&&(
                                <div className='w-fit'>
                                    {company}
                                </div>
                        )}
                            <div className='w-fit text-xs'>
                                {lastNameKana}
                            </div>
                            <div className='w-fit'>
                                {lastName}
                            </div>
                        </div>
                        <div className='flex flex-col mt-2 ml-2 mr-2'>
                            <div className='w-fit text-xs'>
                                {firstNameKana}
                            </div>
                            <div className='w-fit'>
                                {firstName}
                            </div>
                        </div>
                        <div className='flex flex-col mt-4 ml-2 mr-2'>
                            <div className='w-fit'>
                                <a href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a>
                            </div>
                        </div>
                        <div className='flex flex-col mt-4 ml-2 mr-2'>
                            <div className='w-fit'>
                                TEL:<a href={`tel:${tel}`} target="_blank" rel="noreferrer">{tel}</a>
                            </div>
                        </div>
                    </div>
                    <div className='w-fit mx-2 mt-4 text-xl'>
                        {subject}
                    </div>
                </div>
            </div>
        )
    }
    const footerFunction = () => {
        return(
            <div className='w-full'>
                <div className='flex flex-row mx-auto w-fit'>
                    <div className='w-fit mx-2'>
                        <Button 
                            label={t('close')} 
                            type="button"
                            onClick={() => setVisible(false)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const bodyFunction = () => {
        let fileLinks='';
        const files = JSON.parse(file);
        if(file) {
            // ファイルリンクを作成
            fileLinks = files.map(fileName => {
              const originalFileName = fileName;
              const fileUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CONTACT_DIR}${fileName}`; // ファイルURLを作成
              return `<a href="${fileUrl}" target="_blank">${originalFileName}</a>`;
            }).join('<br>'); // 複数のリンクを改行で結合
          }
        return(
            <div className='w-full'>
                <div className='flex flex-col mx-auto w-full'>
                    <div className=' mx-2 p-2 w-full border p-2 rounded-t-xl border-blue-200  bg-sky-100'>
                        {t('contactContent')}</div>
                    <div className='w-full mx-2 text-lg border p-2 rounded-b-xl border-blue-200'>
                        {replaceNewlinesWithBreaks(content)}
                    </div>
                </div>
                <div className='flex flex-col mx-auto w-full mt-4'>
                    <div className=' mx-2 p-2 w-full border p-2 rounded-t-xl border-blue-200 bg-sky-100'>
                        {t('address')}</div>
                    <div className='w-full mx-2 text-lg border p-2 rounded-b-xl border-blue-200'>
                        {address}
                    </div>
                </div>
                <div className='flex flex-col mx-auto w-full mt-4'>
                    <div className=' mx-2 p-2 w-full border p-2 rounded-t-xl border-blue-200 bg-sky-100'>
                        {t('attachmentFile')}</div>
                    <div className='w-full mx-2 text-lg border p-2 rounded-b-xl border-blue-200'>
                        <div dangerouslySetInnerHTML={{ __html: fileLinks}} ></div>
                    </div>
                </div>
            </div>
        )
    }
    return(
        <div className="pb-20">
            <Header />
            <div className="mt-20 p-4 px-0 sm:px-4">
                <DataTable
                    dataKey="id" 
                    filters={filters} 
                    globalFilterFields={['createDate', 'company','lastName', 'lastNameKana', 'firstName', 'firstNameKana', 'subject', 'content','email', 'tel', 'address']}
                    header={header} 
                    selectionMode
                    emptyMessage="No customers found."
                    className="border-2 my-2 rounded-md"
                    // header={t('contactUs')}
                    value={contactUs}
                    tableStyle={{ minWidth: '50rem' }}
                    stripedRows
                    paginator
                    position="top"
                    rows={10} rowsPerPageOptions={[5, 10, 25, 50]} 
                    onRowClick={
                        (e) => {
                            if(e.data){
                                // setCreateDate(e.data.createDate);
                                setCompany(e.data.company);
                                setLastName(e.data.lastName);
                                setLastNameKana(e.data.lastNameKana);
                                setFirstName(e.data.firstName);
                                setFirstNameKana(e.data.firstNameKana);
                                setSubject(e.data.subject);
                                setContent(e.data.content);
                                setEmail(e.data.email);
                                setTel(e.data.tel);
                                setFile(e.data.file);
                                setAddress(e.data.address);
                                setVisible(true);
                            }
                        }
                    }
                >
                    <Column 
                        field='createDate'
                        body={dateTemplate} 
                        sortable
                        header={t('receptionDate')} 
                        pt={{headerContent:{className:'!justify-center text-md text-center min-w-20'},
                            bodyCell:{className:'!justify-center text-md text-center min-w-20'},
                            headerCell:{className:''}
                        }}
                    />
                    <Column 
                        field='company'
                        sortable
                        filter 
                        filterPlaceholder={t('keywordSearch')+t('company')} 
                        body={companyTemplate} 
                        header={t('company')} 
                        pt={{headerContent:{className:'!justify-left text-md text-center min-w-40'},
                            bodyCell:{className:'!justify-center text-md text-left min-w-20'},
                            headerCell:{className:''},
                            filterApplyButton:{
                                title:"TEST"
                            }
                        }}
                    />
                    <Column 
                        field='lastName'
                        sortable
                        filter 
                        filterPlaceholder={t('keywordSearch')+t('name')} 
                        body={nameTemplate} 
                        header={t('name')} 
                        pt={{headerContent:{className:'!justify-left text-md text-center min-w-20'},
                            bodyCell:{className:'!justify-center text-md text-left min-w-20'},
                            headerCell:{className:''},
                            filterApplyButton:{
                                title:"TEST"
                            }
                        }}
                    />
                    <Column 
                        field='subject'
                        filter 
                        filterPlaceholder={t('keywordSearch')+t('subject')} 
                        body={subjectTemplate} 
                        header={t('subject')} 
                        pt={{headerContent:{className:'!justify-left text-md text-center min-w-60'},
                            bodyCell:{className:'!justify-center text-md text-left min-w-20'},
                            headerCell:{className:''}
                        }}
                    />
                    <Column 
                        field='content'
                        filter
                        filterPlaceholder={t('keywordSearch')+t('content')} 
                        body={contentTemplate} 
                        header={t('contactContent')} 
                        pt={{headerContent:{className:'!justify-left text-md text-center min-w-60'},
                            bodyCell:{
                                className:`${!contentVisible ? 'hidden' : ''} !justify-center text-md text-left min-w-20`
                            },
                            headerCell:{
                                className:`${!contentVisible ? 'hidden' : ''}`
                            }
                        }}
                    />
                    <Column 
                        field='application'
                        filter 
                        filterPlaceholder={t('keywordSearch')+t('application')} 
                        body={applicationTemplate} 
                        header={t('applicationContent')} 
                        pt={{headerContent:{className:'!justify-left text-md text-center min-w-60'},
                            bodyCell:{
                                className:`${!applicationVisible ? 'hidden' : ''} !justify-center text-md text-left min-w-20`
                            },
                            headerCell:{
                                className:`${!applicationVisible ? 'hidden' : ''}`
                            }
                        }}
                    />
                    <Column 
                        field='email'
                        filter 
                        filterPlaceholder={t('keywordSearch')+t('email')} 
                        header={t('email')} 
                        pt={{headerContent:{className:'!justify-left text-md text-center min-w-20'},
                            bodyCell:{className:'!justify-center text-md text-left min-w-20'},
                            headerCell:{className:''}
                        }}
                    />
                    <Column 
                        field='tel'
                        filter 
                        filterPlaceholder={t('keywordSearch')+t('tel')} 
                        header={t('tel')} 
                        pt={{headerContent:{className:'!justify-left text-md text-center min-w-20'},
                            bodyCell:{className:'!justify-center text-md text-left min-w-20'},
                            headerCell:{className:''}
                        }}
                    />
                    <Column 
                        field='address'
                        filter
                        body = {addressTemplate}
                        filterPlaceholder={t('keywordSearch')+t('address')} 
                        header={t('address')} 
                        pt={{headerContent:{className:'!justify-left text-md text-center min-w-60'},
                            bodyCell:{className:'!justify-center text-md text-left min-w-20'},
                            headerCell:{className:''}
                        }}
                    />
                </DataTable>
                <Dialog
                    header={headerFunction()}
                    footer={footerFunction()}
                    visible={visible} 
                    maximizable 
                    style={{ width: '95vw' }} 
                    onHide={() => {if (!visible) return; setVisible(false); }}
                >
                    {bodyFunction()}
                </Dialog>
            </div>
            <Footer />
        </div>
    )
}
export default ContactUsManagement