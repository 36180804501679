import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import { twMerge } from 'tailwind-merge';

import SetSessionStrage from './components/SetSessionStrage';
import IndexPage from './components/contents/IndexPage';
import ContentPage from './components/contents/ContentPage';
import AdminPage from './components/contents/AdminPage';
import LoginPage from './components/contents/LoginPage';
import LogoutPage from './components/contents/LogoutPage';
import ContactUsPage from './components/contents/ContactUsPage';
import ApplicationPage from './components/contents/ApplicationPage';
import CompanyLoginPage from './components/contents/CompanyLoginPage';
import EditUser from './components/management/EditUser';
import EditContent from './components/management/EditContent';
import EditMenu from './components/management/EditMenu';
import EditRecruit from './components/management/EditRecruit';
import EditSystem from './components/management/EditSystem';
import FileUploader from './components/management/FileUploader';
import ContactUsManagement from './components/management/ContactUsManagement';
import UnderBannerManager from './components/management/UnderBannerManager';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';



function App() {
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // setToken(localStorage.getItem('token'));
      const tokenVal = sessionStorage.getItem('token');
      // console.log('token:'+tokenVal)
      if (tokenVal !== null && tokenVal !== undefined) {
        setIsAuthenticated(true);
        SetSessionStrage();
        await new Promise((resolve) => {
          // setTimeout(() => {
            resolve(SetSessionStrage());
          // }, 1000);
        });
      }
    };
    fetchData();
  }, []);


  /* 初期画面グループ */
  const defaultContents = () => {
    return (
      <PrimeReactProvider value={{ unstyled: true, pt: Tailwind, ptOptions: { mergeSections: true, mergeProps: true, classNameMergeFunction: twMerge } }}>
        <Router>
          <Routes>
            <>
              <Route path="/" element={<IndexPage />} />
              <Route path="/index" element={<IndexPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/contactus" element={<ContactUsPage />} />
              <Route path="/application" element={<ApplicationPage />} />
              <Route path="/companylogin" element={<CompanyLoginPage />} />
              <Route path="/*" element={<ContentPage />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}`} element={<AdminPage />} />
            </>
          </Routes>
        </Router>
      </PrimeReactProvider>
    )
  }
  /* メンバー画面 */
  const memberContents = () => {
    return (
      <PrimeReactProvider value={{ unstyled: true, pt: {}, ptOptions: { mergeSections: true, mergeProps: true, classNameMergeFunction: twMerge } }}>
        <Router>
          <Routes>
            <>
              <Route path="/" element={<IndexPage />} />
              <Route path="/index" element={<IndexPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/contactus" element={<ContactUsPage />} />
              <Route path="/application" element={<ApplicationPage />} />
              <Route path="/companylogin" element={<CompanyLoginPage />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}/*`} element={<AdminPage />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}/edituser`} element={<EditUser />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}/editmenu`} element={<EditMenu />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}/editsystem`} element={<EditSystem />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}/recruitmentdatamanagement`} element={<EditRecruit />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}/contentsmanagement`} element={<EditContent />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}/fileuploader`} element={<FileUploader />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}/contactusmanagement`} element={<ContactUsManagement />} />
              <Route path={`/${process.env.REACT_APP_ADMIN_ADDRESS}/underbannermanager`} element={<UnderBannerManager />} />
              <Route path="/*" element={<ContentPage />} />
            </>
          </Routes>
        </Router>
      </PrimeReactProvider>
    )
  }

  //初期選択
  if (isAuthenticated) {
    //loginされている
    return memberContents();
  } else {
    //loginされていない
    return defaultContents();
  }
}

export default App;
