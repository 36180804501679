import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import logo03 from 'images/logo03.svg';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';
import SetSessionStrage from 'components/SetSessionStrage';


const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const toast = useRef(null);
    const { t } = useTranslation();
    const sendMessage = async () => {
        const data = { email, password };
        // console.log(data);
        const sendRequest = async (url) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
            });
            // console.log(response);
            if (response.status === 200) {
                const body = await response.json(); // レスポンスボディをJSONオブジェクトに変換
                // const expires = Date.now() + (10 * 24 * 60 * 60 * 1000); // 10日後のミリ秒
                // localStorage.setItem('token', body.token, { expires });
                sessionStorage.setItem('token', body.token);
                const issuedAt = new Date().toISOString(); // 発行時間をISO形式で保存
                sessionStorage.setItem('tokenIssuedAt', issuedAt);
                SetSessionStrage();
                const checkNickname = () => {
                    const nickname = sessionStorage.getItem('nickname')
                    if(nickname){
                        navigate('/');
                    }else{
                        // 1秒後にナ再実行
                        setTimeout(() => {
                            checkNickname();
                        }, 1000);
                    }
                }
                checkNickname();

            } else if (response.status === 405) {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: t('yourPasswordIsIncorrect'), life: 3000 });
                throw new Error('Login failed');
            } else if (response.status === 404) {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: email + t('isNotRegistered'), life: 3000 });
                throw new Error('Login failed');
            } else {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: email + t('loginFailed'), life: 3000 });
                throw new Error('Login failed');
            }
        };
        try {
            await sendRequest(`${process.env.REACT_APP_API_LOGIN}`);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.email) {
                errors.email = t('emailIsRequired');
            }
            if (!data.password) {
                errors.password = t('passwordIsRequired');
            }
            // emailの形式チェックを追加
            if (!/\S+@\S+\.\S+/.test(data.email)) {
                errors.email = t('enterYourEmailAddressCorrectly');
            }
            if (data.password.length < 6) {
                errors.password = t('passwordIs6To20Characters');
            } else if (data.password.length > 20) {
                errors.password = t('passwordIs6To20Characters');
            }
            setEmail(data.email);
            setPassword(data.password);
            return errors;
        },
        onSubmit: (data) => {
            sendMessage();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    const loginCardTitle = ()=>{
        return (
                <div className='justify-center w-full items-center contents-center text-center font-bold text-gray-500' >
                <p>{process.env.REACT_APP_APPTITLE}</p> 
                <p><img src={logo03} alt='Wah hook management Ltd.,' className="w-4/12 mx-auto py-2"/></p>
                <p className="border-b-4 mb-2 border-green-800 text-green-800 border-double">{t('signIn')}</p>
                </div>
        );
    }
    return (
        <div className="">
            <Header />
            <div className="login-container flex justify-center items-center contents-center h-dvh">
                <Toast ref={toast} />
                <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2"
                    style={{
                        width:'90%',
                        maxWidth:'450px'
                    }}>
                    <Card title={loginCardTitle} className="w-full rounded-xl border-green-700 !shadow-xl border p-4">
                        <div className="p-fluid">
                            {/* <div className="p-fluid" style={{justifyContent:'center', width:'100%',display:'flex',textAlign:'center'}}>
                                {Object.keys(lngs).map((lng) => (
                                    <Button
                                        key={lng}
                                        onClick={() => langeChange(lng)}
                                        type='button'
                                        className={`${i18n.resolvedLanguage === lng ? 'p-button-primary' : 'p-button-secondary' } 
                                        !rounded-xl !border-green-700 !shadow-sm !border !text-green-800 
                                        !bg-inherit items-center contents-center text-center
                                        w-auto h-[30px] mx-auto mb-6`}
                                    >
                                        {lngs[lng]}
                                    </Button>
                                ))}
                            </div> */}
                            <div className="p-fluid">
                                <InputText
                                    className="!border !shadow-sm !p-2 !rounded !w-full !border-green-600"
                                    inputid="email"
                                    name="email"
                                    value={email}
                                    placeholder={t('email')}
                                    keyfilter="email"
                                    onChange={(e) => {
                                        formik.setFieldValue('email', e.target.value);
                                    }}
                                    autoComplete="email" />
                                {getFormErrorMessage('email')}
                            </div>
                            <div className="p-fluid ">
                                <Password
                                    inputid="password"
                                    name="password"
                                    value={password}
                                    placeholder={t('password')}
                                    onChange={(e) => {
                                        formik.setFieldValue('password', e.target.value);
                                    }}
                                    panelStyle={{width: '100%'}}
                                    toggleMask
                                    panelClassName="!w-full"
                                    inputClassName="!border-none !p-2 !rounded !w-full !focus-visible:border-none !static"
                                    autoComplete="new-password"
                                    pt={{
                                        root:{
                                            className:"!border !shadow-sm !rounded !w-full !border-green-600 !p-0 !relative [&>div]:!w-full"
                                        },
                                        showIcon:{
                                            className:"!m-2 !absolute !top-1 !right-2 !text-green-600"
                                        },
                                        hideIcon:{
                                            className:"!m-2 !absolute !top-1 !right-2 !text-green-600"
                                        },
                                        info:{
                                            className:"!hidden"
                                        },
                                        panel:{
                                            className:"!hidden"
                                        }
                                    }}
                                />
                                    {getFormErrorMessage('password')}
                            </div>
                            {/* {localStorage.getItem('token')} */}
                            <div className="text-center" >
                            <Button label={t('cancel')} 
                                type="button" 
                                className="!border !rounded-xl !px-4 !border-green-800 !text-green-800 !mr-4 !bg-inherit" 
                                onClick={()=> navigate('/')}
                            />
                            <Button label={t('signIn')} 
                                type="submit" 
                                className="!border !rounded-xl !px-4 !border-green-800 !text-green-800 !mr-4 !bg-inherit" 
                            />
                            </div>
                        </div>
                    </Card>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default LoginPage;