import { useEffect } from 'react';

const DynamicJavaScript = ({ scriptContent }) => {
  useEffect(() => {
    // script タグを動的に作成して追加
    const script = document.createElement('script');
    script.text = scriptContent;
    document.body.appendChild(script);

    // クリーンアップ用の関数を返す
    return () => {
      document.body.removeChild(script);
    };
  }, [scriptContent]);

  return null
};

export default DynamicJavaScript;