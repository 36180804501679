import { useEffect, useState } from 'react';
import Template from 'components/contentsParts/Template';

const ContentPage = () =>{
    const [path, setPath] = useState('');

    useEffect(() => {
        // パスの取得
        const currentPath = window.location.pathname;
        const trimmedPath = currentPath.startsWith('/') ? currentPath.substring(1) : currentPath;
        setPath(trimmedPath);

    },[])

    
    return Template(path,'');

}

export default ContentPage