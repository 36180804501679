import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Template from 'components/contentsParts/Template';

const ContacsUsPage = () =>{
    const { t } = useTranslation();
    const [ subject, setSubject ] = useState('');
    const [ content, setContent ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ emailConfirm, setEmailConfirm ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ tel, setTel ] = useState('');
    const [ agree, setAgree ] = useState('');
    const [ uploadedFiles, setUploadedFiles ] = useState([]);
    const fileUploadRef = useRef(null);
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [ sendData, setSendData ] = useState([]);

    
    const errorSendingMessage = () => {
        setVisible(false);
        setSendData([]);
        toast.current.show({ 
            severity: 'error', 
            summary: t('error'), 
            detail: t('sendErrorMessage'), 
            life: 5000});
    }
    const confirmSendingMessage = () =>{
        setVisible(false);
        setSubject('');
        setContent('');
        setCompany('');
        setLastName('');
        setEmail('');
        setEmailConfirm('');
        setAddress('');
        setTel('');
        setAgree('');
        setUploadedFiles([]);
        setSendData([]);
        fileUploadRef.current.clear()
        toast.current.show({ 
            severity: 'info', 
            summary: t('infomation'), 
            detail: t('sendedMessage'), 
            life: 5000});


    }
    const sendUserDB = async(data) => {
        // console.log(data);
        const sendRequest = async (url) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
                // body: data, // JavaScriptオブジェクトをJSON文字列に変換
            });
            // console.log(response);
            if (response.ok) {
                confirmSendingMessage();

            }else{
                // レスポンスステータスが200以外の場合のエラーハンドリング
                errorSendingMessage();
                const errorData = await response.json();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
            }
        };
        try {
            await sendRequest(`${process.env.REACT_APP_API_CONTACT}`);
        } catch (error) {
            errorSendingMessage();
            console.error('Error:', error);
        }
    }
    // データーチェック
    const formik = useFormik({
        initialValues: {
            subject:'',
            content:'',
            application:'',
            company:'',
            lastName:'',
            email:'',
            emailConfirm:'',
            address:'',
            tel:'',
            agree:'',
            type:'contactUs'
        },
        validate: (data) => {
            let errors = {};
            if (!data.subject) errors.subject = t('isRequired');
            if (/['"`]/.test(data.subject)) errors.subject = t('validateSpCharError');
            if (!data.content) errors.content = t('isRequired');
            if (/['"`]/.test(data.content)) errors.content = t('validateSpCharError');
            if (!data.lastName) errors.lastName = t('isRequired');
            if (/['"`]/.test(data.lastName)) errors.lastName = t('validateSpCharError');
            if (!data.email &&! data.tel ) {
                errors.email = t('emailOrTelRequired');
                errors.tel = t('emailOrTelRequired');
            }
            if (data.email && !/\S+@\S+\.\S+/.test(data.email)) errors.email = t('enterYourEmailAddressCorrectly');
            if (data.email && data.email !== data.emailConfirm ) errors.emailConfirm  = t('enterYourEmailAddressCorrectly');
            if (data.email && (/['"`]/.test(data.email))) errors.email = t('validateSpCharError');
            if (/['"`]/.test(data.tel)) errors.tel = t('validateSpCharError');
            if (/['"`]/.test(data.address)) errors.address = t('validateSpCharError');

            return errors;
        },
        onSubmit: (data) => {
            const upFiles = fileUploadRef.current.getUploadedFiles();
            const newFileList = upFiles.map(upFile => {
                const match = uploadedFiles.find(uploadedFile => uploadedFile.originalName === upFile.name);
                return match ? match.fileName : null;
            }).filter(fileName => fileName !== null);
            data = {
                ...data,
                file: newFileList
            };
            console.log("OK")
            setSendData(data);
            setVisible(true);
        }
    });
    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onUploadFile = (event) => {
        const response = event.xhr.response;
        const fileInfos = JSON.parse(response);
        setUploadedFiles(fileInfos);
        // console.log('fileInfos:'+JSON.stringify(fileInfos))
    };
    const sendFunction = () => {
        sendUserDB(sendData);
    }
    const confirmHeaderFunction = () => {
        return(
            <div className='text-blue-900'>
                <div className='mx-auto w-fit'>
                    {t('confirmInquiryDetails')}
                </div>
            </div>
        )
    }
    const confirmFooterFunction = () => {
        return(
            <div className='w-full'>
                <div className='flex flex-row mx-auto w-fit'>
                    <div className='w-fit mx-2'>
                        <Button 
                            label={t('cancel')} 
                            type="button"
                            onClick={() => setVisible(false)}
                        />
                    </div>
                    <div className='w-fit mx-2'>
                        <Button 
                            label={t('send')} 
                            type="button"
                            severity="danger"
                            onClick = {() =>sendFunction()}
                        />
                    </div>
                </div>
            </div>
        )
    }
    const replaceNewlinesWithBreaks = (text) => {
        if (!text) return null;
        return text.split('\n').map((item, index) => (
          <React.Fragment key={index}>
            {item}
            <br />
          </React.Fragment>
        ));
      }
    const confirmBodyFunction = () => {
        let numberOfFiles = 0;
        if(sendData.file)numberOfFiles = sendData.file.length;
        return(
            <div>
                <div className=' border-blue-200 flex flex-col'>
                    {(sendData.company)&&(
                        <>
                            <div className='font-bold  min-w-[80px] bg-sky-100 px-2 py-1 border border-blue-200 '>
                                {t('company')}
                            </div>
                            <div className='w-full px-2 py-1 border border-blue-200'>
                                {sendData.company}
                            </div>
                        </>
                    )}
                        <div className='font-bold  min-w-[50px] bg-sky-100 px-2 py-1 border border-blue2100 '>
                            {t('name')}
                        </div>
                        <div className='w-full px-2 py-1 border border-blue-200'>
                            {sendData.lastName}
                        </div>
                    {(sendData.email)&&(
                        <>
                        <div className='font-bold w-full bg-sky-100 px-2 py-1 border border-blue-200 '>
                            {t('email')}
                        </div>
                        <div className='w-full px-2 py-1 border border-blue-200'>
                            {sendData.email}
                        </div>
                        </>
                    )}
                    {(sendData.tel)&&(
                        <>
                        <div className='font-bold w-full bg-sky-100 px-2 py-1 border border-blue-200 '>
                            {t('tel')}
                        </div>
                        <div className='w-full px-2 py-1 border border-blue-200'>
                            {sendData.tel}
                        </div>
                        </>
                    )}
                    <div className='font-bold w-full bg-sky-100 px-2 py-1 border border-blue-200 '>
                        {t('contactAddress')}
                    </div>
                    <div className='w-full px-2 py-1 border border-blue-200'>
                        {sendData.address}
                    </div>

                    <div className='font-bold w-full bg-sky-100 px-2 mt-4 py-1 border border-blue-200 '>
                        {t('subject')}
                    </div>
                    <div className='w-full px-2 py-1 border border-blue-200'>
                        {sendData.subject}
                    </div>
                    <div className='font-bold w-full bg-sky-100 px-2 py-1 border border-blue-200 '>
                        {t('contactContent')}
                    </div>
                    <div className='w-full px-2 py-1 border border-blue-200'>
                        {replaceNewlinesWithBreaks(sendData.content)}
                    </div>

                    {(numberOfFiles > 0)&&(
                        <>
                        <div className='font-bold w-full bg-sky-100 px-2 py-1 border border-blue-200 '>
                            {t('attachmentFile')}
                        </div>
                        <div className='w-full px-2 py-1 border border-blue-200'>
                            {numberOfFiles}{t('file')}
                        </div>
                        </>
                    )}
                </div>
            </div>
        )
    }
    const contactusContentData = () =>{
        return(
            <div>
                <div className="border border-blue-600 mx-4 py-2 px-4 rounded-lg">
                    <form 
                                onSubmit={formik.handleSubmit} 
                                className="w-full !max-w-none"
                            >
                            <div className="flex flex-col w-full">
                                <div className="px-2 flex flex-col w-full">
                                    <label className='flex flex-row font-bold'>
                                        {t('subject')}
                                        <div className='text-xs text-red-900'>※{t('required')}</div>
                                    </label>
                                    <InputText
                                        inputid="subject"
                                        name="subject"
                                        value={subject}
                                        placeholder={t('subject')}
                                        onChange={(e) => {
                                            formik.setFieldValue('subject', e.target.value);
                                            setSubject(e.target.value);
                                        }}
                                        />
                                        {getFormErrorMessage('subject')}
                                </div>
                                <div className="px-2 flex flex-col w-full">
                                    <label className='flex flex-row font-bold'>
                                        {t('contactContent')}
                                        <div className='text-xs text-red-900'>※{t('required')}</div>
                                    </label>
                                    <InputTextarea
                                        inputid="content"
                                        name="content"
                                        value={content}
                                        placeholder={t('contactContent')}
                                        onChange={(e) => {
                                            formik.setFieldValue('content', e.target.value);
                                            setContent(e.target.value);
                                        }}
                                        />
                                        {getFormErrorMessage('content')}
                                </div>
                                <div className='font-bold border-b-2 border-blue-800 mb-4'>
                                    {t('customerInfomation')}
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="px-2 flex flex-col w-full">
                                        <label className='flex flex-row font-bold'>
                                            {t('name')}
                                            <div className='text-xs text-red-900'>※{t('required')}</div>
                                        </label>
                                        <InputText
                                            inputid="lastName"
                                            name="lastName"
                                            value={lastName}
                                            placeholder={t('name')}
                                            onChange={(e) => {
                                                formik.setFieldValue('lastName', e.target.value);
                                                setLastName(e.target.value);
                                            }}
                                            />
                                            {getFormErrorMessage('lastName')}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="px-2 flex flex-col w-full">
                                        <label className='flex flex-row font-bold'>
                                            {t('company')}
                                            <div className='text-xs text-red-900'>※{t('contactCoution3')}</div>
                                        </label>
                                        <InputText
                                            inputid="company"
                                            name="comapny"
                                            value={company}
                                            placeholder={t('company')}
                                            onChange={(e) => {
                                                formik.setFieldValue('company', e.target.value);
                                                setCompany(e.target.value);
                                            }}
                                            />
                                            {getFormErrorMessage('company')}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="px-2 flex flex-col w-full">
                                        <label className='flex flex-row font-bold'>
                                            {t('email')}
                                            <div className='text-xs text-red-900'>※{t('emailOrTelRequired')}</div>
                                        </label>
                                        <InputText
                                            inputid="email"
                                            name="email"
                                            value={email}
                                            placeholder={t('email')}
                                            onChange={(e) => {
                                                formik.setFieldValue('email', e.target.value);
                                                setEmail(e.target.value);
                                            }}
                                            />
                                            {getFormErrorMessage('email')}
                                    </div>
                                    <div className="px-2 flex flex-col w-full">
                                        <label className='flex flex-row font-bold'>
                                            {t('emailConfirm')}
                                        </label>
                                        <InputText
                                            inputid="emailConfirm"
                                            name="emailConfirm"
                                            value={emailConfirm}
                                            placeholder={t('emailConfirm')}
                                            onChange={(e) => {
                                                formik.setFieldValue('emailConfirm', e.target.value);
                                                setEmailConfirm(e.target.value);
                                            }}
                                            />
                                            {getFormErrorMessage('emailConfirm')}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="px-2 flex flex-col w-full">
                                        <label className='flex flex-row font-bold'>
                                            {t('tel')}
                                            <div className='text-xs text-red-900'>※{t('emailOrTelRequired')}</div>
                                        </label>
                                        <InputText
                                            inputid="tel"
                                            name="tel"
                                            value={tel}
                                            placeholder={t('tel')}
                                            onChange={(e) => {
                                                formik.setFieldValue('tel', e.target.value);
                                                setTel(e.target.value);
                                            }}
                                            />
                                            {getFormErrorMessage('tel')}
                                    </div>
                                    <div className="px-2 flex flex-col w-full">
                                        <label className='flex flex-row font-bold'>
                                            {t('contactAddress')}
                                        </label>
                                        <InputText
                                            inputid="address"
                                            name="address"
                                            value={address}
                                            placeholder={t('contactAddress')}
                                            onChange={(e) => {
                                                formik.setFieldValue('address', e.target.value);
                                                setAddress(e.target.value);
                                            }}
                                            />
                                            {getFormErrorMessage('address')}
                                    </div>
                                </div>
                                <div className='font-bold border-b-2 border-blue-800 mb-4'>
                                    {t('attachmentFile')}
                                </div>

                                <div className="flex flex-col w-full items-center">
                                    
                                    <FileUpload 
                                        ref={fileUploadRef}
                                        name="files[]" 
                                        url={`${process.env.REACT_APP_API_CONTACTFILEUPLOAD}`} 
                                        onUpload={onUploadFile}
                                        multiple 
                                        // accept="image/*" 
                                        maxFileSize={500000} 
                                        className="w-full pb-4"
                                        emptyTemplate={<p className="m-0">{t('dragAndDopFiles')}</p>} 
                                        chooseLabel={t('choose')}
                                        uploadLabel={t('upload')}
                                        cancelLabel={t('cancel')}
                                        auto
                                        pt={{
                                            chooseButton:{
                                            className:'bg-red-500 border-red-500'
                                            }
                                        }}
                                    />
                                    <div className='fo-bold'>{t('contactCoution0')}</div>
                                    <div className='fo-bold pb-4'>{t('contactCoution1')}<a href='/uploads/履歴書職務務経歴書.docx-1723032932855-786259530.docx' target="_blank">{t('contactCoution1_Link')}</a>{t('contactCoution1_End')}</div>
                                </div>
                                <div className="flex flex-col w-full text-xs">
                                    <div>※{t('contactCoution2')}</div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="px-2 pb-4 pt-2 flex flex-col w-fit mx-auto items-center">
                                        <div>
                                            <Button
                                                type="send"
                                                severity="danger" 
                                                className='w-fit'
                                                label={t('confirmInputContents')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </form>
                    <Toast ref={toast} position="top-center"/>
                    <Dialog
                        header={confirmHeaderFunction()}
                        footer={confirmFooterFunction()}
                        visible={visible} 
                        maximizable 
                        style={{ width: '90vw' }} 
                        onHide={() => {if (!visible) return; setVisible(false); }}
                    >
                        {confirmBodyFunction()}
                    </Dialog>
                </div>
            </div>
        )
    }
    return Template('contactus',contactusContentData());
}
export default ContacsUsPage