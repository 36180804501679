import React from 'react';
import { Card } from 'primereact/card';
import { Fieldset } from 'primereact/fieldset';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';

const AdminPage = () =>{
    return(
        <div className="pb-20">
            <Header />
            <div className=" mt-20 p-8">
                <Card
                    className="border"
                >
                    <Fieldset 
                        legend="Admin Page"
                        className=" m-4"
                        toggleable
                        pt={{
                            toggler:{
                                className:"static"
                            }
                        }}
                    >
                        <p>
                            Admin Page
                        </p>
                    </Fieldset>
                </Card>
            </div>
            <Footer />
        </div>
    )
}

export default AdminPage