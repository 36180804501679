import React, { useState, useEffect, useRef } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';



const EditSystem = () =>{
    const { t } = useTranslation();
    const contactUsMailaddressRef = useRef(null);
    const contactUsMailaddressCCRef = useRef(null);
    const memberPasswordRef = useRef(null);
    const [ systemData, setSystemData ] = useState('');
    const [ defaultLanguageTemp, setDefaultLanguageTemp ] = useState('');
    const [ defaultLanguage, setDefaultLanguage ] = useState('');
    const [ defaultLanguageId, setDefaultLanguageId ] = useState('');
    const [ publicLanguageSelect, setPublicLanguageSelect ] = useState('');
    const [ publicLanguageSelectTemp, setPublicLanguageSelectTemp ] = useState('');
    const [ publicLanguageSelectId, setPublicLanguageSelectId ] = useState('');
    const [ memberLanguageSelect, setMemberLanguageSelect ] = useState('');
    const [ memberLanguageSelectTemp, setMemberLanguageSelectTemp ] = useState('');
    const [ memberLanguageSelectId, setMemberLanguageSelectId ] = useState('');
    const [ contactUsMailaddress, setContactUsMailaddress ] = useState('');
    const [ contactUsMailaddressId, setContactUsMailaddressId ] = useState('');
    const [ contactUsMailaddressCC, setContactUsMailaddressCC ] = useState('');
    const [ contactUsMailaddressCCId, setContactUsMailaddressCCId ] = useState('');
    const [ memberPassword, setMemberPassword ] = useState('');
    const [ memberPasswordId, setMemberPasswordId ] = useState('');

    const sendRequest = async (url) => {
        const response = await fetch( url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }else if(response.status === 200) {
            const responceData = await response.json();
            return responceData;
        }else{
            return "[]";
        }
    }


    useEffect(() =>{
        const requestDB = async() => {
            try {
                const resData = await sendRequest(`${process.env.REACT_APP_API_SYSTEM}`);
                setSystemData(resData.system);
                // return resData;
            } catch (error) {
                console.log(error)
                console.error("Failed to send the message: ", error);
            }
        }
        requestDB();
    },[]);

    useEffect(()=>{
        if(systemData){
            systemData.map((item) => {
                if(item.name ==='defaultLanguage'){
                    setDefaultLanguage(item.value);
                    setDefaultLanguageId(item.id);
                }
                if(item.name ==='publicLanguageSelect'){
                    setPublicLanguageSelect(item.value);
                    setPublicLanguageSelectId(item.id);
                }
                if(item.name ==='memberLanguageSelect'){
                    setMemberLanguageSelect(item.value);
                    setMemberLanguageSelectId(item.id);
                }
                if(item.name ==='contactUsMailaddress'){
                    setContactUsMailaddress(item.value);
                    setContactUsMailaddressId(item.id);
                }
                if(item.name ==='contactUsMailaddressCC'){
                    setContactUsMailaddressCC(item.value);
                    setContactUsMailaddressCCId(item.id);
                }
                if(item.name ==='memberPassword'){
                    setMemberPassword(item.value);
                    setMemberPasswordId(item.id);
                }
                return null;
            })
        }

    },[systemData])
    const removeMenuFunction = () => {
        localStorage.removeItem('Menu');
        localStorage.removeItem('ControlMenu');
        localStorage.removeItem('AdminMenu');
        localStorage.removeItem('publicLanguageSelect');
        localStorage.removeItem('memberLanguageSelect');
        localStorage.removeItem('AdminMenuLang');
    }
    const sendUserDB = async(data, action) => {
        const token = sessionStorage.getItem('token');
        const dataWithToken = { ...data, token };
        // const data = { email, password, name, nickname, level };
        // console.log(data);
        if(action === 'edit'){
            // console.log('Edit')
            // console.log("data:"+JSON.stringify(data))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'PUT', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        removeMenuFunction();
                        window.location.reload();
                    // },1000)
                }else{
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_SYSTEM}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }


    // メニュー編集データーチェック
    const formikEdit = useFormik({
        initialValues: {
            id:'',
            name: '',
            value: '',
            numValue: ''
        },
        validate: (data) => {
            let errors = {};
            return errors;
        },
        onSubmit: (data) => {
            // console.log(data)
            sendUserDB(data,'edit');
            // formik.resetForm();
        }
    });
    const defaultLanguageItems = [
        { name: t('langJapanese'), value: 'ja' },
        { name: t('langEnglish'), value: 'en' },
        { name: t('langChinese'), value: 'xhCn' }
    ];

    const defaultLanguageTemplate = () => {
        let view = '';
        if(defaultLanguage === 'ja') view=t('langJapanese');
        if(defaultLanguage === 'en') view=t('langEnglish');
        if(defaultLanguage === 'zhCn') view=t('langChinese');
        return(
            <div className='flex flex-row justify-between items-center flex-wrap'>
                <div className='font-bold border-2 p-2 rounded-lg bg-gray-200'>{view}</div>
                <div>
                    <form 
                        onSubmit={formikEdit.handleSubmit} 
                        className="flex flex-row flex-wrap"
                    >
                        <div className='flex flex-row items-center'>
                            <SelectButton 
                                // value={value} 
                                onChange={
                                    // (e) => setValue(e.value)
                                    (e) => setDefaultLanguageTemp(e.value)
                                } 
                                optionLabel="name" 
                                options={defaultLanguageItems}
                            />
                        </div>
                        <div className="flex flex-row items-center">
                            <Button 
                                className="m-2 text-nowrap"
                                label={t('change')} 
                                onClick={()=>{
                                    if(defaultLanguageTemp !== ''){
                                        formikEdit.setFieldValue('value', defaultLanguageTemp);
                                        formikEdit.setFieldValue('id', defaultLanguageId);
                                        formikEdit.setFieldValue('name', 'defaultLanguage');
                                    }
                                }}
                                type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    const publicLanguageSelectItems = [
        { name: 'YES', value: '1' },
        { name: 'NO', value: '0' }
    ];
    const publicLanguageSelectTemplate = () => {
        let view =  "NO";
        if(publicLanguageSelect === '1') view ="YES";
        return(
            <div className='flex flex-row justify-between items-center flex-wrap'>
                <div className='font-bold border-2 p-2 rounded-lg bg-gray-200'>{view}</div>
                <div>
                    <form 
                        onSubmit={formikEdit.handleSubmit} 
                        className="flex flex-row"
                    >
                        <div className="p-2 flex flex-row w-full items-center ">
                            <SelectButton 
                                // value={value} 
                                onChange={
                                    // (e) => setValue(e.value)
                                    (e) => setPublicLanguageSelectTemp(e.value)
                                } 
                                optionLabel="name" 
                                options={publicLanguageSelectItems}
                            />
                        </div>
                        <div className="flex flex-row items-center">
                            <Button 
                                className="m-2 text-nowrap"
                                label={t('change')} 
                                onClick={()=>{
                                    if(publicLanguageSelectTemp !== ''){
                                        formikEdit.setFieldValue('value', publicLanguageSelectTemp);
                                        formikEdit.setFieldValue('id', publicLanguageSelectId);
                                        formikEdit.setFieldValue('name', 'publicLanguageSelect');
                                    }
                                }}
                                type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    const memberLanguageSelectItems = [
        { name: 'YES', value: '1' },
        { name: 'NO', value: '0' }
    ];
    const memberLanguageSelectTemplate = () => {
        let view =  "NO";
        if(memberLanguageSelect === '1') view ="YES";
        return(
            <div className='flex flex-row justify-between items-center flex-wrap'>
                <div className='font-bold border-2 p-2 rounded-lg bg-gray-200'>{view}</div>
                <div>
                    <form 
                        onSubmit={formikEdit.handleSubmit} 
                        className="flex flex-row"
                    >
                    <div className="p-2 flex flex-row w-full items-center ">
                        <SelectButton 
                            // value={value} 
                            onChange={
                                // (e) => setValue(e.value)
                                (e) => setMemberLanguageSelectTemp(e.value)
                            } 
                            optionLabel="name" 
                            options={memberLanguageSelectItems}
                        />
                    </div>
                    <div className="flex flex-row items-center">
                        <Button 
                            className="m-2 text-nowrap"
                            label={t('change')} 
                            onClick={()=>{
                                if(memberLanguageSelectTemp !== ''){
                                    formikEdit.setFieldValue('value', memberLanguageSelectTemp);
                                    formikEdit.setFieldValue('id', memberLanguageSelectId);
                                    formikEdit.setFieldValue('name', 'memberLanguageSelect');
                                }
                            }}
                            type="submit" />
                    </div>
                    </form>
                </div>
            </div>
        )
    }
    const contactUsMailaddressTemplate = () => {
        return(
            <div className='flex flex-row justify-between items-center flex-wrap'>
                <div className='font-bold border-2 p-2 rounded-lg bg-gray-200'>{contactUsMailaddress}</div>
                <div>
                    <form 
                        onSubmit={formikEdit.handleSubmit} 
                        className="flex flex-col sm:flex-row"
                    >
                        <div className="p-2 flex flex-col">
                            <InputText
                                ref={contactUsMailaddressRef}
                                inputid="contactUsMailaddress"
                                name="contactUsMailaddress"
                                placeholder={t('contactUsMailaddress')}
                                />
                        </div>
                        <div className="pb-5 flex flex-row">
                            <Button 
                                className="m-2 text-nowrap"
                                label={t('change')} 
                                onClick={()=>{
                                    formikEdit.setFieldValue('value', contactUsMailaddressRef.current.value);
                                    formikEdit.setFieldValue('id', contactUsMailaddressId);
                                    formikEdit.setFieldValue('name', 'contactUsMailaddress');
                                }}
                                type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    const contactUsMailaddressCCTemplate = () => {
        return(
            <div className='flex flex-row justify-between items-center flex-wrap'>
                <div className='font-bold border-2 p-2 rounded-lg bg-gray-200'>{contactUsMailaddressCC}</div>
                <div>
                    <form 
                        onSubmit={formikEdit.handleSubmit} 
                        className="flex flex-col sm:flex-row"
                    >
                        <div className="p-2 flex flex-col w-full">
                            <InputText
                                ref={contactUsMailaddressCCRef}
                                inputid="contactUsMailaddressCC"
                                name="contactUsMailaddressCC"
                                placeholder={t('contactUsMailaddressCC')}
                                />
                        </div>
                        <div className="pb-5 flex flex-row">
                            <Button 
                                className="m-2 text-nowrap"
                                label={t('change')} 
                                onClick={()=>{
                                    formikEdit.setFieldValue('value',contactUsMailaddressCCRef.current.value);
                                    formikEdit.setFieldValue('id', contactUsMailaddressCCId);
                                    formikEdit.setFieldValue('name', 'contactUsMailaddressCC');
                                }}
                                type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    const memberPasswordTemplate = () => {
        return(
            <div className='flex flex-row justify-between items-center flex-wrap'>
                <div className='font-bold border-2 p-2 rounded-lg bg-gray-200'>{memberPassword}</div>
                <div>
                    <form 
                        onSubmit={formikEdit.handleSubmit} 
                        className="flex flex-col sm:flex-row"
                    >
                        <div className="p-2 flex flex-col w-full">
                            <InputText
                                ref={memberPasswordRef}
                                inputid="memberPassword"
                                name="memberPassword"
                                placeholder={t('memberPassword')}
                                />
                        </div>
                        <div className="pb-5 flex flex-row">
                            <Button 
                                className="m-2 text-nowrap"
                                label={t('change')} 
                                onClick={()=>{
                                    formikEdit.setFieldValue('value',memberPasswordRef.current.value);
                                    formikEdit.setFieldValue('id', memberPasswordId);
                                    formikEdit.setFieldValue('name', 'memberPassword');
                                }}
                                type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    return(
        <div className="pb-40">
            <Header />
            <Panel 
                header={t('systemSetting')}
                className='mt-20 mx-0 sm:mx-4'
            >
                <div className='my-2'>
                    <Fieldset legend={t('defaultLanguage')}>
                        {defaultLanguageTemplate()}
                    </Fieldset>
                </div>
                <div className='my-2'>
                    <Fieldset legend={t('publicLanguageSelect')}>
                        {publicLanguageSelectTemplate()}
                    </Fieldset>
                </div>
                <div className='my-2'>
                    <Fieldset legend={t('memberLanguageSelect')}>
                        {memberLanguageSelectTemplate()}
                    </Fieldset>
                </div>
                <div className='my-2'>
                    <Fieldset legend={t('contactUsMailaddress')}>
                        {contactUsMailaddressTemplate()}
                    </Fieldset>
                </div>
                <div className='my-2'>
                    <Fieldset legend={t('contactUsMailaddressCC')}>
                        {contactUsMailaddressCCTemplate()}
                    </Fieldset>
                </div>
                <div className='my-2'>
                    <Fieldset legend={t('memberPassword')}>
                        {memberPasswordTemplate()}
                    </Fieldset>
                </div>
            </Panel>
            <Footer />
        </div>
    )
}

export default EditSystem