import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const LogoutPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('level');
        const timer = setTimeout(() => {
            navigate('/index');
        }, 1000);
        
        return () => clearTimeout(timer); // Cleanupタイマー
    },[navigate]);
};
export default LogoutPage;