import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { locale as primeLocale, addLocale } from 'primereact/api';
// PrimeReactのロケールをロードする関数を追加
const loadPrimeReactLocale = async (lng) => {
  try {
    const response = await fetch(`/languages/${lng}/primereact.json`);
    const data = await response.json();
    addLocale(lng, data);
    primeLocale(lng);
  } catch (error) {
    console.error('Failed to load PrimeReact locale:', error);
  }
};
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    defaultLng: 'ja',
    fallbackLng: 'ja',
    // debug: true,
    ns: ['main','validation', 'primereact'],
    fallbackNS:  ['main','validation', 'primereact'],
    detection: {
        order: ["localStrage", "navigator"]
    },
    backend: {
        loadPath: '/languages/{{lng}}/{{ns}}.json'
    },
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === 'primereact-locale') {
          primeLocale(lng);
        }
        return value;
      }
    }
  });

// i18nextの初期化後にPrimeReactのロケールをロード
i18next.on('languageChanged', (lng) => {
  loadPrimeReactLocale(lng);
});

export default i18next;