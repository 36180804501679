import React, { useState, useEffect, useRef } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';
import 'quill/dist/quill.snow.css'; // Quillのスタイル
import Quill from 'quill';

const UnderBannerManager = () =>{
    const toast = useRef(null);
    const BlockEmbed = Quill.import('blots/block/embed');
    const { t } = useTranslation();
    const [underBannerContent, setUnderBannerContent] = useState('');
    const [ showEditView, setShowEditView] = useState(false); 
    const [editId, setEditId] = useState('');
    const [editName, setEditName] = useState('');
    // const name = 'underBanner';
    // const [ value, setValue ] = useState('');


    const DefSystem = (name) => {
        const sendRequest = async (url,data) => {
            const params = new URLSearchParams(data).toString();
            const response = await fetch( `${url}?${params}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify(data)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responceData = await response.json();
            if(responceData){
                // localSave(responceData.system[0]);
                if(responceData.system[0].name === 'underBanner'){
                    // console.log('getValue:'+responceData.system[0].value)
                    setUnderBannerContent(responceData.system[0].value);
                    setEditId(responceData.system[0].id);
                    setEditName(responceData.system[0].name);
                    // setValue(responceData.system[0].value);
                }
                return responceData;
            }
        }
        const requestDB = async() => {
            try {
                    const data = { name }
                    const resData = await sendRequest(`${process.env.REACT_APP_API_SYSTEM}`,data);
                    return resData;
            } catch (error) {
                console.log(error)
                console.error("Failed to send the message: ", error);
            }
        }
        requestDB();
    }

    const sendUserDB = async(data, action) => {
        const token = sessionStorage.getItem('token');
        const dataWithToken = { ...data, token };
        // const data = { email, password, name, nickname, level };
        // console.log(data);
        if(action === 'edit'){
            // console.log('Edit')
            // console.log("data:"+JSON.stringify(data))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'PUT', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                    //     window.location.reload();
                        toast.current.show({severity:'success', summary: t('success'), detail:t('updateSuccess'), life: 3000});
                    // },1000)
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('updateError'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_SYSTEM}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    // 編集データーチェック
    const formikEdit = useFormik({
        initialValues: {
            id: '',
            name: '',
            value: '',
        },
        validate: (data) => {
            let errors = {};
            return errors;
        },
        onSubmit: (data) => {
            // console.log('val:'+data.value)
            sendUserDB(data,'edit');
            // formik.resetForm();
        }
    });
    const isFormFieldInvalid = (name) => !!(formikEdit.touched[name] && formikEdit.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formikEdit.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };


    useEffect(() => {
        DefSystem('underBanner')
    },[]);
    
    const toggleEditor = () => {
        setShowEditView(!showEditView);
    }

    class CustomSpan extends BlockEmbed {
        static create(value) {
            let node = super.create();
            node.setAttribute('id', value.id);
            node.innerHTML = value.content;
            return node;
        }
    
        static value(node) {
            return {
                id: node.getAttribute('id'),
                content: node.innerHTML
            };
        }
    }
    
    CustomSpan.blotName = 'customSpan';
    CustomSpan.tagName = 'span';


    // Quill.register(CustomSpan);


    // Quillのツールバーオプションの設定
    const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        ['link', 'image'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean']
    ];
    const modules = {
        toolbar: {
            container: toolbarOptions,
            handlers: {
                insertSpan: function () {
                    const range =  this.quill.getSelection();
                    if (range) {
                        const value = { id: 'test', content: 'test' };
                        this.quill.insertEmbed(range.index, 'customSpan', value, Quill.sources.USER);
                    }
                }
            }
        }
    };

    return(
        <div className="pb-40">
            <Header />
            <Toast ref={toast} />
            <Panel 
                header={t('underBanerManagement')}
                className='mt-20 mx-0 sm:mx-4'
            >
                <form 
                    onSubmit={formikEdit.handleSubmit} 
                    className="flex flex-col items-center"
                >
                    <div className="p-0 sm:p-2 flex flex-col w-full">
                        <div className="flex flex-row justify-between w-full">
                            <Button
                                pt={{
                                    root:{
                                        className:"bg-gray-200 border-none text-gray-600 h-10 hover:bg-gray-400 focus:shadow-none"
                                    }
                                }}
                                type='button' 
                                label={showEditView ? t('htmlView') : t('wysiwygView')} 
                                onClick={()=>toggleEditor()} 
                            />
                        </div>
                        {showEditView ? (
                            <Editor
                                inputid="value"
                                name="value"
                                value={underBannerContent}
                                modules={modules}
                                theme="snow"
                                pt={{
                                    toolbar:{
                                        className:"hidden"
                                    }
                                }}
                                onTextChange={(e) => {
                                    formikEdit.setFieldValue('value', e.htmlValue);
                                    formikEdit.setFieldValue('name', editName);
                                    formikEdit.setFieldValue('id', editId);
                                    setUnderBannerContent(e.htmlValue);
                                }}
                            />
                        ) : (
                            <InputTextarea
                                inputid="value"
                                name="value"
                                value={underBannerContent !== null ? underBannerContent : ''}
                                autoResize
                                onChange={(e) => {
                                    formikEdit.setFieldValue('value', e.target.value);
                                    formikEdit.setFieldValue('name', editName);
                                    formikEdit.setFieldValue('id', editId);
                                    setUnderBannerContent(e.target.value);
                                }}
                            />
                        )}
                        {getFormErrorMessage('value')}
                    </div>

                    {/* {localStorage.getItem('token')} */}
                    <div className="pb-5 flex flex-row">
                        <Button 
                            className="m-2 text-nowrap"
                            label={t('change')} 
                            type="submit" />
                    </div>
                </form>
            </Panel>
            <Panel 
                header={t('preview')}
                className='mt-4 mx-0 sm:mx-4'
            >
                <div className='mx-auto mt-5 pt-[20px] pb-[20px] !w-full bg-white text-gray-800'>
                    <div className="!w-full pt-4 max-w-screen-xl mx-auto justify-center items-center content-center">
                        <div className='w-fit mx-auto'>
                            <div dangerouslySetInnerHTML={{ __html: underBannerContent}}></div>
                        </div>
                    </div>
                </div>
            </Panel>
            <Footer />
        </div>
    )
}
export default UnderBannerManager