import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Template from 'components/contentsParts/Template';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useFormik } from 'formik';
// import { ContentData } from 'components/contentsParts/DbData';
import { useTranslation } from 'react-i18next';

const IndexPage = () =>{
    const { t } = useTranslation();
    const [ fieldKeyword, setFieldKeyword ] = useState('');
    const [ keyword, setKeyword ] = useState('');
    const [ results, setResults ] = useState([]);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ page, setPage ] = useState(1);
    // const [ paginationVisibility, setPaginationVisibility ] = useState(false);
    const [detailVisible, setDetailVisible] = useState(false);
    const [detailId, setDetailId] = useState('');
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const navigate = useNavigate();
    const [ token , setToken] = useState('');

    const itemsPerPage = 5;

    // 検索データーチェック
    const formik = useFormik({
        initialValues: {
            keyword:'',
        },
        validate: (data) => {
            let errors = {};
            setFieldKeyword(data.keyword)
            return errors;
        },
        onSubmit: (data) => {
            setTotalCount(0);
            setPage(1);
            setKeyword(data.keyword)
            setResults([]);
        }
    });

    useEffect(() => {
        setToken(sessionStorage.getItem('token'));
        // const fetchData = async () => {
        //     const val = localStorage.getItem('Lang');
        //     if(val){
        //         const jsData = JSON.parse(val);
        //         const data = await ContentData('index',jsData.value);
        //     }
        // }
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        // fetchData();
        window.addEventListener('resize', handleResize);
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[])



    const sendUserDB = async() => {
        const data = { keyword, page, token };
        const sendRequest = async (url) => {
            const response = await fetch(url, {
                method: 'POST', // HTTPメソッド
                headers: {
                    'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                },
                body: JSON.stringify(data), // JavaScriptオブジェクトをJSON文字列に変換
                // body: data, // JavaScriptオブジェクトをJSON文字列に変換
            });
            if (response.ok) {
                // setTimeout(() => {
                const responceData = await response.json();
                // console.log('results:'+JSON.stringify(responceData.results))
                setResults(responceData.results);
                setTotalCount(responceData.totalCount);
                // },1000)
            }else{
                // レスポンスステータスが200以外の場合のエラーハンドリング
                const errorData = await response.json();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
            }
        };
        try {
            await sendRequest(`${process.env.REACT_APP_API_SEARCH}`);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        // if (keyword) {
            sendUserDB();
        // }
    }, [keyword, page]);
    

    const handlePageChange = (event) => {
        setPage(event.page + 1);  // PrimeReactのPaginatorは0ベースなので1を足します
    };

    // const paginationVisibilityFunction = () => {
    //     if(totalCount > 0){
    //         setPaginationVisibility(true);
    //     } else{
    //         setPaginationVisibility(false);
    //     }
    // }
    const searchTitleFunction = (item) => {
        const lStrlangDef = localStorage.getItem('defaultLanguage');
        const lStrlang = localStorage.getItem('Lang');
        let lng = '';
        if(lStrlangDef){
            const data = JSON.parse(lStrlangDef);
            lng = data.value;
        }
        if(lStrlang){
            const data = JSON.parse(lStrlang);
            lng = data.value;
        }
        if(lng === 'ja')return item.titleJP;
        if(lng === 'en')return item.titleJP;
        if(lng === 'ch')return item.titleJP;

    }
    const searchSubTitleFunction = (item) => {
        const lStrlangDef = localStorage.getItem('defaultLanguage');
        const lStrlang = localStorage.getItem('Lang');
        let lng = '';
        if(lStrlangDef){
            const data = JSON.parse(lStrlangDef);
            lng = data.value;
        }
        if(lStrlang){
            const data = JSON.parse(lStrlang);
            lng = data.value;
        }
        if(lng === 'ja')return item.subTitleJP;
        if(lng === 'en')return item.subTitleEN;
        if(lng === 'ch')return item.subTitleCH;

    }
    const dateFormatFunction = (dbDate) => {
        // console.log(dbDate);
        // Dateオブジェクトに変換
        const date = new Date(dbDate);
        // 日本時間への変換
        const offset = 9 * 60; // 日本標準時（JST）はUTC+9
        const dJST = new Date(date.getTime() + offset * 60 * 1000);

        // 年、月、日を取得
        const year = dJST.getUTCFullYear();
        const month = dJST.getUTCMonth() + 1; // 月は0から始まるため+1
        const day = dJST.getUTCDate();

        // 「＠年＠月＠日」という形式の文字列を作成

        const lStrage = localStorage.getItem('Lang');
        const data = JSON.parse(lStrage);
        if(data){
            if(data.value === 'ja')return `${year}年${month}月${day}日`;
            if(data.value === 'en')return `${month}/${day}/${year}`;
            if(data.value === 'ch')return `${year}年${month}月${day}日`;
        }else{
            return
        }
    }
    
    const detailHeaderElement = () => {
        const content = results.map((result, index) => {
            if(index === detailId){
                return(
                    <div className='relative' key={result.id}>
                        {newIconFunction(result,2)}
                        <div className='text-blue-900 font-extrabold'>
                            {t('recruitId')}:【{result.recruitId}】
                        </div>
                        <div key={index} className='text-gray-800 py-2 border-y-4 border-blue-800 px-4 w-full'>
                            <div className='flex flex-col text-sm '>
                                <div className='font-bold text-gray-600'>
                                    {searchSubTitleFunction(result)}
                                </div>
                                <div className='text-xl font-extrabold text-blue-900'>
                                    {searchTitleFunction(result)}
                                </div>
                            </div>
                        </div>
                   </div>
                )
            }else{
                return null;
            }
        })
        return content;
    }
    const detailFooterContent = () => {
        return(
            <div className='w-fit mx-auto p-4'>
                <Button
                    label={t('back')}
                    onClick={() => {
                    setDetailVisible(false);
                    }}
                    pt={{
                        root:{
                            className:`rounded bg-rose-600 hover:bg-rose-700 
                            border-rose-400 hover:border-rose-400 m-2`
                        }
                    }}
                />
                <Button
                    label={t('application')}
                    onClick={() => {
                        navigate('/application');
                    }}
                    pt={{
                        root:{
                            className:`rounded bg-rose-600 hover:bg-rose-700 
                            border-rose-400 hover:border-rose-400 m-2`
                        }
                    }}
                />
            </div>
        )
    }

    const companyNameFunction = (result) =>{
        if(token){
            return(
                <div>
                    <div className='flex flex-row w-full'>
                        <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                            {t('companyNameWork')}
                        </div>
                        <div className='flex flex-row p-4 border w-full content-center'>
                            <div>{result.companyNameWork}</div>
                        </div>
                    </div>
                </div>
            )
        }else return ;
    }
    const recruitAddressFunction = (result) =>{
        if(token){
            return(
                <div>
                    <div className='flex flex-row w-full'>
                        <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                            {t('recruitAddress')}
                        </div>
                        <div className='flex flex-row p-4 border w-full content-center'>
                            <div>{result.address}</div>
                        </div>
                    </div>
                </div>
            )
        }else return ;
    }
    const contactFunction = (result) =>{
        const telContent = () =>{
            if(result.contactTel.length > 6){
                const telVal = 'tel:'+result.contactTel;
                return(
                    <>
                        <a href={telVal}>{result.contactTel}</a>
                    </>
                )
            }
        }
        const emailContent = () =>{
            if(result.contactEmail.length > 6){
                const emailVal = 'mailto:'+result.contactEmail;
                return(
                    <>
                        <a href={emailVal} target='_blank' rel="noreferrer">{result.contactEmail}</a>
                    </>
                )
            }
        }
        if(token){
            return(
                <div>
                <div className='flex flex-row w-full'>
                    <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                        {t('contactPerson')}
                    </div>
                    <div className='flex flex-row p-4 border w-full content-center'>
                        <div>{result.contactPerson}</div>
                    </div>
                </div>
                <div className='flex flex-row w-full'>
                    <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                        {t('contactEmail')}
                    </div>
                    <div className='flex flex-row p-4 border w-full content-center'>
                        <div>{emailContent()}</div>
                    </div>
                </div>
                <div className='flex flex-row w-full'>
                    <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                        {t('contactTel')}
                    </div>
                    <div className='flex flex-row p-4 border w-full content-center'>
                        <div>{telContent()}</div>
                    </div>
                </div>
                <div className='flex flex-row w-full'>
                    <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                        {t('otherContact')}
                    </div>
                    <div className='flex flex-row p-4 border w-full content-center'>
                        <div>{result.otherContact}</div>
                    </div>
                </div>
                </div>
            )
        }else return ;
    }
    const otherRequirementsFunction = (result) =>{
        if(token){
            return(
                <div>
                    <div className='flex flex-row w-full'>
                        <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                            {t('otherRequirements')}
                        </div>
                        <div className='flex flex-row p-4 border w-full content-center'>
                            <div>{result.otherRequirements}</div>
                        </div>
                    </div>
                </div>
            )
        }else return ;
    }
    const headCompanyFunction = (result) =>{

        const formatWebAddress = (webAddress) => {
            if (!webAddress.startsWith('http://') && !webAddress.startsWith('https://')) {
                return `http://${webAddress}`;
            }
            return webAddress;
        };
        if(token){
            return(
                <div>
                    <div className='flex flex-row w-full'>
                        <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                            {t('headCompany')}
                        </div>
                        <div className='flex flex-row p-4 border w-full content-center'>
                            <div>{result.headCompany}</div>
                        </div>
                    </div>
                    <div className='flex flex-row w-full'>
                        <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                            {t('webAddress')}
                        </div>
                        <div className='flex flex-row p-4 border w-full content-center'>
                            <div><a href={formatWebAddress(result.webAddress)} target="_blank" rel="noreferrer">{result.webAddress}</a></div>
                        </div>
                    </div>
                </div>
            )
        }else return ;
    }
    
    const noteFunction = (result) => {
        if(token){
            return (
                <div>
                    <div className='flex flex-row w-full'>
                        <div className='border-gray-200 border p-2 w-[140px] text-sm bg-sky-200 content-center'>
                            {t('note')}
                        </div>
                        <div className='flex flex-row p-4 border w-full content-center'>
                            <div>{result.note}</div>
                        </div>
                    </div>
                </div>
            )
        }else return ;
    }
    const detailContent = () => {
        const content = results.map((result, index) => {
            if(index === detailId){
                return(
                    <div key={result.id}>
                        <div className='border-gray-200 border p-0 my-4 !shadow !shadow-gray-300'
                            key={result.recruitId}>
                            {companyNameFunction(result)}
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('workLocation')}
                                </div>
                                <div className='flex flex-row p-4 border w-full content-center'>
                                    <div className='pr-1'>{result.country}</div>
                                    <div className='pr-1'>{result.province}</div>
                                    <div className='pr-1'>{result.city}</div>
                                </div>
                            </div>
                            {recruitAddressFunction(result)}
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('employeesNumber')}
                                </div>
                                <div className='flex flex-col p-4 border w-full items-left content-center'>
                                    <div>{result.employeesNumber}</div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('recruitJapanes')}
                                </div>
                                <div className='flex flex-col p-4 border w-full items-left content-center'>
                                    <div>{result.japanes}</div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('bussiness')}
                                </div>
                                <div className='flex flex-col p-4 border w-full items-left content-center'>
                                    <div>{result.bussiness}</div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('job')}
                                </div>
                                <div className='flex flex-col p-4 border w-full items-left content-center'>
                                    <div>{result.job}</div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('requirements')}
                                </div>
                                <div className='flex flex-row p-4 border w-full content-center'>
                                    <div>{result.requirements}</div>
                                </div>
                            </div>
                            {otherRequirementsFunction(result)}
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('recruitLanguage')}
                                </div>
                                <div className='flex flex-row p-4 border w-full content-center'>
                                    <div>{result.language}</div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('salaly')}
                                </div>
                                <div className='flex flex-col p-4 border w-full items-left content-center'>
                                    <div>{result.salaly}</div>
                                </div>
                            </div>
                            {/* <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('bonus')}
                                </div>
                                <div className='flex flex-col p-4 border w-full items-left content-center'>
                                    <div>{result.bonus}</div>
                                </div>
                            </div> */}
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('workingTime')}
                                </div>
                                <div className='flex flex-row p-4 border w-full content-center'>
                                    <div>{result.workingTime}</div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('holyday')}
                                </div>
                                <div className='flex flex-row p-4 border w-full content-center'>
                                    <div>{result.holyday}</div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('housingBenefit')}
                                </div>
                                <div className='flex flex-row p-4 border w-full content-center'>
                                    <div>{result.housingBenefit}</div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('otherBenefit')}
                                </div>
                                <div className='flex flex-row p-4 border w-full content-center'>
                                    <div>{result.otherBenefit}</div>
                                </div>
                            </div>
                            {headCompanyFunction(result)}
                            {contactFunction(result)}
                            {noteFunction(result)}

                        </div>
                        <div>
                            <div className='px-2 w-fit  ml-auto mr-0'>
                                {dateFormatFunction(result.publicDate)}
                            </div>
                            {(token)&&(
                            <div className='px-2 w-fit  ml-auto mr-0'>
                                {t('modificationDate')}:{dateFormatFunction(result.modificationDate)}
                            </div>
                            )}
                        </div>
                    </div>
                )
            }else{
                return null;
            }
        })
        // const num = 420;
        const getClassName = () =>{
            if(windowHeight > 1200)return 'w-100 h-[900px]';
            if(windowHeight > 1100)return 'w-100 h-[800px]';
            if(windowHeight > 1000)return 'w-100 h-[700px]';
            if(windowHeight > 900)return 'w-100 h-[600px]';
            if(windowHeight > 800)return 'w-100 h-[500px]';
            if(windowHeight > 700)return 'w-100 h-[400px]';
            if(windowHeight > 600)return 'w-100 h-[300px]';
            if(windowHeight > 500)return 'w-100 h-[200px]';
        } 
        return(
            <div key='Detal01'>
                <ScrollPanel className={getClassName()} >
                    {content}
                </ScrollPanel>
            </div>
        )
    }
    const newIconFunction = (result,temp) => {

        const date = new Date(result.publicDate);
        const currentDate = new Date();
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(currentDate.getMonth() - 1);
        if(date >= oneMonthAgo){
            if(temp === 1){
                return (
                    <div className='absolute left-1 top-[-20px]'>
                        <Badge 
                            value="New" 
                            pt={{
                                root:{
                                    className:'bg-blue-800 text-[0.8rem] px-2'
                                }
                            }}
                        />
                    </div>
                )
            }
            if(temp === 2){
                return (
                    <div className='absolute right-1 top-[-2px]'>
                        <Badge 
                            value="New" 
                            pt={{
                                root:{
                                    className:'bg-blue-800 !text-[0.9rem] px-2'
                                }
                            }}
                        />
                    </div>
                )
            }
            
        }else{
            return
        }
    }

    const searchContentData = () => {
        return(
            <div className='w-[100%] md:w-[80%] mx-auto item-center'>
                <form 
                    onSubmit={formik.handleSubmit} 
                    className="w-fit !max-w-none mx-auto"
                >
                    <div className="flex jutify-center flex-col">
                        <div className='p-inputgroup flex-1 w-[300px] md:w-[500px] mx-auto mb-8 px-2'>
                            <InputText 
                                type="text"
                                value={fieldKeyword}
                                placeholder={t("searchPrefix")} 
                                onChange={(e) => {
                                    formik.setFieldValue('keyword', e.target.value)
                                }}
                                pt={{
                                    root:{
                                        className:'rounded-e-none hover:border-sky-400 w-full focus:shadow-none'
                                    }
                                }}
                            />
                            <Button icon="pi pi-search" className="p-button-warning"
                                type="submit"
                                pt={{
                                    root:{
                                        className:'rounded-s-none bg-sky-500 hover:bg-sky-700 border-sky-400'
                                    }
                                }}
                            />
                        </div>
                        {(totalCount === 0)&&(
                            <div className='mb-[20%] w-full p-4'>
                                {t('searchGuide')}
                            </div>
                        )}
                    </div>
                </form>
                {(totalCount > 0)&&(
                <Paginator
                    first={itemsPerPage * (page - 1)}
                    rows={itemsPerPage}
                    totalRecords={totalCount}
                    onPageChange={handlePageChange}
                />
                )}
                <div>
                    {results.map((result, index) => (
                        <div className='border-gray-200 border-t-8 rounded-b-xl p-0 my-4 !shadow !shadow-gray-300 border-double border-blue-800 !relative'
                             key={index}>
                                {newIconFunction(result,1)}
                            <div className='text-gray-800 pb-4 border-b px-4 w-full '>
                                <div className='flex flex-row text-md pt-6'>
                                    <div className='text-blue-900 font-extrabold'>
                                        【{result.recruitId}】
                                    </div>
                                    <div className='font-bold text-gray-600'>
                                        {searchSubTitleFunction(result)}
                                    </div>
                                </div>
                                <div className='text-xl font-extrabold text-blue-900'>
                                    {searchTitleFunction(result)}
                                </div>
                            </div>
                            {companyNameFunction(result)}
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('workLocation')}
                                </div>
                                <div className='flex flex-row p-4 border w-full content-center'>
                                    <div className='pr-1'>{result.country}</div>
                                    <div className='pr-1'>{result.province}</div>
                                    <div className='pr-1'>{result.city}</div>
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('salaly')}
                                </div>
                                <div className='flex flex-col p-4 border w-full items-left content-center'>
                                    <div>{result.salaly}</div>
                                    {/* <div>{result.bonus}</div> */}
                                </div>
                            </div>
                            <div className='flex flex-row w-full'>
                                <div className='border-gray-200 border p-2 w-[140px] text-sm bg-gray-100 content-center'>
                                    {t('housingBenefit')}
                                </div>
                                <div className='flex flex-row p-4 border w-full content-center'>
                                    <div>{result.housingBenefit}</div>
                                </div>
                            </div>
                            <div>
                                <div className='px-2 w-fit  ml-auto mr-0'>
                                {dateFormatFunction(result.publicDate)}
                                </div>
                                {(token)&&(
                                <div className='px-2 w-fit  ml-auto mr-0'>
                                {t('modificationDate')}:{dateFormatFunction(result.modificationDate)}
                                </div>
                                )}
                            </div>
                            <div className='flex flex-row mx-auto p-2 w-fit'>
                                <div className=''>
                                <Button
                                    label={t('detail')}
                                    onClick={() => {
                                        setDetailVisible(true);
                                        setDetailId(index);
                                    }}
                                    pt={{
                                        root:{
                                            className:`rounded 
                                            bg-rose-600 hover:bg-rose-700 
                                            border-rose-400 hover:border-rose-400
                                             m-2`
                                        }
                                    }}
                                />
                                </div>
                                <div>
                                    <Button
                                        label={t('application')}
                                        onClick={() => {
                                            navigate('/application');
                                        }}
                                        pt={{
                                            root:{
                                                className:`rounded 
                                                bg-rose-600 hover:bg-rose-700 
                                                border-rose-400 hover:border-rose-400
                                                 m-2`
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {(totalCount > 0)&&(
                <Paginator
                    first={itemsPerPage * (page - 1)}
                    rows={itemsPerPage}
                    totalRecords={totalCount}
                    onPageChange={handlePageChange}
                />
                )}
                <Dialog 
                    visible={detailVisible} modal  
                    header={detailHeaderElement} 
                    footer={detailFooterContent} 
                    style={{ width: '50rem' }} 
                    draggable
                    maximizable
                    resizable
                    onHide={() => {if (!detailVisible) return; setDetailVisible(false); }}>
                    {detailContent()}
                </Dialog>
                </div>
        )
    }
    return Template('index',searchContentData());
}

export default IndexPage