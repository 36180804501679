import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Calendar } from 'primereact/calendar';
import { FilterMatchMode } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';

const EditRecruit = () =>{
    const [ systemData, setSystemData ] = useState('');
    const [ spPrecautions, setSpPrecautions ] = useState('');
    const [ spPrecautionsId, setSpPrecautionsId ] = useState('');
    const toast = useRef(null);
    const [ publicLanguageSelect, setPublicLanguageSelect ] = useState();
    const [ defaultLanguage, setDefaultLanguage ] = useState();
    const [activeIndex, setActiveIndex] = useState(3);
    const [ hiddenEditTab, setHiddenEditTab] = useState(0);
    const { t } = useTranslation();
    const calendarRef = useRef(null);
    const inputRef = useRef(null);
    const [ lang, setLang ] = useState('ja');

    const [ publicDate, setPublicDate] = useState('');
    const [ recruitId, setRecruitId] = useState('');
    const [ titleJP, setTitleJP] = useState('');
    const [ titleEN, setTitleEN] = useState('');
    const [ titleCH, setTitleCH] = useState('');
    const [ subTitleJP, setSubTitleJP] = useState('');
    const [ subTitleEN, setSubTitleEN] = useState('');
    const [ subTitleCH, setSubTitleCH] = useState('');
    const [ companyNameWork, setCompanyNameWork] = useState('');
    const [ country, setCountry] = useState('');
    const [ province, setProvince] = useState('');
    const [ city, setCity] = useState('');
    const [ address, setAddress] = useState('');
    const [ contactPerson, setContactPerson] = useState('');
    const [ contactTel, setContactTel] = useState('');
    const [ contactEmail, setContactEmail] = useState('');
    const [ otherContact, setOtherContact] = useState('');
    const [ headCompany, setHeadCompany] = useState('');
    const [ webAddress, setWebAddress] = useState('');
    const [ note, setNote] = useState('');
    const [ employeesNumber, setEmployeesNumber] = useState('');
    const [ japanes, setJapanes] = useState('');
    const [ bussiness, setBussiness] = useState('');
    const [ job, setJob] = useState('');
    const [ requirements, setRequirements] = useState('');
    const [ language, setLanguage] = useState('');
    const [ otherRequirements, setOtherRequirements] = useState('');
    const [ salaly, setSalaly] = useState('');
    const [ bonus, setBonus] = useState('');
    const [ housingBenefit, setHousingBenefit] = useState('');
    const [ otherBenefit, setOtherBenefit] = useState('');
    const [ workingTime, setWorkingTime] = useState('');
    const [ holyday, setHolyday] = useState('');
    const [ visiblity, setVisiblity] = useState('');

    const [ recruitsData, setRecruitsData ] = useState([]);
    const [ selectRecruitsData, setSelectRecruitsData ] = useState(null);

    const [ editRecruitId, setEditRecruitId] = useState('');
    const [ editPublicDate, setEditPublicDate] = useState('');
    const [ editTitleJP, setEditTitleJP] = useState('');
    const [ editTitleEN, setEditTitleEN] = useState('');
    const [ editTitleCH, setEditTitleCH] = useState('');
    const [ editSubTitleJP, setEditSubTitleJP] = useState('');
    const [ editSubTitleEN, setEditSubTitleEN] = useState('');
    const [ editSubTitleCH, setEditSubTitleCH] = useState('');
    const [ editCompanyNameWork, setEditCompanyNameWork] = useState('');
    const [ editCountry, setEditCountry] = useState('');
    const [ editProvince, setEditProvince] = useState('');
    const [ editCity, setEditCity] = useState('');
    const [ editAddress, setEditAddress] = useState('');
    const [ editContactPerson, setEditContactPerson] = useState('');
    const [ editContactTel, setEditContactTel] = useState('');
    const [ editContactEmail, setEditContactEmail] = useState('');
    const [ editOtherContact, setEditOtherContact] = useState('');
    const [ editHeadCompany, setEditHeadCompany] = useState('');
    const [ editWebAddress, setEditWebAddress] = useState('');
    const [ editNote, setEditNote] = useState('');
    const [ editEmployeesNumber, setEditEmployeesNumber] = useState('');
    const [ editJapanes, setEditJapanes] = useState('');
    const [ editBussiness, setEditBussiness] = useState('');
    const [ editJob, setEditJob] = useState('');
    const [ editRequirements, setEditRequirements] = useState('');
    const [ editLanguage, setEditLanguage] = useState('');
    const [ editOtherRequirements, setEditOtherRequirements] = useState('');
    const [ editSalaly, setEditSalaly] = useState('');
    const [ editBonus, setEditBonus] = useState('');
    const [ editHousingBenefit, setEditHousingBenefit] = useState('');
    const [ editOtherBenefit, setEditOtherBenefit] = useState('');
    const [ editWorkingTime, setEditWorkingTime] = useState('');
    const [ editHolyday, setEditHolyday] = useState('');
    const [ editVisiblity, setEditVisiblity] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    // const [selectDel, setSelectDel ] = useState('');
    let selectDel=''
    let selectExcel=''

    const fieldMapping = {
        recruitId: '募集番号',
        publicDate: '掲載日',
        titleJP: 'タイトル（JP）',
        titleEN: 'タイトル（EN）',
        titleCH: 'タイトル（CH）',
        subTitleJP: 'サブタイトル（JP）',
        subTitleEN: 'サブタイトル（EN）',
        subTitleCH: 'サブタイトル（CH）',
        companyNameWork: '勤務先会社名',
        country: '国',
        province: '省',
        city: '市',
        address: '詳細住所',
        contactPerson: '担当者',
        contactTel: '担当携帯',
        contactEmail: '担当E-mail',
        otherContact: '他の連絡方法',
        headCompany: '本社名',
        webAddress: 'WEBサイト',
        note: '備考',
        employeesNumber: '勤務先従業員数',
        japanes: '勤務先日本人数',
        bussiness: '勤務先事業内容',
        job: '仕事内容',
        requirements: '応募条件',
        language: '必要な語学',
        otherRequirements: '年齢その他要求（HPに掲載できない要求）',
        salaly: '給与',
        bonus: '',
        housingBenefit: '住宅手当',
        otherBenefit: '待遇その他',
        workingTime: '勤務時間',
        holyday: '休日',
        visiblity: '掲載',
    };
    const mappedData = recruitsData.map((item) => {
        let mappedItem = {};
        for (let key in item) {
            if (fieldMapping[key]) {
                mappedItem[fieldMapping[key]] = item[key];
            } else {
                mappedItem[key] = item[key]; // マッピングにないフィールドはそのまま
            }
        }
        return mappedItem;
    });
    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(mappedData);
            // const worksheet = xlsx.utils.json_to_sheet(recruitsData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, '求人募集情報');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date() + EXCEL_EXTENSION);
            }
        });
    };
    
    const formatDataTime = (dateTimeString) =>{
        const date = new Date(dateTimeString);
        
        // 日付を "YYYY-MM-DD" 形式にフォーマット
        const formattedDate = date.toISOString().split('T')[0];
        
        // 時間を "HH:MM:SS" 形式にフォーマット
        const formattedTime = date.toTimeString().split(' ')[0];
    
        return `${formattedDate} ${formattedTime}`;
    }

    const exportExcelSingle = () => {
        const filteredData = recruitsData.filter(item => item.id === selectExcel);
        const mappedDataSingle = filteredData.map((item) => {
            let mappedItem = {};
            for (let key in item) {
                if (fieldMapping[key]) {
                    mappedItem[fieldMapping[key]] = item[key];
                } else {
                    mappedItem[key] = item[key]; // マッピングにないフィールドはそのまま
                }
            }
            return mappedItem;
        });
    
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('求人募集情報');
    
        const keys = Object.keys(mappedDataSingle[0]);
    
        let currentRow = 1;
        
        worksheet.getCell(currentRow, 1).value = 'ワーフ・マネジメント WAH FOOK MANAGEMENT.,LTD';
        worksheet.getCell(currentRow, 1).font = { size: 14, bold: true };
        worksheet.getCell(currentRow, 1).alignment = { horizontal: 'center', vertical: 'center' };
        // worksheet.getCell(currentRow, 1).fill ={
        //     type:'pattern',
        //     pattern:'solid',
        //     fgColor:{argb:'FFCCCCCC'}
        // }
        worksheet.getRow(currentRow).height = 1 * 17; // 1行あたりの高さを15ピクセルに設定（必要に応じて調整）
        worksheet.mergeCells(currentRow, 1, currentRow, 4); 
        currentRow++;


        for (let i = 1; i < 3; i++) {
            if(i===1){
                worksheet.getCell(currentRow, i ).value = keys[i];
                worksheet.getCell(currentRow, i ).font = { size: 11, bold: true };
                worksheet.getCell(currentRow, i ).alignment = { wrapText: true };
                worksheet.getColumn( i ).width = 20; // フィールド名の列幅を20に設定
                worksheet.getCell(currentRow,i ).fill ={
                    type:'pattern',
                    pattern:'solid',
                    fgColor:{argb:'FFCCCCCC'}
                }
                worksheet.getCell(currentRow,i ).alignment = { horizontal: 'center', vertical: 'center' };
                worksheet.mergeCells(currentRow, i , currentRow, 2); 
            }else{
                worksheet.getCell(currentRow, i + 1).value = keys[i];
                worksheet.getCell(currentRow, i + 1).font = { size: 11, bold: true };
                worksheet.getCell(currentRow, i + 1).alignment = { wrapText: true };
                worksheet.getColumn(i + 1).width = 20; // フィールド名の列幅を20に設定
                worksheet.getCell(currentRow,i + 1).fill ={
                    type:'pattern',
                    pattern:'solid',
                    fgColor:{argb:'FFCCCCCC'}
                }
                worksheet.getCell(currentRow,i + 1).alignment = { horizontal: 'center', vertical: 'center' };
                worksheet.mergeCells(currentRow, i + 1, currentRow, 4); 
            }
        }
        currentRow++;
        for (let i = 1; i < 3; i++) {
            if(i===1){
                worksheet.getCell(currentRow, i ).value = mappedDataSingle[0][keys[i]];
                worksheet.getCell(currentRow, i ).font = { size: 11};
                worksheet.getCell(currentRow, i ).alignment = { wrapText: true };
                worksheet.getColumn(i ).width = 20; // フィールド名の列幅を20に設定
                worksheet.getCell(currentRow,i ).alignment = { wrapText: true, horizontal: 'center', vertical: 'center' };
                worksheet.mergeCells(currentRow, i , currentRow, 2); 
            }else{
                worksheet.getCell(currentRow, i + 1).value = formatDataTime(mappedDataSingle[0][keys[i]]);
                worksheet.getCell(currentRow, i + 1).font = { size: 11};
                worksheet.getCell(currentRow, i + 1).alignment = { wrapText: true };
                worksheet.getColumn(i ).width = 20; // フィールド名の列幅を20に設定
                worksheet.getCell(currentRow,i + 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'center' };
                worksheet.mergeCells(currentRow, i + 1, currentRow, 4); 
            }
        }
        currentRow++;
        currentRow++;
    
        for (let i = 3; i < 10; i++) {
            if( mappedDataSingle[0][keys[i]].length > 0){
                worksheet.getCell(currentRow, 1).value = keys[i];
                worksheet.getCell(currentRow, 1).font = { size: 11, bold: true };
                worksheet.getCell(currentRow,1).fill ={
                    type:'pattern',
                    pattern:'solid',
                    fgColor:{argb:'FFCCCCCC'}
                }
                worksheet.getCell(currentRow, 2).value = mappedDataSingle[0][keys[i]];
                worksheet.getCell(currentRow, 2).font = { size: 11 };
                worksheet.getCell(currentRow, 2).alignment = { wrapText: true };
                worksheet.mergeCells(currentRow, 2, currentRow, 4); // データを表示するセルを3列分に結合
                worksheet.getCell(currentRow, 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'center' };
                currentRow++;
            }
            // 空白行を追加
            if(i===5)currentRow++;
            if(i===8)currentRow++;
            if(i===9)currentRow++;
        }
    
        for (let i = 10; i < 13; i++) {
            worksheet.getCell(currentRow, i - 10 + 1).value = keys[i];
            worksheet.getCell(currentRow, i - 10 + 1).font = { size: 11, bold: true };
            worksheet.getCell(currentRow, i - 10 + 1).alignment = { wrapText: true };
            worksheet.getColumn(i + 1).width = 20; // フィールド名の列幅を20に設定
            worksheet.getCell(currentRow,i - 10 + 1).fill ={
                type:'pattern',
                pattern:'solid',
                fgColor:{argb:'FFCCCCCC'}
            }
            worksheet.getCell(currentRow,i - 10 + 1).alignment = { horizontal: 'center', vertical: 'center' };
            if(i===12){
                worksheet.mergeCells(currentRow, i - 10 + 1, currentRow, 4); 
            }
        }
        currentRow++;
        for (let i = 10; i < 13; i++) {
            worksheet.getCell(currentRow, i - 10 + 1).value = mappedDataSingle[0][keys[i]];
            worksheet.getCell(currentRow, i - 10 + 1).font = { size: 11 };
            worksheet.getCell(currentRow, i - 10 + 1).alignment = { wrapText: true };
            worksheet.getColumn(i + 1).width = 20; // フィールド名の列幅を20に設定
            worksheet.getCell(currentRow,i - 10 + 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'center' };
            if(i===12){
                worksheet.mergeCells(currentRow, i - 10 + 1, currentRow, 4); 
            }
        }
        currentRow++;
        
        for (let i = 13; i < 14; i++) {
            worksheet.getCell(currentRow, 1).value = keys[i];
            worksheet.getCell(currentRow, 1).font = { size: 11, bold: true };
            worksheet.getCell(currentRow, 1).alignment = { wrapText: true };
            worksheet.getCell(currentRow, 1).fill ={
                type:'pattern',
                pattern:'solid',
                fgColor:{argb:'FFCCCCCC'}
            }
            if(i===13)worksheet.getCell(currentRow, 1).alignment = { horizontal: 'center', vertical: 'center' };
            if(i===13){
                worksheet.mergeCells(currentRow, 1, currentRow, 4); 
            }
        }
        currentRow++;
        for (let i = 13; i < 14; i++) {
            worksheet.getCell(currentRow, 1).value = mappedDataSingle[0][keys[i]];
            worksheet.getCell(currentRow, 1).font = { size: 11 };
            worksheet.getCell(currentRow, 1).alignment = { wrapText: true };
            if(i===13)worksheet.getCell(currentRow, 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'top' };
            if(i===13){
                worksheet.mergeCells(currentRow, 1, currentRow, 4); 
                currentRow++;
            }
        }
        currentRow++;
        
        for (let i = 14; i < 20; i += 2) {
            for (let j = 0; j < 2; j++) {
                const columnIndex = i + j;
                if (columnIndex < keys.length) {
                    worksheet.getCell(currentRow, j * 2 + 1).value = keys[columnIndex];
                    worksheet.getCell(currentRow, j * 2 + 1).font = { size: 11, bold: true };
                    worksheet.getCell(currentRow, j * 2 + 1).alignment = { horizontal: 'center', vertical: 'center' };
                    worksheet.getCell(currentRow, j * 2 + 1).fill ={
                        type:'pattern',
                        pattern:'solid',
                        fgColor:{argb:'FFCCCCCC'}
                    }
                    worksheet.getCell(currentRow, j * 2 + 2).value = mappedDataSingle[0][keys[columnIndex]];
                    worksheet.getCell(currentRow, j * 2 + 2).font = { size: 11 };
                    worksheet.getCell(currentRow, j * 2 + 2).alignment = { wrapText: true, horizontal: 'center', vertical: 'center' };
                    worksheet.getColumn(j * 2 + 2).width = 20; // フィールド名の列幅を20に設定
                }
                if(i===16){
                    // 改行数を計算
                    const lineCount = (mappedDataSingle[0][keys[17]].match(/\n/g) || []).length + 1; // 改行数 + 1行分
                    // 改行数に基づいて行の高さを設定
                    worksheet.getRow(currentRow).height = lineCount * 15; // 1行あたりの高さを15ピクセルに設定（必要に応じて調整）
                }
            }
            if(i === 18)currentRow++;
            currentRow++;
        }
    
        for (let i = 20; i < 21; i++) {
            worksheet.getCell(currentRow, 1).value = keys[i];
            worksheet.getCell(currentRow, 1).font = { size: 11, bold: true };
            worksheet.getCell(currentRow, 1).alignment = { horizontal: 'center', vertical: 'center'  };
            worksheet.getCell(currentRow, 1).fill ={
                type:'pattern',
                pattern:'solid',
                fgColor:{argb:'FFCCCCCC'}
            }
            worksheet.mergeCells(currentRow, 1, currentRow, 4);
        }
        currentRow++;
        for (let i = 20; i < 21; i++) {
            worksheet.getCell(currentRow, 1).value = mappedDataSingle[0][keys[i]];
            worksheet.getCell(currentRow, 1).font = { size: 11 };
            worksheet.getCell(currentRow, 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'top'  };
            worksheet.mergeCells(currentRow, 1, currentRow, 4); 
            // 改行数を計算
            const lineCount = (mappedDataSingle[0][keys[i]].match(/\n/g) || []).length + 1; // 改行数 + 1行分
            // 改行数に基づいて行の高さを設定
            worksheet.getRow(currentRow).height = lineCount * 15; // 1行あたりの高さを15ピクセルに設定（必要に応じて調整）
            currentRow++;
        }
        currentRow++;

        for (let i = 21; i < 23 ; i += 2) {
            for (let j = 0; j < 2; j++) {
                const columnIndex = i + j;
                if (columnIndex < keys.length) {
                    worksheet.getCell(currentRow, j * 2 + 1).value = keys[columnIndex];
                    worksheet.getCell(currentRow, j * 2 + 1).font = { size: 11, bold: true };
                    worksheet.getCell(currentRow, j * 2 + 1).fill ={
                        type:'pattern',
                        pattern:'solid',
                        fgColor:{argb:'FFCCCCCC'}
                    }
                    worksheet.getCell(currentRow, j * 2 + 2).value = mappedDataSingle[0][keys[columnIndex]];
                    worksheet.getCell(currentRow, j * 2 + 2).font = { size: 11 };
                    worksheet.getCell(currentRow, j * 2 + 2).alignment = { wrapText: true, horizontal: 'center', vertical: 'top'  };
                }
            }
            if(i === 21)currentRow++;
            currentRow++;
        }

        for (let i = 23; i < 28; i++) {
            worksheet.getCell(currentRow, 1).value = keys[i];
            worksheet.getCell(currentRow, 1).font = { size: 11, bold: true };
            worksheet.getCell(currentRow, 1).alignment = { wrapText: true , horizontal: 'center', vertical: 'center' };
            worksheet.getCell(currentRow, 1).fill ={
                type:'pattern',
                pattern:'solid',
                fgColor:{argb:'FFCCCCCC'}
            }
            worksheet.mergeCells(currentRow, 1, currentRow, 4);

            worksheet.getCell(currentRow + 1, 1).value = mappedDataSingle[0][keys[i]];
            worksheet.getCell(currentRow + 1, 1).alignment = { wrapText: true , horizontal: 'center', vertical: 'top' };
            worksheet.getCell(currentRow + 1, 1).font = { size: 11 };
            worksheet.mergeCells(currentRow + 1, 1, currentRow + 1, 4); 
            // 改行数を計算
            const lineCount = (mappedDataSingle[0][keys[i]].match(/\n/g) || []).length + 1; // 改行数 + 1行分
            // 改行数に基づいて行の高さを設定
            worksheet.getRow(currentRow + 1).height = lineCount * 15; // 1行あたりの高さを15ピクセルに設定（必要に応じて調整）

            if(i === 27)currentRow++;

            currentRow = currentRow+2;
        }



        for (let i = 28; i < 34; i++) {
            if(i !== 29){

                worksheet.getCell(currentRow, 1).value = keys[i];
                worksheet.getCell(currentRow, 1).font = { size: 11, bold: true };
                worksheet.getCell(currentRow, 1).alignment = { wrapText: true, horizontal: 'center', vertical: 'center' };
                worksheet.getCell(currentRow, 1).fill ={
                    type:'pattern',
                    pattern:'solid',
                    fgColor:{argb:'FFCCCCCC'}
                }
                worksheet.mergeCells(currentRow, 1, currentRow, 4);
    
                worksheet.getCell(currentRow + 1, 1).value = mappedDataSingle[0][keys[i]];
                worksheet.getCell(currentRow + 1, 1).font = { size: 11 };
                worksheet.getCell(currentRow + 1, 1).alignment = { wrapText: true , horizontal: 'center', vertical: 'top' };
                worksheet.mergeCells(currentRow + 1, 1, currentRow + 1, 4); 
                // 改行数を計算
                const lineCount = (mappedDataSingle[0][keys[i]].match(/\n/g) || []).length + 1; // 改行数 + 1行分
                // 改行数に基づいて行の高さを設定
                worksheet.getRow(currentRow + 1).height = lineCount * 15; // 1行あたりの高さを15ピクセルに設定（必要に応じて調整）
    
                if(i === 27)currentRow++;
    
                currentRow = currentRow+2;
            }
        }

        // 注意事項
        worksheet.getCell(currentRow, 1).value = spPrecautions;
        worksheet.getCell(currentRow, 1).font = { size: 10, bold: false };
        worksheet.getCell(currentRow, 1).alignment = { horizontal: 'left', vertical: 'top',wrapText: true };
        // 改行数を計算
        const lineCount = (spPrecautions.match(/\n/g) || []).length + 1; // 改行数 + 1行分
        // 改行数に基づいて行の高さを設定
        worksheet.getRow(currentRow).height = lineCount * 11 *2.5; // 1行あたりの高さを15ピクセルに設定（必要に応じて調整）
        worksheet.mergeCells(currentRow, 1, currentRow, 4); 
        currentRow++;

        for (let i = 35; i < keys.length; i += 2) {
            for (let j = 0; j < 2; j++) {
                const columnIndex = i + j;
                if (columnIndex < keys.length) {
                    worksheet.getCell(currentRow, j * 2 + 1).value = keys[columnIndex];
                    worksheet.getCell(currentRow, j * 2 + 1).font = { size: 11, bold: true };
                    worksheet.getCell(currentRow, j * 2 + 1).fill ={
                        type:'pattern',
                        pattern:'solid',
                        fgColor:{argb:'FFCCCCCC'}
                    }
                    worksheet.getCell(currentRow, j * 2 + 2).value = formatDataTime(mappedDataSingle[0][keys[columnIndex]]);
                    worksheet.getCell(currentRow, j * 2 + 1).font = { size: 11 };
                    worksheet.getCell(currentRow, j * 2 + 2).alignment = { wrapText: true };
                }
            }
            currentRow++;
        }



        // ページ設定
        worksheet.pageSetup = {
            paperSize: 9, // A4サイズ
            orientation: 'portrait', // 縦向き
            fitToPage: true, // ページに合わせる
            fitToHeight: 1, // 1ページに収める（高さ）
            fitToWidth: 1, // 1ページに収める（幅）
            margins: {
                left: 0.7, right: 0.7, top: 0.75, bottom: 0.75,
                header: 0.3, footer: 0.3
            }
        };
    
        // 印刷範囲の設定
        worksheet.pageSetup.printArea = 'A1:' + worksheet.lastColumn.letter + worksheet.lastRow.number;
    
        // 印刷タイトルの設定（1行目を印刷するたびに繰り返す）
        worksheet.pageSetup.printTitlesRow = '1:1';
    
        // Excelファイルとして書き出し
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, mappedDataSingle[0][keys[1]]+' '+mappedDataSingle[0][keys[9]]+'.xlsx');
        });
    };

    const formatWebAddress = (webAddress) => {
        if (!webAddress.startsWith('http://') && !webAddress.startsWith('https://')) {
            return `http://${webAddress}`;
        }
        return webAddress;
    };
    const formatDateForPublic = (date,forParts) => {
        const d = new Date(date);

        // 日本時間への変換
        const offset = 9 * 60; // 日本標準時（JST）はUTC+9
        const dJST = new Date(d.getTime() + offset * 60 * 1000);

        if(forParts==='calModule')return dJST;
        const year = dJST.getUTCFullYear();
        const month = ('0' + (dJST.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + dJST.getUTCDate()).slice(-2);
        return `${year}/${month}/${day}`;
    }
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };
    const sendRequest = async (url,data) => {
        const params = new URLSearchParams(data).toString();
        const response = await fetch( `${url}?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }else if(response.status === 200) {
            const responceData = await response.json();
            return responceData;
        }else{
            return "[]";
        }
    }
    const clearMemory = () => {
        formik.resetForm();
        formikEdit.resetForm();
        setPublicDate('');
        setRecruitId('');
        setTitleJP('');
        setTitleEN('');
        setTitleCH('');
        setSubTitleJP('');
        setSubTitleEN('');
        setSubTitleCH('');
        setCompanyNameWork('');
        setCountry('');
        setProvince('');
        setCity('');
        setAddress('');
        setContactPerson('');
        setContactTel('');
        setContactEmail('');
        setOtherContact('');
        setHeadCompany('');
        setWebAddress('');
        setNote('');
        setEmployeesNumber('');
        setJapanes('');
        setBussiness('');
        setJob('');
        setRequirements('');
        setLanguage('');
        setOtherRequirements('');
        setSalaly('');
        setBonus('');
        setHousingBenefit('');
        setOtherBenefit('');
        setWorkingTime('');
        setHolyday('');
        setVisiblity('');
    }

    const sendUserDB = async(data, action) => {
        const token = sessionStorage.getItem('token');
        const dataWithToken = { ...data, token };
        if(action === 'new'){
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'POST', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        // window.location.reload();
                        requestDB();
                        toast.current.show({severity:'success', summary: t('success'), detail:t('newDataCreationSuccess'), life: 3000});
                    // },1000)
                    clearMemory();
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('newDataCreationFailure'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_RECRUIT}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'edit'){
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'PUT', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        // window.location.reload();
                        requestDB();
                        toast.current.show({severity:'success', summary: t('success'), detail:t('updateSuccess'), life: 3000});
                    // },1000)
                    clearMemory();
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('updateError'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_RECRUIT}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'delete'){
            // console.log('delete')
            // console.log("data:"+JSON.stringify(data))
            if(!dataWithToken.id)dataWithToken.id=selectDel
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'DELETE', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        // window.location.reload();
                        requestDB();
                        toast.current.show({severity:'success', summary: t('success'), detail:t('deleteDataSuccess'), life: 3000});
                    // },1000)
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('deleteDataFailure'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                // console.log(dataWithToken)
                await sendRequest(`${process.env.REACT_APP_API_RECRUIT}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'editExcel'){
            console.log('excel:'+JSON.stringify(dataWithToken));
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'PUT', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        // window.location.reload();
                        requestDB();
                        toast.current.show({severity:'success', summary: t('success'), detail:t('updateSuccess'), life: 3000});
                    // },1000)
                    clearMemory();
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('updateError'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_SYSTEM}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    // 新規データチェック
    const formik = useFormik({
        initialValues: {
            publicDate: '',
            titleJP: '',
            titleEN: '',
            titleCH: '',
            subTitleJP: '',
            subTitleEN: '',
            subTitleCH: '',
            companyNameWork: '',
            country: '',
            province: '',
            city: '',
            address: '',
            contactPerson: '',
            contactTel: '',
            contactEmail: '',
            otherContact: '',
            headCompany: '',
            webAddress: '',
            note: '',
            employeesNumber: '',
            japanes: '',
            bussiness: '',
            job: '',
            requirements: '',
            language: '',
            otherRequirements: '',
            salaly: '',
            bonus: '',
            housingBenefit: '',
            otherBenefit: '',
            workingTime: '',
            holyday: '',
            visiblity: ''
        },
        validate: (data) => {
            let errors = {};
            setPublicDate(data.publicDate);
            setTitleJP(data.titleJP);
            setTitleEN(data.titleEN);
            setTitleCH(data.titleCH);
            setSubTitleJP(data.subTitleJP);
            setSubTitleEN(data.subTitleEN);
            setSubTitleCH(data.subTitleCH);
            setCompanyNameWork(data.companyNameWork);
            setCountry(data.country);
            setProvince(data.province);
            setCity(data.city);
            setAddress(data.address);
            setContactPerson(data.contactPerson);
            setContactTel(data.contactTel);
            setContactEmail(data.contactEmail);
            setOtherContact(data.otherContact);
            setHeadCompany(data.headCompany);
            setWebAddress(data.webAddress);
            setNote(data.note);
            setEmployeesNumber(data.employeesNumber);
            setJapanes(data.japanes);
            setBussiness(data.bussiness);
            setJob(data.job);
            setRequirements(data.requirements);
            setLanguage(data.language);
            setOtherRequirements(data.otherRequirements);
            setSalaly(data.salaly);
            setBonus(data.bonus);
            setHousingBenefit(data.housingBenefit);
            setOtherBenefit(data.otherBenefit);
            setWorkingTime(data.workingTime);
            setHolyday(data.holyday);
            setVisiblity(data.visiblity);
            return errors;
        },
        onSubmit: (data) => {
            // console.log('publicData:'+data.publicDate)
            sendUserDB(data, 'new');
            setActiveIndex(3);
            // formik.resetForm();
        }
    });
    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    // 編集データチェック
    const formikEdit = useFormik({
        initialValues: {
            id: '',
            recruitId: '',
            publicDate: '',
            titleJP: '',
            titleEN: '',
            titleCH: '',
            subTitleJP: '',
            subTitleEN: '',
            subTitleCH: '',
            companyNameWork: '',
            country: '',
            province: '',
            city: '',
            address: '',
            contactPerson: '',
            contactTel: '',
            contactEmail: '',
            otherContact: '',
            headCompany: '',
            webAddress: '',
            note: '',
            employeesNumber: '',
            japanes: '',
            bussiness: '',
            job: '',
            requirements: '',
            language: '',
            otherRequirements: '',
            salaly: '',
            bonus: '',
            housingBenefit: '',
            otherBenefit: '',
            workingTime: '',
            holyday: '',
            visiblity: ''
        },
        validate: (data) => {
            let errors = {};
            setEditRecruitId(data.recruitId);
            setEditPublicDate(formatDateForPublic(data.publicDate,'calModule'));
            setEditTitleJP(data.titleJP);
            setEditTitleEN(data.titleEN);
            setEditTitleCH(data.titleCH);
            setEditSubTitleJP(data.subTitleJP);
            setEditSubTitleEN(data.subTitleEN);
            setEditSubTitleCH(data.subTitleCH);
            setEditCompanyNameWork(data.companyNameWork);
            setEditCountry(data.country);
            setEditProvince(data.province);
            setEditCity(data.city);
            setEditAddress(data.address);
            setEditContactPerson(data.contactPerson);
            setEditContactTel(data.contactTel);
            setEditContactEmail(data.contactEmail);
            setEditOtherContact(data.otherContact);
            setEditHeadCompany(data.headCompany);
            setEditWebAddress(data.webAddress);
            setEditNote(data.note);
            setEditEmployeesNumber(data.employeesNumber);
            setEditJapanes(data.japanes);
            setEditBussiness(data.bussiness);
            setEditJob(data.job);
            setEditRequirements(data.requirements);
            setEditLanguage(data.language);
            setEditOtherRequirements(data.otherRequirements);
            setEditSalaly(data.salaly);
            setEditBonus(data.bonus);
            setEditHousingBenefit(data.housingBenefit);
            setEditOtherBenefit(data.otherBenefit);
            setEditWorkingTime(data.workingTime);
            setEditHolyday(data.holyday);
            setEditVisiblity(data.visiblity);
            return errors;
        },
        onSubmit: (data) => {
            sendUserDB(data, 'edit');
            setActiveIndex(3);
            // formik.resetForm();
        }
    });
    const editIsFormFieldInvalid = (name) => !!(formikEdit.touched[name] && formikEdit.errors[name]);

    const editGetFormErrorMessage = (name) => {
        return editIsFormFieldInvalid(name) ? <small className="p-error">{formikEdit.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const formikEditExcel = useFormik({
        initialValues: {
            spPrecautions: '',
        },
        validate: (data) => {
            let errors = {};
            setSpPrecautions(data.spPrecautions);
            return errors;
        },
        onSubmit: (data) => {
            const id=spPrecautionsId;
            const name='specificPrecautions';
            const value=data.spPrecautions;
            const numValue ='';
            const dataUp = {id,name,value,numValue};
            console.log("json:"+JSON.stringify(dataUp));
            sendUserDB(dataUp, 'editExcel');
            setActiveIndex(3);
            setSpPrecautions(data.spPrecautions);
        }
    });

    const requestDB = async() => {
        const token = sessionStorage.getItem('token');
        const data = { token };
        try {
            const resData = await sendRequest(`${process.env.REACT_APP_API_RECRUIT}`,data);
            setRecruitsData(resData.system);
            // return resData;
        } catch (error) {
            console.log(error)
            console.error("Failed to send the message: ", error);
        }
    }

    useEffect(() =>{
        const lStLang = localStorage.getItem('Lang');
        const lStPubLang = localStorage.getItem('publicLanguageSelect');
        const lSDefLang = localStorage.getItem('defaultLanguage');

        if(lStLang){
            const data = JSON.parse(lStLang)
            setLang(data.value);
        }
        if(lStPubLang){
            const data = JSON.parse(lStPubLang)
            setPublicLanguageSelect(data.value);
        }
        if(lSDefLang){
            const data = JSON.parse(lSDefLang)
            setDefaultLanguage(data.value);
        }

        // setLang(localStorage.getItem('Lang'));
        // setPublicLanguageSelect(localStorage.getItem('publicLanguageSelect'));
        // setDefaultLanguage(localStorage.getItem('defaultLanguage'));


        const updateCalendarPosition = () => {
            const inputElement = inputRef.current?.inputElement; // .inputElementを使用してinputフィールドにアクセス
            const calendarElement = calendarRef.current?.overlayRef?.current; // .overlayRef.currentを使用してカレンダーのDOM要素にアクセス


            if (inputElement && calendarElement) {
                const inputRect = inputElement.getBoundingClientRect();
                calendarElement.style.left = `${inputRect.left}px`;
                calendarElement.style.top = `${inputRect.bottom}px`;
            }
        };

        requestDB();
        updateCalendarPosition();
        window.addEventListener('resize', updateCalendarPosition);
    },[]);

    useEffect(() => {
        if (selectRecruitsData) {
            formikEdit.setFieldValue('id', selectRecruitsData.id);
            formikEdit.setFieldValue('recruitId', selectRecruitsData.recruitId);
            formikEdit.setFieldValue('publicDate', selectRecruitsData.publicDate);
            formikEdit.setFieldValue('titleJP', selectRecruitsData.titleJP);
            formikEdit.setFieldValue('titleEN', selectRecruitsData.titleEN);
            formikEdit.setFieldValue('titleCH', selectRecruitsData.titleCH);
            formikEdit.setFieldValue('subTitleJP', selectRecruitsData.subTitleJP);
            formikEdit.setFieldValue('subTitleEN', selectRecruitsData.subTitleEN);
            formikEdit.setFieldValue('subTitleCH', selectRecruitsData.subTitleCH);
            formikEdit.setFieldValue('companyNameWork', selectRecruitsData.companyNameWork);
            formikEdit.setFieldValue('country', selectRecruitsData.country);
            formikEdit.setFieldValue('province', selectRecruitsData.province);
            formikEdit.setFieldValue('city', selectRecruitsData.city);
            formikEdit.setFieldValue('address', selectRecruitsData.address);
            formikEdit.setFieldValue('contactPerson', selectRecruitsData.contactPerson);
            formikEdit.setFieldValue('contactTel', selectRecruitsData.contactTel);
            formikEdit.setFieldValue('contactEmail', selectRecruitsData.contactEmail);
            formikEdit.setFieldValue('otherContact', selectRecruitsData.otherContact);
            formikEdit.setFieldValue('headCompany', selectRecruitsData.headCompany);
            formikEdit.setFieldValue('webAddress', selectRecruitsData.webAddress);
            formikEdit.setFieldValue('note', selectRecruitsData.note);
            formikEdit.setFieldValue('employeesNumber', selectRecruitsData.employeesNumber);
            formikEdit.setFieldValue('japanes', selectRecruitsData.japanes);
            formikEdit.setFieldValue('bussiness', selectRecruitsData.bussiness);
            formikEdit.setFieldValue('job', selectRecruitsData.job);
            formikEdit.setFieldValue('requirements', selectRecruitsData.requirements);
            formikEdit.setFieldValue('language', selectRecruitsData.language);
            formikEdit.setFieldValue('otherRequirements', selectRecruitsData.otherRequirements);
            formikEdit.setFieldValue('salaly', selectRecruitsData.salaly);
            formikEdit.setFieldValue('bonus', selectRecruitsData.bonus);
            formikEdit.setFieldValue('housingBenefit', selectRecruitsData.housingBenefit);
            formikEdit.setFieldValue('otherBenefit', selectRecruitsData.otherBenefit);
            formikEdit.setFieldValue('workingTime', selectRecruitsData.workingTime);
            formikEdit.setFieldValue('holyday', selectRecruitsData.holyday);
            formikEdit.setFieldValue('visiblity', selectRecruitsData.visiblity);
        }
    }, [selectRecruitsData]);


    useEffect(() =>{
        const requestDB = async() => {
            try {
                const name = 'specificPrecautions';
                const resData = await sendRequest(`${process.env.REACT_APP_API_SYSTEM}`,name);
                setSystemData(resData.system);
                // return resData;
            } catch (error) {
                console.log(error)
                console.error("Failed to send the message: ", error);
            }
        }
        requestDB();
    },[]);

    useEffect(()=>{
        if(systemData){
            systemData.map((item) => {
                if(item.name ==='specificPrecautions'){
                    setSpPrecautions(item.value);
                    setSpPrecautionsId(item.id);
                }
                return null;
            })
        }

    },[systemData])



    const accept = () => {
        const data = []
        sendUserDB(data,'delete');
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        // setSelectDel('');
        selectDel = '';
    }
    const confirmDelete = () => {
        confirmDialog({
            message: t('areYouSureYouWantToDelete?'),
            header: t('confirm'),
            rejectLabel:t('no'),
            acceptLabel:t('yes'),
            defaultFocus:'reject',
            pt:{
                closeButton:{
                    className: 'focus:shadow-none'
                }
            },
            accept,
            reject
        })
    }
    const hiddenTabFunction = () => {
        if(hiddenEditTab === 0){
            return 'hidden'
        }else{
            return 'block'
        }
    }
    const headIdTemplate = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('recruitId')}
                </div>
                <div>
                    {t('publicDate')}
                </div>
                <div>
                    {t('visiblity')}
                </div>
                <div className='text-amber-900'>
                    {t('delete')}
                </div>
            </div>
        )
    }
    const idTemplate = (rowData) => {
        return(
            <div className='text-center'>
                <div className='font-bold'>
                    {rowData.recruitId}
                </div>
                <div>{formatDateForPublic(rowData.publicDate)}</div>
                {rowData.visiblity ? 
                    <div className='font-bold text-green-700 border-2 border-green-700 rounded my-1'>{t('visibled')}</div> 
                    :
                     <div className='font-bold text-amber-700 border-2 border-amber-700 rounded my-1'>{t('unVisibled')}</div>}
                <div>
                    <Button 
                        label={t('delete')}
                        onClick={() => {
                            // setSelectDel(rowData.id)
                            selectDel = rowData.id
                            confirmDelete()
                        }}
                        pt={{root:{ className:'text-sm bg-red-400 hover:bg-red-600 border-0 py-1 focus:shadow-none'}}}
                    />
                    <Button 
                        label='Excel'
                        onClick={() => {
                            // setSelectDel(rowData.id)
                            selectExcel = rowData.id
                            exportExcelSingle()
                        }}
                        pt={{root:{ className:'text-sm bg-blue-400 hover:bg-blue-600 border-0 my-2 py-1 focus:shadow-none'}}}
                    />
                </div>
            </div>
        )
    }
    // 2xl //
    const headTitletemplate = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('title')}
                </div>
                <div className='text-amber-600'>
                    {t('subTitle')}
                </div>
            </div>
        )
    }
    const titleTemplate = (rowData) => {
        return(
            <div>
                <div>{truncateText(rowData.titleJP,10)}</div>
                <div>{truncateText(rowData.titleEN,20)}</div>
                <div>{truncateText(rowData.titleCH,10)}</div>
                <div className='text-amber-600'>{truncateText(rowData.subTitleJP,10)}</div>
                <div className='text-amber-600'>{truncateText(rowData.subTitleEN,20)}</div>
                <div className='text-amber-600'>{truncateText(rowData.subTitleCH,10)}</div>
            </div>
        )
    }
    // lg- xl //
    const headTitleTemplateLg = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('title')}
                </div>
                <div className='text-amber-600'>
                    {t('subTitle')}
                </div>
            </div>
        )
    }
    const titleTemplateLg = (rowData) => {
        return(
            <div>
                <div>{truncateText(rowData.titleJP,10)}</div>
                <div>{truncateText(rowData.titleEN,20)}</div>
                <div>{truncateText(rowData.titleCH,10)}</div>
                <div className='text-amber-600'>{truncateText(rowData.subTitleJP,10)}</div>
                <div className='text-amber-600'>{truncateText(rowData.subTitleEN,20)}</div>
                <div className='text-amber-600'>{truncateText(rowData.subTitleCH,10)}</div>
            </div>
        )
    }
    // 2xl //
    const headCompanyTemplate = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('companyNameWork')}
                </div>
                <div>
                    {t('companyAddressWork')}
                </div>
                <div>
                    {t('headCompany')}
                </div>
            </div>
        )
    }
    const companyTemplate = (rowData) => {
        return(
            <div>
                <div className='font-bold'>{truncateText(rowData.companyNameWork,30)}</div>
                <div>{rowData.country} {rowData.province} {rowData.city}</div>
                <div>{truncateText(rowData.address,30)}</div>
                <div className='font-bold'>{truncateText(rowData.headCompany,20)}</div>
                <div className='font-bold text-green-800'>
                    {rowData.webAddress && (
                            <a href={`${formatWebAddress(rowData.webAddress)}`} target='_blank' rel="noreferrer noopener">
                                {rowData.webAddress}
                            </a>
                        )
                    }
                </div>
            </div>
        )
    }
    // lg-xl //
    const headCompanyTemplateLg = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('companyNameWork')}
                </div>
                <div>
                    {t('companyAddressWork')}
                </div>
                <div>
                    {t('headCompany')}
                </div>
                <div>
                    {t('contactPerson')}
                </div>
            </div>
        )
    }
    const companyTemplateLg = (rowData) => {
        return(
            <div>
                <div className='font-bold'>{truncateText(rowData.companyNameWork,30)}</div>
                <div>{rowData.country} {rowData.province} {rowData.city}</div>
                <div>{truncateText(rowData.address,30)}</div>
                <div className='font-bold'>{truncateText(rowData.headCompany,20)}</div>
                <div className='font-bold text-green-800'>
                    {rowData.webAddress && (
                            <a href={`${formatWebAddress(rowData.webAddress)}`} target='_blank' rel="noreferrer noopener">
                                {rowData.webAddress}
                            </a>
                        )
                    }
                </div>
                <div className='font-bold'>{truncateText(rowData.contactPerson,20)}</div>
                <div className='font-bold text-green-800'>
                    {rowData.contactTel && (
                            <a href={`tel:${rowData.contactTel}`} target='_blank' rel="noreferrer noopener">
                                {rowData.contactTel}
                            </a>
                        )
                    }
                </div>
                <div className='font-bold text-green-800'>
                    {rowData.contactEmail && (
                            <a href={`mailto:${rowData.contactEmail}`} target='_blank' rel="noreferrer noopener">
                                {rowData.contactEmail}
                            </a>
                        )
                    }
                </div>
                <div>{truncateText(rowData.otherContact,30)}</div>
            </div>
        )
    }
    const headContactTemplate = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('contactPerson')}
                </div>
            </div>
        )
    }
    const contactTemplate = (rowData) => {
        return(
            <div>
                <div className='font-bold'>{truncateText(rowData.contactPerson,20)}</div>
                <div className='font-bold text-green-800'>
                    {rowData.contactTel && (
                            <a href={`tel:${rowData.contactTel}`} target='_blank' rel="noreferrer noopener">
                                {rowData.contactTel}
                            </a>
                        )
                    }
                </div>
                <div className='font-bold text-green-800'>
                    {rowData.contactEmail && (
                            <a href={`mailto:${rowData.contactEmail}`} target='_blank' rel="noreferrer noopener">
                                {rowData.contactEmail}
                            </a>
                        )
                    }
                </div>
                <div>{truncateText(rowData.otherContact,30)}</div>
            </div>
        )
    }
    // 2xl //
    const headNoteTemplate = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('employeesNumber')}/{t('recruitJapanes')}
                </div>
                <div className='text-amber-600'>
                    {t('bussiness')}
                </div>
                <div>
                    {t('job')}
                </div>
                <div className='text-amber-600'>
                    {t('requirements')}
                </div>
                <div>
                    {t('recruitLanguage')}
                </div>
                <div className='text-amber-600'>
                    {t('otherRequirements')}
                </div>
                <div>
                    {t('note')}
                </div>
            </div>
        )
    }
    const noteTemplate = (rowData) => {
        return(
            <div>
                <div className='text-sm'>{rowData.employeesNumber} / {rowData.japanes}</div>
                <div className='text-sm text-amber-600'>{truncateText(rowData.bussiness,11)}</div>
                <div className='text-sm'>{truncateText(rowData.job,11)}</div>
                <div className='text-sm text-amber-600'>{truncateText(rowData.requirements,11)}</div>
                <div className='text-sm'>{truncateText(rowData.language,11)}</div>
                <div className='text-sm text-amber-600'>{truncateText(rowData.otherRequirements,11)}</div>
                <div className='text-sm'>{truncateText(rowData.note,11)}</div>
            </div>
        )
    }
    // lg-xl //
    const headNoteTemplateLg = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('employeesNumber')}/{t('recruitJapanes')}
                </div>
                <div className='text-amber-600'>
                    {t('bussiness')}
                </div>
                <div>
                    {t('job')}
                </div>
                <div className='text-amber-600'>
                    {t('requirements')}
                </div>
                <div>
                    {t('recruitLanguage')}
                </div>
                <div className='text-amber-600'>
                    {t('otherRequirements')}
                </div>
                <div>
                    {t('note')}
                </div>
            </div>
        )
    }
    const noteTemplateLg = (rowData) => {
        return(
            <div>
                <div className='text-xs'>{rowData.employeesNumber} / {rowData.japanes}</div>
                <div className='text-xs text-amber-600'>{truncateText(rowData.bussiness,11)}</div>
                <div className='text-xs'>{truncateText(rowData.job,11)}</div>
                <div className='text-xs text-amber-600'>{truncateText(rowData.requirements,11)}</div>
                <div className='text-xs'>{truncateText(rowData.language,11)}</div>
                <div className='text-xs text-amber-600'>{truncateText(rowData.otherRequirements,11)}</div>
                <div className='text-xs'>{truncateText(rowData.note,11)}</div>
            </div>
        )
    }
    // 2xl //
    const headSalalyTemplate = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('salaly')}
                </div>
                {/* <div className='text-amber-600'>
                    {t('bonus')}
                </div> */}
                <div className='text-amber-600'>
                    {t('housingBenefit')}
                </div>
                <div>
                    {t('otherBenefit')}
                </div>
                <div className='text-amber-600'>
                    {t('workingTime')}
                </div>
                <div>
                    {t('holyday')}
                </div>
            </div>
        )
    }
    const salalyTemplate = (rowData) => {
        return(
            <div>
                <div className='text-sm'>{rowData.salaly}</div>
                {/* <div className='text-sm text-amber-600'>{truncateText(rowData.bonus,11)}</div> */}
                <div className='text-sm text-amber-600'>{truncateText(rowData.housingBenefit,11)}</div>
                <div className='text-sm'>{truncateText(rowData.otherBenefit,11)}</div>
                <div className='text-sm text-amber-600'>{truncateText(rowData.workingTime,11)}</div>
                <div className='text-sm'>{truncateText(rowData.holyday,11)}</div>
            </div>
        )
    }
    // 2lg - xl //
    const headSalalyTemplateLg = () => {
        return(
            <div className='text-center'>
                <div>
                    {t('salaly')}
                </div>
                {/* <div className='text-amber-600'>
                    {t('bonus')}
                </div> */}
                <div className='text-amber-600'>
                    {t('housingBenefit')}
                </div>
                <div>
                    {t('otherBenefit')}
                </div>
                <div className='text-amber-600'>
                    {t('workingTime')}
                </div>
                <div>
                    {t('holyday')}
                </div>
            </div>
        )
    }
    const salalyTemplateLg = (rowData) => {
        return(
            <div>
                <div className='text-xs'>{rowData.salaly}</div>
                {/* <div className='text-xs text-amber-600'>{truncateText(rowData.bonus,11)}</div> */}
                <div className='text-xs text-amber-600'>{truncateText(rowData.housingBenefit,11)}</div>
                <div className='text-xs'>{truncateText(rowData.otherBenefit,11)}</div>
                <div className='text-xs text-amber-600'>{truncateText(rowData.workingTime,11)}</div>
                <div className='text-xs'>{truncateText(rowData.holyday,11)}</div>
            </div>
        )
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className='flex flex-row'>
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('keywordSearch')} />
                </IconField>
            </div>
            <Button
                label="Excel Data"
                onClick={()=>exportExcel()}
                className='ml-4'
            />
            </div>
        );
    };

    const header = renderHeader();


    const titleNewFields = () => {
        const contJP = () => {
            return(
                <div className="p-2 flex flex-col w-full">
                    <label>{t('titleJP')}</label>
                    <InputText
                        inputid="titleJP"
                        name="titleJP"
                        value={titleJP}
                        placeholder={t('titleJP')}
                        onChange={(e) => {
                            formik.setFieldValue('titleJP', e.target.value);
                        }}
                        autoComplete="titleJP" />
                        {getFormErrorMessage('titleJP')}
                </div>
            )
        }
        const contEN = () => {
            return(
                <div className="p-2 flex flex-col w-full">
                    <label>{t('titleEN')}</label>
                    <InputText
                        inputid="titleEN"
                        name="titleEN"
                        value={titleEN}
                        placeholder={t('titleEN')}
                        onChange={(e) => {
                            formik.setFieldValue('titleEN', e.target.value);
                        }}
                        autoComplete="titleEN" />
                        {getFormErrorMessage('titleEN')}
                </div>
            )
        }
        const contCH = () => {
            return(
                <div className="p-2 flex flex-col w-full">
                    <label>{t('titleCH')}</label>
                    <InputText
                        inputid="titleCH"
                        name="titleCH"
                        value={titleCH}
                        placeholder={t('titleCH')}
                        onChange={(e) => {
                            formik.setFieldValue('titleCH', e.target.value);
                        }}
                        autoComplete="titleCH" />
                        {getFormErrorMessage('titleCH')}
                </div>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                    {contJP()}
                    {contEN()}
                    {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const subTitleNewFields = () => {
        const contJP = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('subTitleJP')}</label>
                    <InputText
                        inputid="subTitleJP"
                        name="subTitleJP"
                        value={subTitleJP}
                        placeholder={t('subTitleJP')}
                        onChange={(e) => {
                            formik.setFieldValue('subTitleJP', e.target.value);
                        }}
                        autoComplete="subTitleJP" />
                        {getFormErrorMessage('subTitleJP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('subTitleEN')}</label>
                    <InputText
                        inputid="subTitleEN"
                        name="subTitleEN"
                        value={subTitleEN}
                        placeholder={t('subTitleEN')}
                        onChange={(e) => {
                            formik.setFieldValue('subTitleEN', e.target.value);
                        }}
                        autoComplete="subTitleEN" />
                        {getFormErrorMessage('subTitleEN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('subTitleCH')}</label>
                    <InputText
                        inputid="subTitleCH"
                        name="subTitleCH"
                        value={subTitleCH}
                        placeholder={t('subTitleCH')}
                        onChange={(e) => {
                            formik.setFieldValue('subTitleCH', e.target.value);
                        }}
                        autoComplete="subTitleCH" />
                        {getFormErrorMessage('subTitleCH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const titleEditFields = () => {
        const contJP = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('titleJP')}</label>
                    <InputText
                        inputid="titleJP"
                        name="titleJP"
                        value={editTitleJP}
                        placeholder={t('titleJP')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('titleJP', e.target.value);
                        }}
                        autoComplete="titleJP" />
                        {editGetFormErrorMessage('titleJP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                        <label>{t('titleEN')}</label>
                        <InputText
                            inputid="titleEN"
                            name="titleEN"
                            value={editTitleEN}
                            placeholder={t('titleEN')}
                            onChange={(e) => {
                                formikEdit.setFieldValue('titleEN', e.target.value);
                            }}
                            autoComplete="titleEN" />
                            {editGetFormErrorMessage('titleEN')}
                    </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('titleCH')}</label>
                    <InputText
                        inputid="titleCH"
                        name="titleCH"
                        value={editTitleCH}
                        placeholder={t('titleCH')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('titleCH', e.target.value);
                        }}
                        autoComplete="titleCH" />
                        {editGetFormErrorMessage('titleCH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    const subTitleEditFields = () => {
        const contJP = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('subTitleJP')}</label>
                    <InputText
                        inputid="subTitleJP"
                        name="subTitleJP"
                        value={editSubTitleJP}
                        placeholder={t('subTitleJP')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('subTitleJP', e.target.value);
                        }}
                        autoComplete="subTitleJP" />
                        {editGetFormErrorMessage('subTitleJP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('subTitleEN')}</label>
                    <InputText
                        inputid="subTitleEN"
                        name="subTitleEN"
                        value={editSubTitleEN}
                        placeholder={t('subTitleEN')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('subTitleEN', e.target.value);
                        }}
                        autoComplete="subTitleEN" />
                        {editGetFormErrorMessage('subTitleEN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('subTitleCH')}</label>
                    <InputText
                        inputid="subTitleCH"
                        name="subTitleCH"
                        value={editSubTitleCH}
                        placeholder={t('subTitleCH')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('subTitleCH', e.target.value);
                        }}
                        autoComplete="subTitleCH" />
                        {editGetFormErrorMessage('subTitleCH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                {contJP()}
                {contEN()}
                {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    return(
        <div className="pb-20">
            <Header />
            <Toast ref={toast} />
            <div className=" mt-20 p-2 sm:p-8">
            <Accordion activeIndex={activeIndex} 
                onTabChange={
                    (e) => {
                        setActiveIndex(e.index);
                        if(e.index != 1){
                            setHiddenEditTab(0);
                        }else{
                            setHiddenEditTab(1);
                        }
                    }
                }
            >
                {/* 新規 */}
                <AccordionTab header={t('newRecruitInfo')}>
                    <div className="w-full ">
                        <form 
                            onSubmit={formik.handleSubmit} 
                            className="w-full !max-w-none"
                        >
                            <div className="flex flex-col contents-center justify-start w-full ">
                                <div className="flex flex-wrap">
                                    <div className="p-2 flex flex-col">
                                        <label>{t('publicDate')}</label>
                                        <Calendar
                                            ariaLabel='publicDate'
                                            inputid="publicDate"
                                            name="publicDate"
                                            aria-expanded="true"
                                            value={publicDate}
                                            placeholder={t('publicDate')}
                                            onChange={(e) => {
                                                formik.setFieldValue('publicDate', e.target.value);
                                            }}
                                            autoComplete="publicDate"
                                            ref={calendarRef}
                                            inputRef={inputRef}
                                            showButtonBar
                                            locale={lang}
                                            pt={{
                                                panel:{
                                                    className:'!w-[400px]'
                                                },
                                                groupContainer:{
                                                    className:'w-[400px] border-2 rounded-md'
                                                },
                                                buttonbar:{
                                                    className:`flex justify-between items-center px-4 py-2 border-2 rounded-md 
                                                                [&_button]:bg-gray-400 [&_button]:border-none [&_button]:h-8
                                                                [&_button:focus]:shadow-none
                                                                [&_button:hover]:bg-gray-500
                                                                `
                                                }
                                            }}
                                            />
                                            {getFormErrorMessage('publicDate')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('visiblity')}</label>
                                        <InputSwitch
                                            inputid="visiblity"
                                            name="visiblity"
                                            checked={visiblity}
                                            onChange={(e) => {
                                                formik.setFieldValue('visiblity', e.value);
                                            }}
                                            />
                                            {getFormErrorMessage('visiblity')}
                                    </div>
                                </div>
                                <div className="flex flex-col items-center contents-center justify-start w-full xl:items-end xl:flex-row w-full">
                                    {titleNewFields()}
                                </div>
                                <div className="flex flex-col items-center contents-center justify-start w-full xl:items-end xl:flex-row w-full">
                                    {subTitleNewFields()}
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('companyNameWork')}</label>
                                        <InputText
                                            inputid="companyNameWork"
                                            name="companyNameWork"
                                            value={companyNameWork}
                                            placeholder={t('companyNameWork')}
                                            onChange={(e) => {
                                                formik.setFieldValue('companyNameWork', e.target.value);
                                            }}
                                            autoComplete="companyNameWork" />
                                            {getFormErrorMessage('companyNameWork')}
                                    </div>
                                </div>
                                <div className="flex flex-row flex-wrap w-full">
                                    <div className="p-2 flex flex-col">
                                        <label>{t('country')}</label>
                                        <InputText
                                            inputid="country"
                                            name="country"
                                            value={country}
                                            placeholder={t('country')}
                                            onChange={(e) => {
                                                formik.setFieldValue('country', e.target.value);
                                            }}
                                            autoComplete="country" />
                                            {getFormErrorMessage('country')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('province')}</label>
                                        <InputText
                                            inputid="province"
                                            name="province"
                                            value={province}
                                            placeholder={t('province')}
                                            onChange={(e) => {
                                                formik.setFieldValue('province', e.target.value);
                                            }}
                                            autoComplete="province" />
                                            {getFormErrorMessage('province')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('city')}</label>
                                        <InputText
                                            inputid="city"
                                            name="city"
                                            value={city}
                                            placeholder={t('city')}
                                            onChange={(e) => {
                                                formik.setFieldValue('city', e.target.value);
                                            }}
                                            autoComplete="city" />
                                            {getFormErrorMessage('city')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('recruitAddress')}</label>
                                        <InputText
                                            inputid="address"
                                            name="address"
                                            value={address}
                                            placeholder={t('recruitAddress')}
                                            onChange={(e) => {
                                                formik.setFieldValue('address', e.target.value);
                                            }}
                                            autoComplete="address" />
                                            {getFormErrorMessage('address')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('contactPerson')}</label>
                                        <InputText
                                            inputid="contactPerson"
                                            name="contactPerson"
                                            value={contactPerson}
                                            placeholder={t('contactPerson')}
                                            onChange={(e) => {
                                                formik.setFieldValue('contactPerson', e.target.value);
                                            }}
                                            autoComplete="contactPerson" />
                                            {getFormErrorMessage('contactPerson')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('contactTel')}</label>
                                        <InputText
                                            inputid="contactTel"
                                            name="contactTel"
                                            value={contactTel}
                                            placeholder={t('contactTel')}
                                            onChange={(e) => {
                                                formik.setFieldValue('contactTel', e.target.value);
                                            }}
                                            autoComplete="contactTel" />
                                            {getFormErrorMessage('contactTel')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('contactEmail')}</label>
                                        <InputText
                                            inputid="contactEmail"
                                            name="contactEmail"
                                            value={contactEmail}
                                            placeholder={t('contactEmail')}
                                            onChange={(e) => {
                                                formik.setFieldValue('contactEmail', e.target.value);
                                            }}
                                            autoComplete="contactEmail" />
                                            {getFormErrorMessage('contactEmail')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('otherContact')}</label>
                                        <InputTextarea
                                            inputid="otherContact"
                                            name="otherContact"
                                            value={otherContact !== null ? otherContact : ''}
                                            placeholder={t('otherContact')}
                                            autoResize
                                            onChange={(e) => {
                                                formik.setFieldValue('otherContact', e.target.value);
                                            }}
                                        />
                                            {getFormErrorMessage('otherContact')}
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('headCompany')}</label>
                                            <InputText
                                                inputid="headCompany"
                                                name="headCompany"
                                                value={headCompany}
                                                placeholder={t('headCompany')}
                                                onChange={(e) => {
                                                    formik.setFieldValue('headCompany', e.target.value);
                                                }}
                                                autoComplete="headCompany" />
                                                {getFormErrorMessage('headCompany')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('webAddress')}</label>
                                            <InputText
                                                inputid="webAddress"
                                                name="webAddress"
                                                value={webAddress}
                                                placeholder={t('webAddress')}
                                                onChange={(e) => {
                                                    formik.setFieldValue('webAddress', e.target.value);
                                                }}
                                                autoComplete="webAddress" />
                                                {getFormErrorMessage('webAddress')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('note')}</label>
                                            <InputTextarea
                                                inputid="note"
                                                name="note"
                                                value={note !== null ? note : ''}
                                                placeholder={t('note')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('note', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('note')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col">
                                            <label>{t('employeesNumber')}</label>
                                            <InputText
                                                inputid="employeesNumber"
                                                name="employeesNumber"
                                                value={employeesNumber}
                                                placeholder={t('employeesNumber')}
                                                onChange={(e) => {
                                                    formik.setFieldValue('employeesNumber', e.target.value);
                                                }}
                                                autoComplete="employeesNumber" />
                                                {getFormErrorMessage('employeesNumber')}
                                        </div>
                                        <div className="p-2 flex flex-col">
                                            <label>{t('recruitJapanes')}</label>
                                            <InputText
                                                inputid="japanes"
                                                name="japanes"
                                                value={japanes}
                                                placeholder={t('recruitJapanes')}
                                                onChange={(e) => {
                                                    formik.setFieldValue('japanes', e.target.value);
                                                }}
                                                autoComplete="japanes" />
                                                {getFormErrorMessage('japanes')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('bussiness')}</label>
                                            <InputTextarea
                                                inputid="bussiness"
                                                name="bussiness"
                                                value={bussiness !== null ? bussiness : ''}
                                                placeholder={t('bussiness')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('bussiness', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('bussiness')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('job')}</label>
                                            <InputTextarea
                                                inputid="job"
                                                name="job"
                                                value={job !== null ? job : ''}
                                                placeholder={t('job')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('job', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('job')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('requirements')}</label>
                                            <InputTextarea
                                                inputid="requirements"
                                                name="requirements"
                                                value={requirements !== null ? requirements : ''}
                                                placeholder={t('requirements')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('requirements', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('requirements')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('recruitLanguage')}</label>
                                            <InputTextarea
                                                inputid="language"
                                                name="language"
                                                value={language !== null ? language : ''}
                                                placeholder={t('recruitLanguage')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('language', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('language')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('otherRequirements')}</label>
                                            <InputTextarea
                                                inputid="otherRequirements"
                                                name="otherRequirements"
                                                value={otherRequirements !== null ? otherRequirements : ''}
                                                placeholder={t('otherRequirements')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('otherRequirements', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('otherRequirements')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('salaly')}</label>
                                            <InputTextarea
                                                inputid="salaly"
                                                name="salaly"
                                                value={salaly !== null ? salaly : ''}
                                                placeholder={t('salaly')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('salaly', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('salaly')}
                                        </div>
                                        {/* <div className="p-2 flex flex-col w-full">
                                            <label>{t('bonus')}</label>
                                            <InputTextarea
                                                inputid="bonus"
                                                name="bonus"
                                                value={bonus !== null ? bonus : ''}
                                                placeholder={t('bonus')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('bonus', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('bonus')}
                                        </div> */}
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('housingBenefit')}</label>
                                            <InputTextarea
                                                inputid="housingBenefit"
                                                name="housingBenefit"
                                                value={housingBenefit !== null ? housingBenefit : ''}
                                                placeholder={t('housingBenefit')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('housingBenefit', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('housingBenefit')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('otherBenefit')}</label>
                                            <InputTextarea
                                                inputid="otherBenefit"
                                                name="otherBenefit"
                                                value={otherBenefit !== null ? otherBenefit : ''}
                                                placeholder={t('otherBenefit')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('otherBenefit', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('otherBenefit')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('workingTime')}</label>
                                            <InputTextarea
                                                inputid="workingTime"
                                                name="workingTime"
                                                value={workingTime !== null ? workingTime : ''}
                                                placeholder={t('workingTime')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('workingTime', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('workingTime')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('holyday')}</label>
                                            <InputTextarea
                                                inputid="holyday"
                                                name="holyday"
                                                value={holyday !== null ? holyday : ''}
                                                placeholder={t('holyday')}
                                                autoResize
                                                onChange={(e) => {
                                                    formik.setFieldValue('holyday', e.target.value);
                                                }}
                                            />
                                                {getFormErrorMessage('holyday')}
                                        </div>
                                    </div>
                                </div>
                                {/* {localStorage.getItem('token')} */}
                                <div className="pb-5 flex flex-row mx-auto">
                                    <Button 
                                        className="m-2 text-nowrap"
                                        label={t('create')} 
                                        type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </AccordionTab>
                {/* 編集 */}
                <AccordionTab header={t('editRecruitInfo')} className={hiddenTabFunction()} >
                <div className="w-full ">
                        <form 
                            onSubmit={formikEdit.handleSubmit} 
                            className="w-full !max-w-none"
                        >
                            <div className="flex flex-col contents-center justify-start w-full ">
                                <div className="flex flex-wrap">
                                    <div className="p-2 flex flex-col">
                                        <label>{t('recruitId')}</label>
                                        <InputText
                                            inputid="recruitId"
                                            name={recruitId}
                                            value={formikEdit.values.recruitId}
                                            placeholder={t('recruitId')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('recruitId', e.target.value);
                                            }}
                                            autoComplete="recruitId" />
                                            {editGetFormErrorMessage('recruitId')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('publicDate')}</label>
                                        <Calendar
                                            ariaLabel='publicDate'
                                            inputid="publicDate"
                                            name="publicDate"
                                            aria-expanded="true"
                                            value={editPublicDate}
                                            placeholder={t('publicDate')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('publicDate', e.target.value);
                                            }}
                                            autoComplete="publicDate"
                                            ref={calendarRef}
                                            inputRef={inputRef}
                                            showButtonBar
                                            locale={lang}
                                            pt={{
                                                panel:{
                                                    className:'!w-[400px]'
                                                },
                                                groupContainer:{
                                                    className:'w-[400px] border-2 rounded-md'
                                                },
                                                buttonbar:{
                                                    className:`flex justify-between items-center px-4 py-2 border-2 rounded-md 
                                                                [&_button]:bg-gray-400 [&_button]:border-none [&_button]:h-8
                                                                [&_button:focus]:shadow-none
                                                                [&_button:hover]:bg-gray-500
                                                                `
                                                }
                                            }}
                                            />
                                            {editGetFormErrorMessage('publicDate')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('visiblity')}</label>
                                        <InputSwitch
                                            inputid="visiblity"
                                            name="visiblity"
                                            checked={editVisiblity}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('visiblity', e.value);
                                            }}
                                            />
                                            {editGetFormErrorMessage('visiblity')}
                                    </div>
                                </div>
                                <div className="flex flex-col items-center contents-center justify-start w-full xl:items-end xl:flex-row w-full">
                                    {titleEditFields()}
                                </div>
                                <div className="flex flex-col items-center contents-center justify-start w-full xl:items-end xl:flex-row w-full">
                                    {subTitleEditFields()}
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('companyNameWork')}</label>
                                        <InputText
                                            inputid="companyNameWork"
                                            name="companyNameWork"
                                            value={editCompanyNameWork}
                                            placeholder={t('companyNameWork')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('companyNameWork', e.target.value);
                                            }}
                                            autoComplete="companyNameWork" />
                                            {editGetFormErrorMessage('companyNameWork')}
                                    </div>
                                </div>
                                <div className="flex flex-row flex-wrap w-full">
                                    <div className="p-2 flex flex-col">
                                        <label>{t('country')}</label>
                                        <InputText
                                            inputid="country"
                                            name="country"
                                            value={editCountry}
                                            placeholder={t('country')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('country', e.target.value);
                                            }}
                                            autoComplete="country" />
                                            {editGetFormErrorMessage('country')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('province')}</label>
                                        <InputText
                                            inputid="province"
                                            name="province"
                                            value={editProvince}
                                            placeholder={t('province')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('province', e.target.value);
                                            }}
                                            autoComplete="province" />
                                            {editGetFormErrorMessage('province')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('city')}</label>
                                        <InputText
                                            inputid="city"
                                            name="city"
                                            value={editCity}
                                            placeholder={t('city')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('city', e.target.value);
                                            }}
                                            autoComplete="city" />
                                            {editGetFormErrorMessage('city')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('recruitAddress')}</label>
                                        <InputText
                                            inputid="address"
                                            name="address"
                                            value={editAddress}
                                            placeholder={t('recruitAddress')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('address', e.target.value);
                                            }}
                                            autoComplete="address" />
                                            {editGetFormErrorMessage('address')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('contactPerson')}</label>
                                        <InputText
                                            inputid="contactPerson"
                                            name="contactPerson"
                                            value={editContactPerson}
                                            placeholder={t('contactPerson')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('contactPerson', e.target.value);
                                            }}
                                            autoComplete="contactPerson" />
                                            {editGetFormErrorMessage('contactPerson')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('contactTel')}</label>
                                        <InputText
                                            inputid="contactTel"
                                            name="contactTel"
                                            value={editContactTel}
                                            placeholder={t('contactTel')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('contactTel', e.target.value);
                                            }}
                                            autoComplete="contactTel" />
                                            {editGetFormErrorMessage('contactTel')}
                                    </div>
                                    <div className="p-2 flex flex-col">
                                        <label>{t('contactEmail')}</label>
                                        <InputText
                                            inputid="contactEmail"
                                            name="contactEmail"
                                            value={editContactEmail}
                                            placeholder={t('contactEmail')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('contactEmail', e.target.value);
                                            }}
                                            autoComplete="contactEmail" />
                                            {editGetFormErrorMessage('contactEmail')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('otherContact')}</label>
                                        <InputTextarea
                                            inputid="otherContact"
                                            name="otherContact"
                                            value={editOtherContact !== null ? editOtherContact : ''}
                                            placeholder={t('otherContact')}
                                            autoResize
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('otherContact', e.target.value);
                                            }}
                                        />
                                            {editGetFormErrorMessage('otherContact')}
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('headCompany')}</label>
                                            <InputText
                                                inputid="headCompany"
                                                name="headCompany"
                                                value={editHeadCompany}
                                                placeholder={t('headCompany')}
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('headCompany', e.target.value);
                                                }}
                                                autoComplete="headCompany" />
                                                {editGetFormErrorMessage('headCompany')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('webAddress')}</label>
                                            <InputText
                                                inputid="webAddress"
                                                name="webAddress"
                                                value={editWebAddress}
                                                placeholder={t('webAddress')}
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('webAddress', e.target.value);
                                                }}
                                                autoComplete="webAddress" />
                                                {editGetFormErrorMessage('webAddress')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('note')}</label>
                                            <InputTextarea
                                                inputid="note"
                                                name="note"
                                                value={editNote !== null ? editNote : ''}
                                                placeholder={t('note')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('note', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('note')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col">
                                            <label>{t('employeesNumber')}</label>
                                            <InputText
                                                inputid="employeesNumber"
                                                name="employeesNumber"
                                                value={editEmployeesNumber !== null ? editEmployeesNumber : ''}
                                                placeholder={t('employeesNumber')}
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('employeesNumber', e.target.value);
                                                }}
                                                autoComplete="employeesNumber" />
                                                {editGetFormErrorMessage('employeesNumber')}
                                        </div>
                                        <div className="p-2 flex flex-col">
                                            <label>{t('recruitJapanes')}</label>
                                            <InputText
                                                inputid="japanes"
                                                name="japanes"
                                                value={editJapanes !== null ? editJapanes : ''}
                                                placeholder={t('recruitJapanes')}
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('japanes', e.target.value);
                                                }}
                                                autoComplete="japanes" />
                                                {editGetFormErrorMessage('japanes')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('bussiness')}</label>
                                            <InputTextarea
                                                inputid="bussiness"
                                                name="bussiness"
                                                value={editBussiness !== null ? editBussiness : ''}
                                                placeholder={t('bussiness')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('bussiness', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('bussiness')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('job')}</label>
                                            <InputTextarea
                                                inputid="job"
                                                name="job"
                                                value={editJob !== null ? editJob : ''}
                                                placeholder={t('job')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('job', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('job')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('requirements')}</label>
                                            <InputTextarea
                                                inputid="requirements"
                                                name="requirements"
                                                value={editRequirements !== null ? editRequirements : ''}
                                                placeholder={t('requirements')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('requirements', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('requirements')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('recruitLanguage')}</label>
                                            <InputTextarea
                                                inputid="language"
                                                name="language"
                                                value={editLanguage !== null ? editLanguage : ''}
                                                placeholder={t('recruitLanguage')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('language', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('language')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('otherRequirements')}</label>
                                            <InputTextarea
                                                inputid="otherRequirements"
                                                name="otherRequirements"
                                                value={editOtherRequirements !== null ? editOtherRequirements : ''}
                                                placeholder={t('otherRequirements')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('otherRequirements', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('otherRequirements')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('salaly')}</label>
                                            <InputTextarea
                                                inputid="salaly"
                                                name="salaly"
                                                value={editSalaly !== null ? editSalaly : ''}
                                                placeholder={t('salaly')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('salaly', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('salaly')}
                                        </div>
                                        {/* <div className="p-2 flex flex-col w-full">
                                            <label>{t('bonus')}</label>
                                            <InputTextarea
                                                inputid="bonus"
                                                name="bonus"
                                                value={editBonus !== null ? editBonus : ''}
                                                placeholder={t('bonus')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('bonus', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('bonus')}
                                        </div> */}
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('housingBenefit')}</label>
                                            <InputTextarea
                                                inputid="housingBenefit"
                                                name="housingBenefit"
                                                value={editHousingBenefit !== null ? editHousingBenefit : ''}
                                                placeholder={t('housingBenefit')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('housingBenefit', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('housingBenefit')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('otherBenefit')}</label>
                                            <InputTextarea
                                                inputid="otherBenefit"
                                                name="otherBenefit"
                                                value={editOtherBenefit !== null ? editOtherBenefit : ''}
                                                placeholder={t('otherBenefit')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('otherBenefit', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('otherBenefit')}
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('workingTime')}</label>
                                            <InputTextarea
                                                inputid="workingTime"
                                                name="workingTime"
                                                value={editWorkingTime !== null ? editWorkingTime : ''}
                                                placeholder={t('workingTime')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('workingTime', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('workingTime')}
                                        </div>
                                        <div className="p-2 flex flex-col w-full">
                                            <label>{t('holyday')}</label>
                                            <InputTextarea
                                                inputid="holyday"
                                                name="holyday"
                                                value={editHolyday !== null ? editHolyday : ''}
                                                placeholder={t('holyday')}
                                                autoResize
                                                onChange={(e) => {
                                                    formikEdit.setFieldValue('holyday', e.target.value);
                                                }}
                                            />
                                                {editGetFormErrorMessage('holyday')}
                                        </div>
                                    </div>
                                </div>
                                {/* {localStorage.getItem('token')} */}
                                <div className="pb-5 flex flex-row mx-auto">
                                    <Button 
                                        className="m-2 text-nowrap"
                                        label={t('change')} 
                                        type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                   
                </AccordionTab>
                {/* 編集 */}
                <AccordionTab header={t('notesForFndividualFiles')} >
                    <div className="w-full ">
                        <form 
                            onSubmit={formikEditExcel.handleSubmit} 
                            className="w-full !max-w-none"
                        >
                            {t('excelComment')}
                            <InputTextarea
                                inputid="spPrecautions"
                                name="spPrecautions"
                                value={spPrecautions !== null ? spPrecautions : ''}
                                placeholder={t('spPrecautions')}
                                autoResize
                                onChange={(e) => {
                                    formikEditExcel.setFieldValue('spPrecautions', e.target.value);
                                }}
                                pt={{
                                    root:{
                                        className: 'w-full'
                                    }
                                }}
                            />

                            <div className="pb-5 flex flex-row mx-auto justify-center">
                                <Button 
                                    className="m-2 text-nowrap"
                                    label={t('change')} 
                                    type="submit" />
                            </div>
                        </form>
                    </div>
                   
                </AccordionTab>
                <AccordionTab header={t('editRecruitList')} >
                    <ConfirmDialog />
                        <DataTable 
                            value={recruitsData} 
                            selectionMode 
                            selection={selectRecruitsData} 
                            filters={filters} 
                            globalFilterFields={['recruitId', 'publicDate', 'titleJP', 'titleEN', 'titleCH', 'subTitleJP', 'subTitleEN', 'subTitleCH', 'companyNameWork', 
                                'country', 'province', 'city', 'address', 'contactPerson', 'contactTel', 'contactEmail', 'otherContact', 'headCompany', 
                                'webAddress', 'note', 'bussiness', 'job', 'requirements', 'language', 'otherRequirements', 'salaly', 'bonus', 'housingBenefit', 
                                'otherBenefit', 'workingTime', 'holyday' ]}
                            header={header} 
                            paginator
                            rows={5} 
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            dataKey="id" 
                            tableStyle={{ minWidth: '50rem' }}
                            className="border"
                            stripedRows
                            pt={{
                                headerRow:{
                                    className:'!text-center '
                                }
                            }}
                            onRowClick={
                                (e) => {
                                    // console.log('eData:'+JSON.stringify(e.data));
                                    if(e.data){
                                        formikEdit.setFieldValue('id', e.data.id);
                                        setEditRecruitId(e.data.recruitId);
                                        formikEdit.setFieldValue('recruitId', e.data.recruitId);
                                        setEditPublicDate(formatDateForPublic(e.data.publicDate,'calModule'));
                                        formikEdit.setFieldValue('publicDate', e.data.publicDate);
                                        setEditTitleJP(e.data.titleJP);
                                        formikEdit.setFieldValue('titleJP', e.data.titleJP);
                                        setEditTitleEN(e.data.titleEN);
                                        formikEdit.setFieldValue('titleEN', e.data.titleEN);
                                        setEditTitleCH(e.data.titleCH);
                                        formikEdit.setFieldValue('titleCH', e.data.titleCH);
                                        setEditSubTitleJP(e.data.subTitleJP);
                                        formikEdit.setFieldValue('subTitleJP', e.data.subTitleJP);
                                        setEditSubTitleEN(e.data.subTitleEN);
                                        formikEdit.setFieldValue('subTitleEN', e.data.subTitleEN);
                                        setEditSubTitleCH(e.data.subTitleCH);
                                        formikEdit.setFieldValue('subTitleCH', e.data.subTitleCH);
                                        setEditCompanyNameWork(e.data.companyNameWork);
                                        formikEdit.setFieldValue('companyNameWork', e.data.companyNameWork);
                                        setEditCountry(e.data.country);
                                        formikEdit.setFieldValue('country', e.data.country);
                                        setEditProvince(e.data.province);
                                        formikEdit.setFieldValue('province', e.data.province);
                                        setEditCity(e.data.city);
                                        formikEdit.setFieldValue('city', e.data.city);
                                        setEditAddress(e.data.address);
                                        formikEdit.setFieldValue('address', e.data.address);
                                        setEditContactPerson(e.data.contactPerson);
                                        formikEdit.setFieldValue('contactPerson', e.data.contactPerson);
                                        setEditContactTel(e.data.contactTel);
                                        formikEdit.setFieldValue('contactTel', e.data.contactTel);
                                        setEditContactEmail(e.data.contactEmail);
                                        formikEdit.setFieldValue('contactEmail', e.data.contactEmail);
                                        setEditOtherContact(e.data.otherContact);
                                        formikEdit.setFieldValue('otherContact', e.data.otherContact);
                                        setEditHeadCompany(e.data.headCompany);
                                        formikEdit.setFieldValue('headCompany', e.data.headCompany);
                                        setEditWebAddress(e.data.webAddress);
                                        formikEdit.setFieldValue('webAddress', e.data.webAddress);
                                        setEditNote(e.data.note);
                                        formikEdit.setFieldValue('note', e.data.note);
                                        setEditEmployeesNumber(e.data.employeesNumber);
                                        formikEdit.setFieldValue('employeesNumber', e.data.employeesNumber);
                                        setEditJapanes(e.data.japanes);
                                        formikEdit.setFieldValue('japanes', e.data.japanes);
                                        setEditBussiness(e.data.bussiness);
                                        formikEdit.setFieldValue('bussiness', e.data.bussiness);
                                        setEditJob(e.data.job);
                                        formikEdit.setFieldValue('job', e.data.job);
                                        setEditRequirements(e.data.requirements);
                                        formikEdit.setFieldValue('requirements', e.data.requirements);
                                        setEditLanguage(e.data.language);
                                        formikEdit.setFieldValue('language', e.data.language);
                                        setEditOtherRequirements(e.data.otherRequirements);
                                        formikEdit.setFieldValue('otherRequirements', e.data.otherRequirements);
                                        setEditSalaly(e.data.salaly);
                                        formikEdit.setFieldValue('salaly', e.data.salaly);
                                        setEditBonus(e.data.bonus);
                                        formikEdit.setFieldValue('bonus', e.data.bonus);
                                        setEditHousingBenefit(e.data.housingBenefit);
                                        formikEdit.setFieldValue('housingBenefit', e.data.housingBenefit);
                                        setEditOtherBenefit(e.data.otherBenefit);
                                        formikEdit.setFieldValue('otherBenefit', e.data.otherBenefit);
                                        setEditWorkingTime(e.data.workingTime);
                                        formikEdit.setFieldValue('workingTime', e.data.workingTime);
                                        setEditHolyday(e.data.holyday);
                                        formikEdit.setFieldValue('holyday', e.data.holyday);
                                        setEditVisiblity(e.data.visiblity);
                                        formikEdit.setFieldValue('visiblity', e.data.visiblity);
                                        setSelectRecruitsData(e.data);
                                        setHiddenEditTab(1);
                                        setActiveIndex(1);
                                    }
                                }
                            } 
                        >
                            <Column header={headIdTemplate} body={idTemplate}
                                pt={{
                                    headerContent:{className:'!justify-center text-xs w-20'},
                                    bodyCell:{className:'!justify-center text-xs 2xl:text-md w-20'},
                                    headerCell:{className:''}
                                }}
                                className=''
                            />
                            {/* 2xl Size */}
                            <Column header={headCompanyTemplate} body={companyTemplate}
                                pt={{headerContent:{className:'!justify-center text-xs text-center min-w-80'},
                                    bodyCell:{className:'!justify-center text-md text-center min-w-80'},
                                    headerCell:{className:'hidden 2xl:table-cell'}
                                }}
                                className='hidden 2xl:table-cell'
                            />
                            <Column header={headContactTemplate} body={contactTemplate}
                                pt={{headerContent:{className:'!justify-center text-xs text-center min-w-44'},
                                    bodyCell:{className:'!justify-center text-md text-center min-w-44'},
                                    headerCell:{className:'hidden 2xl:table-cell'}
                                }}
                                    filterPlaceholder={t('name')} 
                                className='hidden 2xl:table-cell'
                            />
                            <Column header={headTitletemplate} body={titleTemplate}
                                pt={{
                                    headerContent:{className:'!justify-center text-xs text-center min-w-44'},
                                        bodyCell:{className:'!justify-center text-md text-center min-w-44'},
                                        headerCell:{className:'hidden 2xl:table-cell'}
                                }}
                                className='hidden 2xl:table-cell'
                            />
                            <Column header={headNoteTemplate} body={noteTemplate}
                                pt={{headerContent:{className:'!justify-center text-xs text-center min-w-[140px] min-w-44'},
                                    bodyCell:{className:'!justify-center text-md text-center min-w-[140px] min-w-44'},
                                    headerCell:{className:'hidden 2xl:table-cell'}
                                }}
                                className='hidden 2xl:table-cell'
                            />
                            <Column header={headSalalyTemplate} body={salalyTemplate}
                                pt={{headerContent:{className:'!justify-center text-xs text-center min-w-[140px] min-w-44'},
                                    bodyCell:{className:'!justify-center text-md text-center min-w-[140px] min-w-44'},
                                    headerCell:{className:'hidden 2xl:table-cell'}
                                }}
                                className='hidden 2xl:table-cell'
                            />
                            {/* lg-xl Size */}
                            <Column header={headCompanyTemplateLg} body={companyTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-xs text-center min-w-40'},
                                    bodyCell:{className:'!justify-center text-xs text-center min-w-40'},
                                    headerCell:{className:'hidden lg:table-cell 2xl:hidden'}
                                }}
                                className='hidden lg:table-cell 2xl:hidden'
                            />
                            <Column header={headTitleTemplateLg} body={titleTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-xs text-center min-w-40'},
                                    bodyCell:{className:'!justify-center text-xs text-center min-w-40'},
                                    headerCell:{className:'hidden lg:table-cell 2xl:hidden'}
                                }}
                                className='hidden lg:table-cell 2xl:hidden'
                            />
                            <Column header={headNoteTemplateLg} body={noteTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-xs text-center min-w-40'},
                                    bodyCell:{className:'!justify-center text-xs text-center min-w-40'},
                                    headerCell:{className:'hidden lg:table-cell 2xl:hidden'}
                                }}
                                className='hidden lg:table-cell 2xl:hidden'
                            />
                            <Column header={headSalalyTemplateLg} body={salalyTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-xs text-center min-w-40'},
                                    bodyCell:{className:'!justify-center text-xs text-center min-w-40'},
                                    headerCell:{className:'hidden lg:table-cell 2xl:hidden'}
                                }}
                                className='hidden lg:table-cell 2xl:hidden'
                            />
                            {/* sm-md Size */}
                            <Column header={headCompanyTemplateLg} body={companyTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-[0.3em] text-center min-w-32'},
                                    bodyCell:{className:'!justify-center text-[0.3em] text-center min-w-32'},
                                    headerCell:{className:'hidden sm:table-cell lg:hidden'}
                                }}
                                className='hidden sm:table-cell lg:hidden'
                            />
                            <Column header={headNoteTemplateLg} body={noteTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-[0.3em] text-center min-w-32'},
                                    bodyCell:{className:'!justify-center text-[0.3em] text-center min-w-32'},
                                    headerCell:{className:'hidden sm:table-cell lg:hidden'}
                                }}
                                className='hidden sm:table-cell lg:hidden'
                            />
                            <Column header={headSalalyTemplateLg} body={salalyTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-[0.3em] text-center min-w-32'},
                                    bodyCell:{className:'!justify-center text-[0.3em] text-center min-w-32'},
                                    headerCell:{className:'hidden sm:table-cell lg:hidden'}
                                }}
                                className='hidden sm:table-cell lg:hidden'
                            />
                            {/* < sm Size */}
                            <Column header={headCompanyTemplateLg} body={companyTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-[0.3em] text-center min-w-24 p-1 m-1'},
                                    bodyCell:{className:'!justify-center text-[0.3em] text-center min-w-24 p-1 m-1'},
                                    headerCell:{className:'table-cell sm:hidden'}
                                }}
                                className='table-cell sm:hidden'
                            />
                            <Column header={headNoteTemplateLg} body={noteTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-[0.3em] text-center min-w-24 p-1'},
                                    bodyCell:{className:'!justify-center text-[0.3em] text-center  min-w-24 p-1'},
                                    headerCell:{className:'table-cell sm:hidden'}
                                }}
                                className='table-cell sm:hidden'
                            />
                            <Column header={headSalalyTemplateLg} body={salalyTemplateLg}
                                pt={{headerContent:{className:'!justify-center text-[0.3em] text-center min-w-24 p-1'},
                                    bodyCell:{className:'!justify-center text-[0.3em] text-center min-w-24 p-1'},
                                    headerCell:{className:'table-cell sm:hidden'}
                                }}
                                className='table-cell sm:hidden'
                            />
                            <Column selectionMode="single" 
                                pt={{
                                    radioButton:{
                                        className:"invisible"
                                    },
                                    headerCell:{className:'hidden'}
                                }}
                                className='hidden'
                            />
                    </DataTable>
                </AccordionTab>
            </Accordion>
            </div>
            <Footer />
        </div>
    )


}

export default EditRecruit