import React, { useEffect, useState } from 'react';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';
import UnderBanner from 'components/contentsParts/UnderBanner';
import DynamicJavaScript from 'components/contentsParts/DynamicJavaScript';
import { ContentData, DefSystem } from 'components/contentsParts/DbData';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';

const Template = (path,addContent) => {
    const navigate = useNavigate();
    const [ contentData, setContentData ] = useState([]);
    const [cssContent, setCssContent] = useState('');
    const [jsContent, setJsContent] = useState('');
    const [schemaMarkup, setSchemaMarkup] = useState(null);
    const [pageTitle, setPageTitle] = useState('Wah fook managemnt .,Ltd');
    const [description, setDescription] = useState('');
    const { t, i18n } = useTranslation();
    // const [ publicLanguageSelect, setPublicLanguageSelect ] = useState(0);
    // const [ memberLanguageSelect, setMemberLanguageSelect ] = useState(0);
    // const [ nickname, setNickname ] = useState('');
    // const [ language, setLanguage ] = useState('')
    // const [ menuData, setMenuData] = useState([]);
    const location = useLocation();

    useEffect(() => {
        // const token = sessionStorage.getItem('token');

        let defLang = '';
        // let pubLang = '';
        // let memLang = '';
        // if(token){
        //     setNickname(sessionStorage.getItem('nickname'));
        // }
        const getValuefunction = async(name,val) =>{
            const lStrage = localStorage.getItem(name);
            const data = JSON.parse(lStrage);
            const currentTime = new Date().getTime();
            if(!lStrage || data.expires < currentTime){
                localStorage.removeItem(name);
                const resData =  await DefSystem(name);
                if(name === 'defaultLanguage')defLang = resData.value;
            }else{
                if(name === 'defaultLanguage')defLang = data.value;
            }
        }
        getValuefunction('defaultLanguage');
        getValuefunction('publicLanguageSelect');
        getValuefunction('memberLanguageSelect');

        const langLoadFunction =() => {
            const val = localStorage.getItem('Lang');
            if(val){
                const data = JSON.parse(val);
                // setLangStrage(data.value);
                // setLanguage(data.value);
                i18n.changeLanguage(data.value);
            }else{
                // setLanguage(defLang);
                const expires = Date.now() + (30 * 24 * 60 * 60 * 1000); // 30日後のミリ秒
                if(defLang){
                    const arrValue = {"value":defLang, expires}
                    localStorage.setItem('Lang', JSON.stringify(arrValue) );
                }
                i18n.changeLanguage(defLang);
                setTimeout(() => {
                    langLoadFunction();
                },1000);
            }
        }
        langLoadFunction();
        const fetchData = async () => {
            const val = localStorage.getItem('Lang');
            if(val && path){
                const jsData = JSON.parse(val);
                const data = await ContentData(path,jsData.value);
                // console.log('data1:'+JSON.stringify(data))
                const lsData = sessionStorage.getItem('CompanyPassword');
                let companyPass = false;
                if(lsData){
                    companyPass = JSON.parse(lsData).value;
                    // console.log(companyPass)
                }
                if(data[0].password !== 1 || companyPass === 'true'){
                    if(data)setContentData(data[0]);
                    if(data[0].css)setCssContent(data[0].css);
                    if(data[0].js)setJsContent(data[0].js);
                    if(data[0].json)setSchemaMarkup(data[0].json);
                    if(data[0].description)setDescription(data[0].description);
                    const prefix = `${process.env.REACT_APP_APPTITLE}`;
                    const newTitle = `${prefix} -= ${data[0].title} =-`;
                    setPageTitle(newTitle);
                    
                }else{
                    const addressPath = location.pathname.split('/')[1];
                    const naviPath = '/companylogin?page='+addressPath;
                    navigate(naviPath);
                    // const path = location.pathname.split('/')[1];
                    // console.log(path)
                }
            }else{
                setTimeout(() => {
                    fetchData();
                },1000);
            }
        }

        fetchData();
    },[path,i18n,location.pathname,navigate])
    const heroImagefunction = () => {
        let imgPath = '';
        if(contentData.thumbnail){
            if(contentData.thumbnail.startsWith('http')){
                imgPath = contentData.thumbnail;
            }else{
                imgPath = process.env.REACT_APP_API_IMAGEPATH+contentData.thumbnail;
            }
            return (
                <div className='mt-[70px] max-w-screen-xl mx-auto max-h-[300px] overflow-hidden flex items-center justify-center rounded-b-lg '>
                    <img src={imgPath} className='w-full' alt='hero image'/>
                </div>
            )

        }else{
            return (
                <div className='mt-[70px]'></div>
            )
        }
    }
    const titleCreateFunction = (design) => {
        if(path !== 'index'){
            if(design === 1){
                return(
                    <h1 className='flex flex-row items-center mx-4 my-4 pl-4 py-2 
                        rounded-md border-l-2 border-b-2 
                        border-double
                        border-blue-900 text-blue-900 bg-blue-100'
                    >
                        <div className='size-3.5 bg-blue-800 rounded mr-2'></div> {contentData.title}
                    </h1>
                )
            }
            if(design === 2){
                return(
                    <h1 className='flex flex-row items-center mx-4 my-4 pl-4 py-2 
                        border-b-2 
                        border-double
                        border-blue-900 text-blue-900'
                    >
                        {contentData.title}
                    </h1>
                )
            }
            if(design === 3){
                return(
                    <h1 className='flex flex-row items-center mx-4 my-4 pl-4 py-2 
                        rounded-md border-l-2 border-b-2 
                        border-double
                        border-blue-900 text-blue-900 bg-blue-100'
                    >
                        <div className='size-3.5 bg-blue-800 rounded mr-2'></div> {contentData.title}
                    </h1>
                )
            }
        }
    }
    const breadListFunction = () => {
        if(path !== 'index'){
            if(contentData.breadcrumb){
                return (
                    <div id='breadlist' className="pt-2 px-4 w-100 max-w-screen-xl mx-auto text-sm text-gray-500">
                        <a href="/" className=''>{t('home')}</a> >> <span className='font-bold'>{contentData.breadcrumb}</span>
                    </div>
                )
            }else{
                return (
                    <div id='breadlist' className="pt-2 px-4 w-100 max-w-screen-xl mx-auto text-sm text-gray-500">
                        <a href="/" className=''>{t('home')}</a> >> <span className='font-bold'>{contentData.title}</span>
                    </div>
                )
            }
        }
    }
    if(contentData.template === 1){
        return(
            <div className='pb-0 md:pb-[65px] contentsBody prose'>
                <Header />
                {heroImagefunction()}
                {breadListFunction()}
                <div className="!w-full max-w-screen-xl mx-auto">
                        <div className='flex flex-col md:flex-row justify-between !w-full'>
                            <div className='flex flex-col w-auto mx-0 !w-full'>
                                {titleCreateFunction(1)}
                                {(contentData.content1) && (
                                    <div className="px-5 w-full">
                                            <div dangerouslySetInnerHTML={{ __html: contentData.content1 }}></div>
                                    </div>
                                )}

                                {(addContent) && (
                                        <div>{addContent}</div>
                                )}

                                {(contentData.content2) && (
                                    <div className="px-5 w-full">
                                            <div dangerouslySetInnerHTML={{ __html: contentData.content2 }}></div>
                                    </div>
                                )}
                            </div>
                            {(contentData.content3) && (
                                <div className="px-5 min-w-64">
                                        <div dangerouslySetInnerHTML={{ __html: contentData.content3 }}></div>
                                </div>
                            )}
                        </div>
                </div>
                <UnderBanner />
                <Footer />
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content={description} />
                    {schemaMarkup && (
                    <script type="application/ld+json">
                        {schemaMarkup}
                    </script>
                    )}
                </Helmet>
                <div dangerouslySetInnerHTML={{ __html: `<style>${cssContent}</style>` }} />
                <DynamicJavaScript scriptContent={jsContent} />
            </div>
        )
    }
    if(contentData.template === 2){
        return(
            <div className='pb-0 md:pb-[65px] contentsBody prose'>
                <Header />
                {heroImagefunction()}
                {breadListFunction()}
                <div className='w-100 max-w-screen-xl mx-auto !w-full'>
                        <div className='flex flex-col md:flex-row justify-between !w-full'>
                            <div className='flex flex-col w-auto mx-0 !w-full'>
                                {titleCreateFunction(2)}
                                {(contentData.content1) && (
                                    <div className="px-5 w-full">
                                            <div dangerouslySetInnerHTML={{ __html: contentData.content1 }}></div>
                                    </div>
                                )}
                                {(addContent) && (
                                        <div>{addContent}</div>
                                )}
                                        {(contentData.content2) && (
                                    <div className="px-5 w-full">
                                            <div dangerouslySetInnerHTML={{ __html: contentData.content2 }}></div>
                                    </div>
                            )}
                            </div>
                            {(contentData.content3) && (
                                <div className="px-5 min-w-64">
                                        <div dangerouslySetInnerHTML={{ __html: contentData.content3 }}></div>
                                </div>
                            )}
                        </div>
                </div>
                <Footer />
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content={description} />
                    {schemaMarkup && (
                    <script type="application/ld+json">
                        {schemaMarkup}
                    </script>
                    )}
                </Helmet>
                <div dangerouslySetInnerHTML={{ __html: `<style>${cssContent}</style>` }} />
                <DynamicJavaScript scriptContent={jsContent} />
                <style type="text/css">
                        {`
                            h3 {
                                border-left: 4px solid;
                                border-color: #0d3304;
                                color:#0d3304;
                                padding-left: 5px;
                            }
                        `}
                </style>
            </div>
        )
    }
    if(contentData.template === 3){
        return(
            <div className='pb-0 md:pb-[65px] contentsBody prose'>
                <Header />
                {heroImagefunction()}
                {breadListFunction()}
                <div className="!w-full max-w-screen-xl mx-auto">
                        <div className='flex flex-col md:flex-row justify-between !w-full'>
                            <div className='flex flex-col w-auto mx-0 !w-full'>
                                {titleCreateFunction(3)}
                                {(contentData.content1) && (
                                    <div className="px-5 w-full">
                                            <div dangerouslySetInnerHTML={{ __html: contentData.content1 }}></div>
                                    </div>
                                )}
                                {(addContent) && (
                                        <div>{addContent}</div>
                                )}
                                {(contentData.content2) && (
                                    <div className="px-5 w-full">
                                            <div dangerouslySetInnerHTML={{ __html: contentData.content2 }}></div>
                                    </div>
                                )}
                            </div>
                            {(contentData.content3) && (
                                <div className="px-5 min-w-64">
                                        <div dangerouslySetInnerHTML={{ __html: contentData.content3 }}></div>
                                </div>
                            )}
                        </div>
                </div>
                {/* <UnderBanner /> */}
                <Footer />
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content={description} />
                    {schemaMarkup && (
                    <script type="application/ld+json">
                        {schemaMarkup}
                    </script>
                    )}
                </Helmet>
                <div dangerouslySetInnerHTML={{ __html: `<style>${cssContent}</style>` }} />
                <DynamicJavaScript scriptContent={jsContent} />
            </div>
        )
    }

}

export default Template