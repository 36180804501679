import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';
import { LevelData } from '../../components/contentsParts/DbData';

const EditMenu = () =>{
    const [ publicLanguageSelect, setPublicLanguageSelect ] = useState();
    const [ defaultLanguage, setDefaultLanguage ] = useState();
    const [activeIndex, setActiveIndex] = useState(2);
    const [ hiddenEditTab, setHiddenEditTab] = useState(0);
    const { t } = useTranslation();
    const [ levelData, setLevelData] = useState([]);

    const [ menusData, setMenusData ] = useState([]);
    const [ selectMenusData, setSelectMenusData ] = useState(null);
    const [ selectedLevel, setSelectedLevel] = useState('');
    const [ editSelectedLevel, setEditSelectedLevel] = useState('');


    const [ labelJP, setLabelJP] = useState('');
    const [ labelEN, setLabelEN] = useState('');
    const [ labelCH, setLabelCH] = useState('');
    const [ icon, setIcon] = useState('');
    const [ url, setUrl] = useState('');
    const [ sort, setSort] = useState(0);

    const [ editLabelJP, setEditLabelJP] = useState('');
    const [ editLabelEN, setEditLabelEN] = useState('');
    const [ editLabelCH, setEditLabelCH] = useState('');
    const [ editIcon, setEditIcon] = useState('');
    const [ editUrl, setEditUrl] = useState('');
    const [ editSort, setEditSort] = useState(0);

    let selectDel = '';

    const sendRequest = async (url,data) => {
        const params = new URLSearchParams(data).toString();
        const response = await fetch( `${url}?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }else if(response.status === 200) {
            const responceData = await response.json();
            return responceData;
        }else{
            return "[]";
        }
    }


    useEffect(() =>{
        const token = sessionStorage.getItem('token');
        const data = { token }
        const lStrageData = localStorage.getItem('publicLanguageSelect');
        if(lStrageData){
            const data = JSON.parse(lStrageData);
            setPublicLanguageSelect(data.value);
        }
        const lStrageData2 = localStorage.getItem('defaultLanguage');
        if(lStrageData2){
            const data = JSON.parse(lStrageData2);
            setDefaultLanguage(data.value);
        }
        let language = '';
        const lStrageData3 = localStorage.getItem('Lang');
        if(lStrageData3){
            const data = JSON.parse(lStrageData3);
            language = data.value;
        }

        const getLevelFunction = async () => {
            const getData = await LevelData(language);
            if(getData){
                setLevelData(getData);
                try {
                    setLevelData(JSON.parse(getData));
                } catch (e) {
                }
            }
        }
        if (language) {
            getLevelFunction();
        }
        const requestDB = async() => {
            try {
                const resData = await sendRequest(`${process.env.REACT_APP_API_MENU}`,data);
                setMenusData(resData.system);
                // return resData;
            } catch (error) {
                console.log(error)
                console.error("Failed to send the message: ", error);
            }
        }
        requestDB();
    },[]);

    const removeMenuFunction = () => {
        localStorage.removeItem('Menu');
        localStorage.removeItem('ControlMenu');
        localStorage.removeItem('AdminMenu');
    }
    const sendUserDB = async(data, action) => {
        const token = sessionStorage.getItem('token');
        const dataWithToken = { ...data, token };
        // const data = { email, password, name, nickname, level };
        // console.log(data);
        if(action === 'new'){
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'POST', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        removeMenuFunction();
                        window.location.reload();
                    // },1000)
                }else{
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_MENU}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'edit'){
            // console.log('Edit')
            // console.log("data:"+JSON.stringify(data))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'PUT', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        removeMenuFunction();
                        window.location.reload();
                    // },1000)
                }else{
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_MENU}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'delete'){
            // console.log('delete')
            // console.log("data:"+JSON.stringify(data))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'DELETE', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        removeMenuFunction();
                        window.location.reload();
                    // },1000)
                }else{
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_MENU}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }
    // 新規メニューデータチェック
    const formik = useFormik({
        initialValues: {
            level: '',
            labelJP: '',
            labelEN: '',
            labelCH: '',
            icon: '',
            url: '',
            sort:0
        },
        validate: (data) => {
            let errors = {};
            if (!data.url) {
                errors.url = t('urlIsRequired');
            }
            if (!data.level) {
                errors.level = t('levelIsRequired');
            }
            return errors;
        },
        onSubmit: (data) => {
            sendUserDB(data, 'new');
            setActiveIndex(2);
            // formik.resetForm();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };


    // メニュー編集データーチェック
    const formikEdit = useFormik({
        initialValues: {
            id:'',
            level: '',
            labelJP: '',
            labelEN: '',
            labelCH: '',
            icon: '',
            url: '',
            sort:0
        },
        validate: (data) => {
            let errors = {};
            if (!data.url) {
                errors.url = t('urlIsRequired');
            }
            if (!data.level) {
                errors.level = t('levelIsRequired');
            }
            return errors;
        },
        onSubmit: (data) => {
            sendUserDB(data,'edit');
            setActiveIndex(2);
            // formik.resetForm();
        }
    });

    const editIsFormFieldInvalid = (name) => !!(formikEdit.touched[name] && formikEdit.errors[name]);

    const editGetFormErrorMessage = (name) => {
        return editIsFormFieldInvalid(name) ? <small className="p-error">{formikEdit.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const hiddenTabFunction = () => {
        if(hiddenEditTab === 0){
            return 'hidden'
        }else{
            return 'block'
        }
    }

    const levelSelectMachData = (value) => {
        const matchedLevel = levelData.find(data => data.level === value);
        return matchedLevel;
    }
    const levelBodyTemplate = (value) => {
        const matchedLevel = levelData.find(data => data.level === value.level);
        return <span>{matchedLevel.name}</span>;
    }

    const accept = () => {
        const id=selectDel;
        const data = {id:id}
        sendUserDB(data,'delete');
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        selectDel = '';
    }
    const confirmDelete = (value) => {
        selectDel = value.id;
        confirmDialog({
            message: t('areYouSureYouWantToDelete?'),
            header: t('confirm'),
            rejectLabel:t('no'),
            acceptLabel:t('yes'),
            defaultFocus:'reject',
            pt:{
                closeButton:{
                    className: 'focus:shadow-none'
                }
            },
            accept,
            reject
        })
    }
    const deleteTemplate = (value) => {
        return (
            <span>
                <Button 
                    label={t('delete')}
                    onClick={() => {
                        confirmDelete(value)
                    }}
                    pt={{
                        root:{
                            className:'text-sm bg-red-400 hover:bg-red-600 border-0 py-1 focus:shadow-none'
                        }
                    }}
                />
            </span>
        )
    }
    const headLabelTemplate = () => {
        return(
            <div>
                <div>{t('label')}</div>
            </div>
        )
    }
    const labelTemplate = (row) => {
        if(publicLanguageSelect === '1'){
            return(
                <div>
                    <div>{row.labelJP}</div>
                    <div>{row.labelEN}</div>
                    <div>{row.labelCH}</div>
                </div>
            )
        }else{
            let content = '';
            if(defaultLanguage === 'ja')content=row.labelJP;
            if(defaultLanguage === 'en')content=row.labelEN;
            if(defaultLanguage === 'zhCn')content=row.labelCH;
            return(
                <div>
                    <div>{content}</div>
                </div>
            )
        }
    }
    const iconTemplate = (row) => {
        return(
            <div>
                <div>{row.icon}</div>
                <div><i className={row.icon} style={{ color: '#708090' }}></i></div>
            </div>
        )
    }
    const urlTemplate = (row) => {
        const baseUrl = `${window.location.protocol}//${window.location.host}/`;
        return(
            <div>
                <a href={(`${baseUrl}${row.url}`)} target="_blank" rel="noreferrer noopener">
                {row.url}
                </a>
            </div>
        )
    }
    const labelNewfieldFunction = () => {
        const contJP = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('labelJP')}</label>
                    <InputText
                        inputid="labelJP"
                        name="labelJP"
                        value={labelJP}
                        placeholder={t('labelJP')}
                        onChange={(e) => {
                            formik.setFieldValue('labelJP', e.target.value);
                            setLabelJP(e.target.value);
                        }}
                        autoComplete="labelJP" />
                        {getFormErrorMessage('labelJP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('labelEN')}</label>
                    <InputText
                        inputid="labelEN"
                        name="labelEN"
                        value={labelEN}
                        placeholder={t('labelEN')}
                        onChange={(e) => {
                            formik.setFieldValue('labelEN', e.target.value);
                            setLabelEN(e.target.value);
                        }}
                        autoComplete="labelEN" />
                        {getFormErrorMessage('labelEN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('labelCH')}</label>
                    <InputText
                        inputid="labelCH"
                        name="labelCH"
                        value={labelCH}
                        placeholder={t('labelCH')}
                        onChange={(e) => {
                            formik.setFieldValue('labelCH', e.target.value);
                            setLabelCH(e.target.value);
                        }}
                        autoComplete="labelCH" />
                        {getFormErrorMessage('labelCH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return (
                <>
                    {contJP()}
                    {contEN()}
                    {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }

    const labelEditfieldFunction = () => {
        const contJP = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('labelJP')}</label>
                    <InputText
                        inputid="labelJP"
                        name="labelJP"
                        value={editLabelJP}
                        placeholder={t('labelJP')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('labelJP', e.target.value);
                            setEditLabelJP(e.target.value);
                        }}
                        autoComplete="labelJP" />
                        {editGetFormErrorMessage('labelJP')}
                </div>
                </>
            )
        }
        const contEN = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('labelEN')}</label>
                    <InputText
                        inputid="labelEN"
                        name="labelEN"
                        value={editLabelEN}
                        placeholder={t('labelEN')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('labelEN', e.target.value);
                            setEditLabelEN(e.target.value);
                        }}
                        autoComplete="labelEN" />
                        {editGetFormErrorMessage('labelEN')}
                </div>
                </>
            )
        }
        const contCH = () => {
            return(
                <>
                <div className="p-2 flex flex-col w-full">
                    <label>{t('labelCH')}</label>
                    <InputText
                        inputid="labelCH"
                        name="labelCH"
                        value={editLabelCH}
                        placeholder={t('labelCH')}
                        onChange={(e) => {
                            formikEdit.setFieldValue('labelCH', e.target.value);
                            setEditLabelCH(e.target.value);
                        }}
                        autoComplete="labelCH" />
                        {editGetFormErrorMessage('labelCH')}
                </div>
                </>
            )
        }
        if(publicLanguageSelect === '1'){
            return(
                <>
                    {contJP()}
                    {contEN()}
                    {contCH()}
                </>
            )
        }else{
            if(defaultLanguage === 'ja')return contJP()
            if(defaultLanguage === 'en')return contEN()
            if(defaultLanguage === 'zhCn')return contCH()
        }
    }
    return(
        <div className="pb-40">
            <Header />
            <div className=" mt-20 p-0 sm:p-8">
            <Accordion activeIndex={activeIndex} 
                onTabChange={
                    (e) => {
                        setActiveIndex(e.index);
                        if(e.index != 1){
                            setHiddenEditTab(0);
                        }else{
                            setHiddenEditTab(1);
                        }
                    }
                }
            >
                {/* 新規ユーザー */}
                <AccordionTab header={t('newMenu')}>
                <div className="w-full ">
                        <form 
                            onSubmit={formik.handleSubmit} 
                            className="w-full !max-w-none"
                        >
                            <div className="flex flex-col items-center contents-center justify-start w-full xl:items-center">
                                <div className="flex flex-col sm:flex-row w-full">
                                    {labelNewfieldFunction()}
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('icon')}</label>
                                        <InputText
                                            inputid="icon"
                                            name="icon"
                                            value={icon}
                                            placeholder={t('icon')}
                                            onChange={(e) => {
                                                formik.setFieldValue('icon', e.target.value);
                                                setIcon(e.target.value);
                                            }}
                                            autoComplete="icon" />
                                            {getFormErrorMessage('icon')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('url')}</label>
                                        <InputText
                                            inputid="url"
                                            name="url"
                                            value={url}
                                            placeholder={t('url')}
                                            onChange={(e) => {
                                                formik.setFieldValue('url', e.target.value);
                                                setUrl(e.target.value);
                                            }}
                                            autoComplete="url" />
                                            {getFormErrorMessage('url')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('sort')}</label>
                                        <InputText
                                            inputid="sort"
                                            name="sort"
                                            value={sort}
                                            placeholder={t('sort')}
                                            onChange={(e) => {
                                                formik.setFieldValue('sort', e.target.value);
                                                setSort(e.target.value);
                                            }}
                                            autoComplete="sort" />
                                            {getFormErrorMessage('sort')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('level')}</label>
                                        <Dropdown
                                            name="level"
                                            value={selectedLevel}
                                            options={levelData}
                                            optionLabel="name"
                                            onChange={(e) => {
                                                formik.setFieldValue('level', e.target.value.level);
                                                setSelectedLevel(e.target.value);
                                            }} 
                                            placeholder={t('level')}
                                            pt={{
                                                root:{
                                                    className:"!border-gray-300 text-gray-300"
                                                },
                                                input:{
                                                    className:"text-gray-400"
                                                }
                                            }}
                                        />
                                            {getFormErrorMessage('level')}
                                    </div>
                                </div>
                                {/* {localStorage.getItem('token')} */}
                                <div className="pb-5 flex flex-row">
                                    <Button 
                                        className="m-2 text-nowrap"
                                        label={t('create')} 
                                        type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </AccordionTab>
                {/* ユーザー編集 */}
                <AccordionTab header={t('editMenu')} className={hiddenTabFunction()} >
                    <div className="w-full ">
                        <form 
                            onSubmit={formikEdit.handleSubmit} 
                            className="w-full !max-w-none"
                        >
                            <div className="flex flex-col items-center contents-center justify-start w-full xl:items-center">
                                <div className="flex flex-col sm:flex-row w-full">
                                    {labelEditfieldFunction()}
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('icon')}</label>
                                        <InputText
                                            inputid="icon"
                                            name="icon"
                                            value={editIcon}
                                            placeholder={t('icon')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('icon', e.target.value);
                                                setEditIcon(e.target.value);
                                            }}
                                            autoComplete="icon" />
                                            {editGetFormErrorMessage('icon')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('url')}</label>
                                        <InputText
                                            inputid="url"
                                            name="url"
                                            value={editUrl}
                                            placeholder={t('url')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('url', e.target.value);
                                                setEditUrl(e.target.value);
                                            }}
                                            autoComplete="url" />
                                            {editGetFormErrorMessage('url')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('sort')}</label>
                                        <InputText
                                            inputid="sort"
                                            name="sort"
                                            value={editSort}
                                            placeholder={t('sort')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('sort', e.target.value);
                                                setEditSort(e.target.value);
                                            }}
                                            autoComplete="sort" />
                                            {editGetFormErrorMessage('sort')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <label>{t('level')}</label>
                                        <Dropdown
                                            name="level"
                                            value={editSelectedLevel}
                                            options={levelData}
                                            optionLabel="name"
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('level', e.target.value.level);
                                                setEditSelectedLevel(e.target.value);
                                            }} 
                                            placeholder={t('level')}
                                            pt={{
                                                root:{
                                                    className:"!border-gray-300 text-gray-300"
                                                },
                                                input:{
                                                    className:"text-gray-400"
                                                }
                                            }}
                                        />
                                            {editGetFormErrorMessage('level')}
                                    </div>
                                </div>
                                {/* {localStorage.getItem('token')} */}
                                <div className="pb-5 flex flex-row">
                                    <Button 
                                        className="m-2 text-nowrap"
                                        label={t('change')} 
                                        type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </AccordionTab>
                <AccordionTab header={t('listMenu')}>
                    <ConfirmDialog />
                    <DataTable 
                        value={menusData} 
                        selectionMode 
                        selection={selectMenusData} 
                        onRowClick={
                            (e) => {
                                // console.log('eData:'+JSON.stringify(e.data));
                                if(e.data){
                                    setSelectMenusData(e.data);
                                    formikEdit.setFieldValue('id', e.data.id);
                                    setEditLabelJP(e.data.labelJP);
                                    formikEdit.setFieldValue('labelJP', e.data.labelJP);
                                    setEditLabelEN(e.data.labelEN);
                                    formikEdit.setFieldValue('labelEN', e.data.labelEN);
                                    setEditLabelCH(e.data.labelCH);
                                    formikEdit.setFieldValue('labelCH', e.data.labelCH);
                                    setEditIcon(e.data.icon);
                                    formikEdit.setFieldValue('icon', e.data.icon);
                                    setEditUrl(e.data.url);
                                    formikEdit.setFieldValue('url', e.data.url);
                                    if(e.data.sort){
                                        setEditSort(e.data.sort);
                                        formikEdit.setFieldValue('sort', e.data.sort);
                                    }else{
                                        setEditSort(0);
                                        formikEdit.setFieldValue('sort', 0);
                                    }
                                    formikEdit.setFieldValue('level', e.data.level);
                                    setEditSelectedLevel(levelSelectMachData(e.data.level));
                                    setHiddenEditTab(1);
                                    setActiveIndex(1);
                                }
                            }
                        } 
                        dataKey="id" 
                        tableStyle={{ minWidth: '50rem' }}
                        className="border"
                        stripedRows
                    >
                        <Column selectionMode="single"
                            pt={{
                                headerCell:{className:'hidden'}
                            }}
                            className="hidden"
                        />
                        <Column field="id" header={t('delete')} body={deleteTemplate} 
                            pt={{headerContent:{className:'!justify-center text-md text-center min-w-20'},
                                bodyCell:{className:'!justify-center text-md text-center min-w-20'},
                                headerCell:{className:''}
                            }}
                        />
                        <Column 
                            field={labelTemplate} 
                            header={headLabelTemplate}
                            pt={{headerContent:{className:'!justify-center text-md text-center min-w-36'},
                                bodyCell:{className:'!justify-center text-md text-center min-w-36'},
                                headerCell:{className:''}
                            }}
                        />
                        <Column field="sort" header={t('sort')}
                            sortable
                            pt={{headerContent:{className:'!justify-center text-md text-center '},
                                bodyCell:{className:'!justify-center text-md text-center'},
                                headerCell:{className:''}
                            }}
                        />
                        <Column field={iconTemplate} header={t('icon')}
                            pt={{headerContent:{className:'!justify-center text-md text-center min-w-40'},
                                bodyCell:{className:'!justify-center text-md text-center min-w-40'},
                                headerCell:{className:''}
                            }}
                        />
                        <Column field="level" header={t('level')} body={levelBodyTemplate}
                            sortable
                            pt={{headerContent:{className:'!justify-center text-md text-center min-w-20'},
                                bodyCell:{className:'!justify-center text-md text-center min-w-20'},
                                headerCell:{className:''}
                            }}
                        />
                        <Column field={urlTemplate} header={t('url')}
                            pt={{headerContent:{className:'!justify-center text-md text-center '},
                                bodyCell:{className:'!justify-center text-md text-center'},
                                headerCell:{className:''}
                            }}
                        />
                    </DataTable>
                </AccordionTab>
            </Accordion>
            </div>
            <Footer />
        </div>
    )
}

export default EditMenu