import React, { useState, useEffect } from 'react';
import { DefSystem } from '../../components/contentsParts/DbData';


const UnderBanner = () =>{
    const [underBannerContent, setUnderBannerContent] = useState('');

    useEffect(() =>{
        const getBannerValuefunction =async() =>{
            const underBanner = localStorage.getItem('underBanner');
            const data = JSON.parse(underBanner);
            const currentTime = new Date().getTime();
            if(!underBanner || data.expires < currentTime){
                localStorage.removeItem('underBanner');
                const resData =  await DefSystem('underBanner');
                setUnderBannerContent(resData.value);
            }else{
                setUnderBannerContent(data.value);
            }
        }
        getBannerValuefunction();
    },[])

    return(
        <div className='mx-auto mt-5 pb-[45px] md:pb-[20px] !w-full bg-hite text-gray-700'>
                    <div className="!w-full pt-[20px] max-w-screen-xl mx-auto justify-center items-center content-center">
                        <div className='w-fit mx-auto'>
                            <div dangerouslySetInnerHTML={{ __html: underBannerContent}}></div>
                        </div>
                     </div>
        </div>
    )
}

export default UnderBanner