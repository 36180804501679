import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';
import { LevelData } from '../../components/contentsParts/DbData';

const EditUser = () =>{
    const toast = useRef(null);
    const [activeIndex, setActiveIndex] = useState();
    const [ hiddenEditTab, setHiddenEditTab] = useState(0);
    const { t } = useTranslation();
    const [ levelData, setLevelData] = useState([]);

    const [ email, setEmail] = useState('');
    const [ password, setPassword] = useState('');
    const [ selectedLevel, setSelectedLevel] = useState('');
    const [ name, setName] = useState('');
    const [ nickname, setNickname] = useState('');

    const [ usersData, setUsersData ] = useState([]);
    const [ selectUsersData, setSelectUsersData ] = useState(null);

    const [ editEmail, setEditEmail] = useState('');
    const [ editSelectedLevel, setEditSelectedLevel] = useState('');
    const [ editName, setEditName] = useState('');
    const [ editNickname, setEditNickname] = useState('');

    const [ editPassword, setEditPassword] = useState('');
    let selectDel = '';

    const sendRequest = async (url) => {
        const response = await fetch( url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }else if(response.status === 200) {
            const responceData = await response.json();
            return responceData;
        }else{
            return "[]";
        }
    }

    const requestDB = async() => {
        try {
            const resData = await sendRequest(`${process.env.REACT_APP_API_USER}`);
            setUsersData(resData.system);
            // return resData;
        } catch (error) {
            console.log(error)
            console.error("Failed to send the message: ", error);
        }
    }

    useEffect(() =>{
        let language = '';
        const strJson = localStorage.getItem('Lang');
        if(strJson){
            const data = JSON.parse(strJson);
            language = data.value;
        }

        const getLevelFunction = async () => {
            const getData = await LevelData(language);
            if(getData){
                setLevelData(getData);
                try {
                    setLevelData(JSON.parse(getData));
                } catch (e) {
                }
            }
        }
        if (language) {
            getLevelFunction();
        }
        
        requestDB();
    },[]);

    const sendUserDB = async(data, action) => {
        const token = sessionStorage.getItem('token');
        const dataWithToken = { ...data, token };
        // const data = { email, password, name, nickname, level };
        // console.log(data);
        if(action === 'new'){
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'POST', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        requestDB();
                        toast.current.show({severity:'success', summary: t('success'), detail:t('newDataCreationSuccess'), life: 3000});
                    // },1000)
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('newDataCreationFailure'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_USER}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'edit'){
            // console.log('Edit')
            // console.log("data:"+JSON.stringify(data))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'PUT', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        // window.location.reload();
                        requestDB();
                        toast.current.show({severity:'success', summary: t('success'), detail:t('updateSuccess'), life: 3000});
                    // },1000)
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('updateError'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_USER}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'editPassword'){
            // console.log('Edit Password')
            // console.log("data:"+JSON.stringify(dataWithToken))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'PUT', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        requestDB();
                        toast.current.show({severity:'success', summary: t('success'), detail:t('updateSuccess'), life: 3000});
                        setEditPassword('');

                    // },1000)
                }else{
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    toast.current.show({severity:'error', summary: t('error'), detail:t('updateError'), life: 3000});
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                // console.log(dataWithToken)
                await sendRequest(`${process.env.REACT_APP_API_USER}/password`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if(action === 'delete'){
            // console.log('delete')
            // console.log("data:"+JSON.stringify(data))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'DELETE', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        requestDB();
                        toast.current.show({severity:'success', summary: t('success'), detail:t('deleteDataSuccess'), life: 3000});
                    // },1000)
                }else{
                    toast.current.show({severity:'error', summary: t('error'), detail:t('deleteDataFailure'), life: 3000});
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_USER}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }
    // 新規ユーザーデータチェック
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            name: '',
            nickname: '',
            level:''
        },
        validate: (data) => {
            let errors = {};
            if (!data.email) {
                errors.email = t('emailIsRequired');
            }
            if (!data.password) {
                errors.password = t('passwordIsRequired');
            }
            if (!data.name) {
                errors.name = t('nameIsRequired');
            }
            if (!data.nickname) {
                errors.nickname = t('nicknameIsRequired');
            }
            if (!data.level) {
                errors.level = t('levelIsRequired');
            }
            // emailの形式チェックを追加
            if (!/\S+@\S+\.\S+/.test(data.email)) {
                errors.email = t('enterYourEmailAddressCorrectly');
            }
            if (data.password.length < 6) {
                errors.password = t('passwordIs6To20Characters');
            } else if (data.password.length > 20) {
                errors.password = t('passwordIs6To20Characters');
            }
            setEmail(data.email);
            setPassword(data.password);
            setName(data.name);
            setNickname(data.nickname);
            return errors;
        },
        onSubmit: (data) => {
            sendUserDB(data, 'new');
            // formik.resetForm();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };


    // ユーザー編集データーチェック
    const formikEdit = useFormik({
        initialValues: {
            id:'',
            email: '',
            name: '',
            nickname: '',
            level:''
        },
        validate: (data) => {
            let errors = {};
            if (!data.email) {
                errors.email = t('emailIsRequired');
            }
            if (!data.name) {
                errors.name = t('nameIsRequired');
            }
            if (!data.nickname) {
                errors.nickname = t('nicknameIsRequired');
            }
            if (!data.level) {
                errors.level = t('levelIsRequired');
            }
            // emailの形式チェックを追加
            if (!/\S+@\S+\.\S+/.test(data.email)) {
                errors.email = t('enterYourEmailAddressCorrectly');
            }
            return errors;
        },
        onSubmit: (data) => {
            sendUserDB(data,'edit');
            // formik.resetForm();
        }
    });

    const editIsFormFieldInvalid = (name) => !!(formikEdit.touched[name] && formikEdit.errors[name]);

    const editGetFormErrorMessage = (name) => {
        return editIsFormFieldInvalid(name) ? <small className="p-error">{formikEdit.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };


    // ユーザーパスワード変更データーチェック
    const formikEditPassword = useFormik({
        initialValues: {
            password: '',
            id: ''
        },
        validate: (data) => {
            let errors = {};
            if (data.password.length < 6) {
                errors.password = t('passwordIs6To20Characters');
            } else if (data.password.length > 20) {
                errors.password = t('passwordIs6To20Characters');
            }
            return errors;
        },
        onSubmit: (data) => {
            sendUserDB(data,'editPassword');
            setEditPassword('');
            formik.resetForm();
        }
    });

    const editPasswordIsFormFieldInvalid = (name) => !!(formikEditPassword.touched[name] && formikEditPassword.errors[name]);

    const editPasswordGetFormErrorMessage = (name) => {
        return editPasswordIsFormFieldInvalid(name) ? <small className="p-error">{formikEditPassword.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const levelBodyTemplate = (value) => {
        const matchedLevel = levelData.find(data => data.level === value.level);
        return <span>{matchedLevel.name}</span>;
    }

    const accept = () => {
        const id=selectDel;
        const data = {id:id}
        sendUserDB(data,'delete');
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        selectDel = '';
    }
    const confirmDelete = (value) => {
        selectDel = value.id;
        confirmDialog({
            message: t('areYouSureYouWantToDelete?'),
            header: t('confirm'),
            rejectLabel:t('no'),
            acceptLabel:t('yes'),
            defaultFocus:'reject',
            pt:{
                closeButton:{
                    className: 'focus:shadow-none'
                }
            },
            accept,
            reject
        })
    }
    const deleteTemplate = (value) => {
        return (
            <span>
                <Button 
                    label={t('delete')}
                    onClick={() => {
                        confirmDelete(value)
                    }}
                    pt={{
                        root:{
                            className:'text-sm bg-red-400 hover:bg-red-600 border-0 py-1 focus:shadow-none'
                        }
                    }}
                />
            </span>
        )
    }
    const levelSelectMachData = (value) => {
        const matchedLevel = levelData.find(data => data.level === value);
        return matchedLevel;
    }
    const hiddenTabFunction = () => {
        if(hiddenEditTab === 0){
            return 'hidden'
        }else{
            return 'block'
        }
    }
    return(
        <div className="pb-40">
            <Header />
            <Toast ref={toast} />
            <div className=" mt-20 p-0 sm:p-8">
            <Accordion activeIndex={activeIndex} 
                onTabChange={
                    (e) => {
                        setActiveIndex(e.index);
                        if(e.index === 0){
                            setHiddenEditTab(0);
                        }else{
                            setHiddenEditTab(1);
                        }
                    }
                }
            >
                {/* 新規ユーザー */}
                <AccordionTab header={t('newUser')}>
                    <div className="w-full ">
                        <form 
                            onSubmit={formik.handleSubmit} 
                            className="w-full !max-w-none"
                        >
                            <div className="flex flex-col items-center contents-center justify-start w-full xl:items-end xl:flex-row w-full">
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <InputText
                                            inputid="email"
                                            name="email"
                                            value={email}
                                            placeholder={t('email')}
                                            keyfilter="email"
                                            onChange={(e) => {
                                                formik.setFieldValue('email', e.target.value);
                                            }}
                                            autoComplete="email" />
                                            {getFormErrorMessage('email')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <InputText
                                            inputid="password"
                                            name="password"
                                            value={password}
                                            placeholder={t('password')}
                                            onChange={(e) => {
                                                formik.setFieldValue('password', e.target.value);
                                            }}
                                            autoComplete="new-password" />
                                            {getFormErrorMessage('password')}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <InputText
                                            inputid="name"
                                            name="name"
                                            value={name}
                                            placeholder={t('name')}
                                            onChange={(e) => {
                                                formik.setFieldValue('name', e.target.value);
                                            }}
                                            autoComplete="name" />
                                            {getFormErrorMessage('name')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <InputText
                                            inputid="nickname"
                                            name="nickname"
                                            value={nickname}
                                            placeholder={t('nickname')}
                                            onChange={(e) => {
                                                formik.setFieldValue('nickname', e.target.value);
                                            }}
                                            autoComplete="nickname" />
                                            {getFormErrorMessage('nickname')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <Dropdown
                                            name="level"
                                            value={selectedLevel}
                                            options={levelData}
                                            optionLabel="name"
                                            onChange={(e) => {
                                                formik.setFieldValue('level', e.target.value.level);
                                                setSelectedLevel(e.target.value);
                                            }} 
                                            placeholder={t('level')}
                                            pt={{
                                                root:{
                                                    className:"!border-gray-300 text-gray-300"
                                                },
                                                input:{
                                                    className:"text-gray-400"
                                                }
                                            }}
                                        />
                                            {getFormErrorMessage('level')}
                                    </div>
                                </div>
                                {/* {localStorage.getItem('token')} */}
                                <div className="pb-5">
                                    <Button 
                                        className="m-2 text-nowrap"
                                        label={t('create')} 
                                        type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </AccordionTab>
                {/* ユーザー編集 */}
                <AccordionTab header={t('editUser')} className={hiddenTabFunction()} >
                    <div className="w-full">
                        <form 
                            onSubmit={formikEdit.handleSubmit} 
                            className="w-full !max-w-none"
                        >
                            <div className="flex flex-col items-center contents-center justify-start w-full xl:items-end xl:flex-row w-full">
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col w-full">
                                        <InputText
                                            inputid="name"
                                            name="name"
                                            value={editName}
                                            placeholder={t('name')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('name', e.target.value);
                                                setEditName(e.target.value);
                                            }}
                                            autoComplete="name" />
                                            {editGetFormErrorMessage('name')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <InputText
                                            inputid="email"
                                            name="email"
                                            value={editEmail}
                                            placeholder={t('email')}
                                            keyfilter="email"
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('email', e.target.value);
                                                setEditEmail(e.target.value);
                                            }}
                                            autoComplete="email" />
                                            {editGetFormErrorMessage('email')}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full">
                                    <div className="p-2 flex flex-col">
                                        <InputText
                                            inputid="nickname"
                                            name="nickname"
                                            value={editNickname}
                                            placeholder={t('nickname')}
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('nickname', e.target.value);
                                                setEditNickname(e.target.value);
                                            }}
                                            autoComplete="nickname" />
                                            {editGetFormErrorMessage('nickname')}
                                    </div>
                                    <div className="p-2 flex flex-col w-full">
                                        <Dropdown
                                            name="level"
                                            value={editSelectedLevel}
                                            options={levelData}
                                            optionLabel="name"
                                            onChange={(e) => {
                                                formikEdit.setFieldValue('level', e.target.value.level);
                                                setEditSelectedLevel(e.target.value);
                                            }} 
                                            placeholder={t('level')}
                                            pt={{
                                                root:{
                                                    className:"!border-gray-300 text-gray-300"
                                                },
                                                input:{
                                                    className:"text-gray-400"
                                                }
                                            }}
                                        />
                                            {editGetFormErrorMessage('level')}
                                    </div>
                                </div>
                                {/* {localStorage.getItem('token')} */}
                                <div className="pb-5 flex flex-row">
                                    <Button 
                                        className="m-2 text-nowrap"
                                        label={t('change')} 
                                        type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </AccordionTab>
                {/* ユーザーパスワード変更 */}
                <AccordionTab header={t('changePasswordUser')} className={hiddenTabFunction()} >
                    <div className="w-full ">
                        <form 
                            onSubmit={formikEditPassword.handleSubmit} 
                            className="w-full !max-w-none !h-fit"
                            style={{
                                width:'90%',
                                maxWidth:'450px'
                            }}
                        >
                            <div className="flex flex-col items-center content-center justify-start w-full xl:items-end xl:flex-row !h-fit">
                                <div className="flex flex-col sm:flex-row !h-fit">
                                    <div className="flex flex-col !m-2 !py-2 !px-4 !h-fit">
                                        <div className='font-bold'>
                                            {t('name')}
                                        </div>
                                        <span>
                                            {editName}
                                        </span>
                                    </div>
                                    <div className="flex flex-col !m-2 !py-2 !px-4 !h-fit">
                                        <span className='font-bold'>
                                            {t('email')}
                                        </span>
                                        <span>
                                            {editEmail}
                                        </span>
                                    </div>
                                    <div className="mt-2 flex flex-col !pt-3">
                                        <InputText
                                            inputid="password"
                                            name="password"
                                            value={editPassword}
                                            placeholder={t('password')}
                                            onChange={(e) => {
                                                formikEditPassword.setFieldValue('password', e.target.value);
                                                setEditPassword(e.target.value);
                                            }} />
                                            {editPasswordGetFormErrorMessage
                                            ('password')}
                                    </div>
                                </div>
                                <div className="flex flex-row mx-4 mb-5">
                                    <Button 
                                        className="text-nowrap"
                                        label={t('change')} 
                                        type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </AccordionTab>
            </Accordion>
            <ConfirmDialog />
            <DataTable 
                value={usersData} 
                selectionMode 
                selection={selectUsersData} 
                onRowClick={
                    (e) => {
                        // console.log('eData:'+JSON.stringify(e.data));
                        if(e.data){
                            setSelectUsersData(e.data);
                            formikEdit.setFieldValue('id', e.data.id);
                            formikEditPassword.setFieldValue('id', e.data.id);
                            setEditName(e.data.name);
                            formikEdit.setFieldValue('name', e.data.name);
                            setEditEmail(e.data.email);
                            formikEdit.setFieldValue('email', e.data.email);
                            // setEditPassword(e.data.password);
                            setEditNickname(e.data.nickname);
                            formikEdit.setFieldValue('nickname', e.data.nickname);
                            formikEdit.setFieldValue('level', e.data.level);
                            setEditSelectedLevel(levelSelectMachData(e.data.level));
                            setHiddenEditTab(1);
                            setActiveIndex(1);
                        }
                    }
                } 
                dataKey="id" 
                tableStyle={{ minWidth: '50rem' }}
                className="border"
                stripedRows
            >
                <Column selectionMode="single"
                    pt={{
                        bodyCell:{className:'hidden'},
                        headerCell:{className:'hidden'}
                    }}
                ></Column>
                <Column field="name" header={t('name')}
                    pt={{headerContent:{className:'!justify-center text-md text-center '},
                        bodyCell:{className:'!justify-center text-md text-center'},
                        headerCell:{className:''}
                    }}
                />
                <Column field="email" header={t('email')}
                    pt={{headerContent:{className:'!justify-center text-md text-center '},
                        bodyCell:{className:'!justify-center text-md text-center'},
                        headerCell:{className:''}
                    }}
                />
                <Column field="nickname" header={t('nickname')}
                    pt={{headerContent:{className:'!justify-center text-md text-center '},
                        bodyCell:{className:'!justify-center text-md text-center'},
                        headerCell:{className:''}
                    }}
                />
                <Column field="level" header={t('level')} body={levelBodyTemplate}
                    pt={{headerContent:{className:'!justify-center text-md text-center '},
                        bodyCell:{className:'!justify-center text-md text-center'},
                        headerCell:{className:''}
                    }}
                />
                <Column field="id" header={t('delete')} body={deleteTemplate}
                    pt={{headerContent:{className:'!justify-center text-md text-center '},
                        bodyCell:{className:'!justify-center text-md text-center'},
                        headerCell:{className:''}
                    }}
                />
            </DataTable>
            </div>
            <Footer />
        </div>
    )
}

export default EditUser