import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { FileUpload } from 'primereact/fileupload';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';

const FileUploader = () =>{
    const { t } = useTranslation();
    const [ filesList, setFilesList ] = useState();
    const onUploadFile = (e) =>{
        window.location.reload();
        // console.log('File Uploaded:', e.files);
    }
    let selectDel = '';

    const sendRequest = async (url,data) => {
        const params = new URLSearchParams(data).toString();
        const response = await fetch( `${url}?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }else if(response.status === 200) {
            const responceData = await response.json();
            return responceData;
        }else{
            return "[]";
        }
    }

    const sendUserDB = async(data, action) => {
        const token = sessionStorage.getItem('token');
        const dataWithToken = { ...data, token };
        if(action === 'delete'){
            // console.log('delete')
            // console.log("data:"+JSON.stringify(data))
            const sendRequest = async (url) => {
                const response = await fetch(url, {
                    method: 'DELETE', // HTTPメソッド
                    headers: {
                        'Content-Type': 'application/json', // コンテントタイプをJSONに指定
                    },
                    body: JSON.stringify(dataWithToken), // JavaScriptオブジェクトをJSON文字列に変換
                    // body: data, // JavaScriptオブジェクトをJSON文字列に変換
                });
                // console.log(response);
                if (response.ok) {
                    // setTimeout(() => {
                        window.location.reload();
                    // },1000)
                }else{
                    // レスポンスステータスが200以外の場合のエラーハンドリング
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
                }
            };
            try {
                await sendRequest(`${process.env.REACT_APP_API_FILEUPLOAD}`);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }
    useEffect (() =>{
        const token = sessionStorage.getItem('token');
        const data = { token }
        const requestDB = async() => {
            try {
                const resData = await sendRequest(`${process.env.REACT_APP_API_FILEUPLOAD}`,data);
                setFilesList(resData.system);
                // return resData;
            } catch (error) {
                console.log(error)
                console.error("Failed to send the message: ", error);
            }
        }
        requestDB();
    },[])

    const formatNumber = (num) => {
        if (num >= 1_000_000_000) {
            return (num / 1_000_000_000).toFixed(1) + 'G';
        } else if (num >= 1_000_000) {
            return (num / 1_000_000).toFixed(1) + 'M';
        } else if (num >= 1_000) {
            return (num / 1_000).toFixed(1) + 'K';
        } else {
            return num.toString();
        }
    };
    const isImageFile = (fileName) => {
        const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.svg'];
        const fileExtension = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1).toLowerCase();
        return allowedExtensions.includes(`.${fileExtension}`);
    };
    const fileImageTemplate = (row) => {
        if(isImageFile(row.fileName)){
            // 画像
            return(
                <div className='text-center max-w-[150px]'>
                    <img src={`${process.env.REACT_APP_API_IMAGEPATH}${row.fileName}`} alt='upload file' className='mx-auto text-center min-w-[40px] max-w-[150px]'/>
                </div>
            )
        }else{
            // 画像以外
        return(
            <div className='text-center max-w-[150px]'>
                <i className='pi pi-file text-2xl' />
            </div>
        )
        }
    }
    const fileSizeTemplate = (row) => {
        return(
            <div>
                {formatNumber(row.fileSize)}
            </div>
        )
    }

    const urlTemplate = (row) => {
        return(
            <div>
                <span>{row.fileName}</span>
                <div>
                    <a href={`${process.env.REACT_APP_API_IMAGEPATH}${row.fileName}`} target="_blank"  rel="noreferrer noopener">{`/uploads/${row.fileName}`}</a>
                </div>
            </div>
        )
    }

    const accept = () => {
        const id=selectDel;
        const data = {id:id}
        sendUserDB(data,'delete');
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        selectDel = '';
    }
    const confirmDelete = (row) => {
        selectDel = row.id;
        confirmDialog({
            message: t('areYouSureYouWantToDelete?'),
            header: t('confirm'),
            rejectLabel:t('no'),
            acceptLabel:t('yes'),
            defaultFocus:'reject',
            pt:{
                closeButton:{
                    className: 'focus:shadow-none'
                }
            },
            accept,
            reject
        })
    }
    const deleteTemplate = (row) => {
        return (
            <span>
                <Button 
                    label={t('delete')}
                    onClick={() => {
                        confirmDelete(row)
                    }}
                    pt={{
                        root:{
                            className:'text-sm bg-red-400 hover:bg-red-600 border-0 py-1 focus:shadow-none'
                        }
                    }}
                />
            </span>
        )
    }
    return(
        <div className="pb-40">
            <Header />
            <div className=" mt-20 p-0 sm:p-8">
                <Panel
                    className=""
                    header={t('fileUploader')}
                >
                    <FileUpload 
                        name="fileUploader" 
                        url={`${process.env.REACT_APP_API_FILEUPLOAD}`} 
                        onUpload={onUploadFile}
                        multiple 
                        // accept="image/*" 
                        maxFileSize={10000000} 
                        emptyTemplate={<p className="m-0">{t('dragAndDopFiles')}</p>} 
                        chooseLabel={t('choose')}
                        uploadLabel={t('upload')}
                        cancelLabel={t('cancel')}
                    />
                </Panel>
                <ConfirmDialog />
                <DataTable
                    className="border-2 my-2 rounded-md"
                    header={t('fileList')}
                    value={filesList}
                    dataKey="id" 
                    tableStyle={{ minWidth: '50rem' }}
                    stripedRows
                    paginator
                    rows={10} rowsPerPageOptions={[10, 25, 50]} 
                >
                    <Column 
                        field='fileName'
                        body={fileImageTemplate} 
                        header={t('image')} 
                        pt={{headerContent:{className:'!justify-center text-md text-center min-w-20'},
                            bodyCell:{className:'!justify-center text-md text-center min-w-20'},
                            headerCell:{className:''}
                        }}
                    />
                    <Column 
                        sortable
                        field='fileSize'
                        header={t('size')}
                        body={fileSizeTemplate}
                        pt={{headerContent:{className:'!justify-center text-md text-center min-w-20'},
                            bodyCell:{className:'!justify-center text-md text-center min-w-20'},
                            headerCell:{className:''}
                        }}
                    />
                    <Column 
                        sortable
                        field='fileName'
                        body={urlTemplate} 
                        header={t('url')}
                        pt={{headerContent:{className:'!justify-center text-md text-center min-w-20'},
                            bodyCell:{className:'!justify-center text-md text-center min-w-20'},
                            headerCell:{className:''}
                        }}
                    />
                    <Column 
                        field="id" 
                        header={t('delete')} 
                        body={deleteTemplate} 
                        pt={{headerContent:{className:'!justify-center text-md text-center min-w-20'},
                            bodyCell:{className:'!justify-center text-md text-center min-w-20'},
                            headerCell:{className:''}
                        }}
                    />
                </DataTable>
            </div>
            <Footer />
        </div>
    )
}
export default FileUploader